import './main.css';
import './toasty.css';
import './elm-datepicker.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';
import * as bowser from 'bowser';
import * as sentinel from 'sentinel-js';
import * as d3 from 'd3-dag';
import textFit from 'textfit';
import * as svgPanZoom from 'svg-pan-zoom';
import * as Hammer from 'hammerjs';
import { Auth0Client } from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/browser';

Sentry.init({ dsn: 'https://e4254af4db0249aa808b4d227afd1005@sentry.io/5134362' });
let dag = null;

const auth0Options = {
  "domain": "dwmartin41.eu.auth0.com",
  "client_id": "iObILO32qS2c7CxAn7dcUp4eZtDm9jjj",
  cacheLocation: "localstorage",
  redirect_uri: window.location.origin,
  audience: "https://dwmartin41.eu.auth0.com/api/v2/"
};

function updateDAG() {
  Array.from(document.getElementsByClassName('dag-node-text'))
    .forEach(e => textFit(e, { multiLine: true, minFontSize: 20, maxFontSize: 10000, alignVertWithFlexbox: true }));

  if(dag !== null) {
    try {
      dag.resize(); // update SVG cached size and controls positions
      dag.fit();
      dag.center();
    } catch (err) {
      console.log(err);
    }
  }
}
console.log(bowser.getParser(window.navigator.userAgent).getBrowserName());
const auth0 = new Auth0Client(auth0Options);
console.log('auth0: ', auth0);

auth0.handleRedirectCallback()
  .catch(_ => true)
  .then(_ => {
    auth0.getTokenSilently(auth0Options)
      .catch(err => {
        console.log("auth failure:", err);
        return auth0.loginWithRedirect(auth0Options);
      })
      .then(token => {
        if (token) {
          console.log('token', token);
          const app = Elm.Main.init({
            node: document.getElementById('root'),
            flags: {
              apiUrl: process.env.ELM_APP_API_URL || "http://localhost:9090",
              browser: bowser.getParser(window.navigator.userAgent).getBrowserName(),
              authToken: token
            }
          });

          app.ports.outgoing.subscribe(msgWithId => {
            const id = msgWithId.id
            const request = msgWithId.msg
            // Reverse response
            console.log(id);
            if (request && request.length) {
              let nodes = request;
              const numRootNodes = nodes.filter(r => r.parents.length == 0).length;
              if(numRootNodes > 1) {
                nodes = [...nodes.map(r => r.parents.length == 0 ? {...r, parents: [-1]} : r), {id: -1, parents: []}];
              }
              const dagData = d3.dagStratify()(nodes.map(r => ({ id: r.id, parentIds: r.parents })));
              const result = d3.sugiyama().size([10000, 10000])(dagData);
              console.log(result);
              // Simply include the same `id` and the response under the `msg` key.
              app.ports.incoming.send({
                id: id,
                msg: result
              });
            }

            requestAnimationFrame(() => {
              updateDAG();
            });
          });

          app.ports.logout.subscribe(() => {
            auth0.logout({ returnTo: auth0Options.redirect_uri });
          });
        }
      });
  });

sentinel.on('#dag', function (el) {
  let eventsHandler = {
    haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel']
    , init: function (options) {
      var instance = options.instance
        , initialScale = 1
        , pannedX = 0
        , pannedY = 0
      // Init Hammer
      // Listen only for pointer and touch events
      this.hammer = Hammer(options.svgElement, {
        inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
      })
      // Enable pinch
      this.hammer.get('pinch').set({ enable: true })
      // Handle double tap
      this.hammer.on('doubletap', function (ev) {
        instance.zoomIn()
      })
      // Handle pan
      this.hammer.on('panstart panmove', function (ev) {
        // On pan start reset panned variables
        if (ev.type === 'panstart') {
          pannedX = 0
          pannedY = 0
        }
        // Pan only the difference
        instance.panBy({ x: ev.deltaX - pannedX, y: ev.deltaY - pannedY })
        pannedX = ev.deltaX
        pannedY = ev.deltaY
      })
      // Handle pinch
      this.hammer.on('pinchstart pinchmove', function (ev) {
        const rect = el.getBoundingClientRect();
        const pos = {
          x: (ev.center.x - rect.left),
          y: (ev.center.y - rect.top)
        };

        // On pinch start remember initial zoom
        if (ev.type === 'pinchstart') {
          initialScale = instance.getZoom();
        }

        instance.zoomAtPoint(initialScale * ev.scale, pos);

      })
      // Prevent moving the page on some devices when panning over SVG
      options.svgElement.addEventListener('touchmove', function (e) { e.preventDefault(); });
    }
    , destroy: function () {
      this.hammer.destroy()
    }
  }

  dag = svgPanZoom(el, {
    controlIconsEnabled: true,
    contain: true,
    zoomScaleSensitivity: 0.3,
    customEventsHandler: eventsHandler
  });

  updateDAG();

});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
