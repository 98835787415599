(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.fn.cz === region.f9.cz)
	{
		return 'on line ' + region.fn.cz;
	}
	return 'on lines ' + region.fn.cz + ' through ' + region.f9.cz;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.iA,
		impl.j0,
		impl.jP,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		a9: func(record.a9),
		fp: record.fp,
		e8: record.e8
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.a9;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.fp;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.e8) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.iA,
		impl.j0,
		impl.jP,
		function(sendToApp, initialModel) {
			var view = impl.j2;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.iA,
		impl.j0,
		impl.jP,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.fg && impl.fg(sendToApp)
			var view = impl.j2;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.br);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.jU) && (_VirtualDom_doc.title = title = doc.jU);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.i6;
	var onUrlRequest = impl.i7;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		fg: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.g_ === next.g_
							&& curr.gk === next.gk
							&& curr.gV.a === next.gV.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		iA: function(flags)
		{
			return A3(impl.iA, flags, _Browser_getUrl(), key);
		},
		j2: impl.j2,
		j0: impl.j0,
		jP: impl.jP
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { iv: 'hidden', hU: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { iv: 'mozHidden', hU: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { iv: 'msHidden', hU: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { iv: 'webkitHidden', hU: 'webkitvisibilitychange' }
		: { iv: 'hidden', hU: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		hf: _Browser_getScene(),
		hy: {
			hC: _Browser_window.pageXOffset,
			hE: _Browser_window.pageYOffset,
			hB: _Browser_doc.documentElement.clientWidth,
			gj: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		hB: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		gj: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			hf: {
				hB: node.scrollWidth,
				gj: node.scrollHeight
			},
			hy: {
				hC: node.scrollLeft,
				hE: node.scrollTop,
				hB: node.clientWidth,
				gj: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			hf: _Browser_getScene(),
			hy: {
				hC: x,
				hE: y,
				hB: _Browser_doc.documentElement.clientWidth,
				gj: _Browser_doc.documentElement.clientHeight
			},
			ii: {
				hC: x + rect.left,
				hE: y + rect.top,
				hB: rect.width,
				gj: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.bu.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.bu.b, xhr)); });
		$elm$core$Maybe$isJust(request.bI) && _Http_track(router, xhr, request.bI.a);

		try {
			xhr.open(request.bB, request.bJ, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.bJ));
		}

		_Http_configureRequest(xhr, request);

		request.br.a && xhr.setRequestHeader('Content-Type', request.br.a);
		xhr.send(request.br.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.bw; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.bF.a || 0;
	xhr.responseType = request.bu.d;
	xhr.withCredentials = request.hJ;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		bJ: xhr.responseURL,
		jK: xhr.status,
		jL: xhr.statusText,
		bw: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			jC: event.loaded,
			bE: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			jp: event.loaded,
			bE: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.iT) { flags += 'm'; }
	if (options.hS) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;
var $author$project$Types$LinkClicked = function (a) {
	return {$: 7, a: a};
};
var $author$project$Types$UrlChanged = function (a) {
	return {$: 6, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.C) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.F),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.F);
		} else {
			var treeLen = builder.C * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.K) : builder.K;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.C);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.F) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.F);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{K: nodeList, C: (len / $elm$core$Array$branchFactor) | 0, F: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {gf: fragment, gk: host, gS: path, gV: port_, g_: protocol, g$: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Types$DAG = 1;
var $author$project$Types$InitDatePicker = function (a) {
	return {$: 42, a: a};
};
var $author$project$Types$Topological = 0;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Types$ReceivedIntentions = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.jK));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $author$project$API$headers = function (authToken) {
	return _List_fromArray(
		[
			A2($elm$http$Http$header, 'Authorization', 'Bearer ' + authToken)
		]);
};
var $author$project$Types$DBRef = function (id) {
	return {iw: id};
};
var $author$project$Types$Intention = F6(
	function (id, title, parents, description, status, dueDate) {
		return {ib: description, $8: dueDate, iw: id, ji: parents, jJ: status, jU: title};
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.fn, posixMinutes) < 0) {
					return posixMinutes + era.d;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		f2: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		gG: month,
		hF: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).f2;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gG;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).hF;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (path, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$at, path, $elm$json$Json$Decode$value),
				input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					return A2(
						$elm$json$Json$Decode$at,
						path,
						nullOr(valDecoder));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				_List_fromArray(
					[key]),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Types$Done = 1;
var $author$project$Types$ToDo = 0;
var $author$project$Utils$parseIntentionStatus = function (status) {
	switch (status) {
		case 'active':
			return 0;
		case 'done':
			return 1;
		default:
			return 0;
	}
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {f$: col, h0: contextStack, gX: problem, ha: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.ha, s.f$, x, s.j));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.c),
			s.d) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.c);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.d, offset) < 0,
					0,
					{f$: col, j: s0.j, r: s0.r, d: offset, ha: row, c: s0.c});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.d, s.ha, s.f$, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.d, s1.d, s0.c),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.d, s.c);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{f$: 1, j: s.j, r: s.r, d: s.d + 1, ha: s.ha + 1, c: s.c}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{f$: s.f$ + 1, j: s.j, r: s.r, d: newOffset, ha: s.ha, c: s.c}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.d, s.ha, s.f$, s.c);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{f$: newCol, j: s.j, r: s.r, d: newOffset, ha: newRow, c: s.c});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$succeed(0)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {f$: col, gX: problem, ha: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.ha, p.f$, p.gX);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{f$: 1, j: _List_Nil, r: 1, d: 0, ha: 1, c: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$JSON$intentionsDecoder = $elm$json$Json$Decode$list(
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'due-date',
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$andThen(
				function (s) {
					var _v0 = A2(
						$elm$core$Result$map,
						$justinmimbs$date$Date$fromPosix($elm$time$Time$utc),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(s));
					if (!_v0.$) {
						var date = _v0.a;
						return $elm$core$Maybe$Just(date);
					} else {
						return $elm$core$Maybe$Just(
							A3(
								$justinmimbs$date$Date$fromCalendarDate,
								1,
								$justinmimbs$date$Date$numberToMonth(3),
								3));
					}
				}),
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'status',
			A2($elm$json$Json$Decode$map, $author$project$Utils$parseIntentionStatus, $elm$json$Json$Decode$string),
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'description',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'parents',
					$elm$json$Json$Decode$list(
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$Types$DBRef))),
					_List_Nil,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'title',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$Types$Intention))))))));
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {g5: reqs, hp: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.bI;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.g5));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.hp)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					hJ: r.hJ,
					br: r.br,
					bu: A2(_Http_mapExpect, func, r.bu),
					bw: r.bw,
					bB: r.bB,
					bF: r.bF,
					bI: r.bI,
					bJ: r.bJ
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{hJ: false, br: r.br, bu: r.bu, bw: r.bw, bB: r.bB, bF: r.bF, bI: r.bI, bJ: r.bJ}));
};
var $author$project$API$getAllIntentions = F2(
	function (apiUrl, authToken) {
		return $elm$http$Http$request(
			{
				br: $elm$http$Http$emptyBody,
				bu: A2($elm$http$Http$expectJson, $author$project$Types$ReceivedIntentions, $author$project$JSON$intentionsDecoder),
				bw: $author$project$API$headers(authToken),
				bB: 'GET',
				bF: $elm$core$Maybe$Just(30000),
				bI: $elm$core$Maybe$Nothing,
				bJ: apiUrl + '/intentions'
			});
	});
var $author$project$Types$ReceivedViews = function (a) {
	return {$: 1, a: a};
};
var $author$project$Types$View = F3(
	function (id, rootNode, title) {
		return {iw: id, ju: rootNode, jU: title};
	});
var $author$project$JSON$viewsDecoder = $elm$json$Json$Decode$list(
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'title',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'root-node',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Types$DBRef)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$int,
				$elm$json$Json$Decode$succeed($author$project$Types$View)))));
var $author$project$API$getViews = F2(
	function (apiUrl, authToken) {
		return $elm$http$Http$request(
			{
				br: $elm$http$Http$emptyBody,
				bu: A2($elm$http$Http$expectJson, $author$project$Types$ReceivedViews, $author$project$JSON$viewsDecoder),
				bw: $author$project$API$headers(authToken),
				bB: 'GET',
				bF: $elm$core$Maybe$Just(30000),
				bI: $elm$core$Maybe$Nothing,
				bJ: apiUrl + '/views'
			});
	});
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Types$DAGLayoutCalculated = function (a) {
	return {$: 3, a: a};
};
var $elm$core$String$toLower = _String_toLower;
var $author$project$Utils$caseInsensitiveContains = F2(
	function (sub, s) {
		return A2(
			$elm$core$String$contains,
			$elm$core$String$toLower(sub),
			$elm$core$String$toLower(s));
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$List$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$List$cons, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, _List_Nil, list, _List_Nil);
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Utils$filterIntentionGraph = F2(
	function (intentions, predicate) {
		var childrenMap = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (i) {
					return _Utils_Tuple2(
						i.iw,
						A2(
							$elm$core$List$filter,
							function (c) {
								return A2(
									$elm$core$Set$member,
									i.iw,
									$elm$core$Set$fromList(
										A2(
											$elm$core$List$map,
											function ($) {
												return $.iw;
											},
											c.ji)));
							},
							intentions));
				},
				intentions));
		var include = function (intention) {
			return predicate(intention) || A2(
				$elm$core$List$any,
				include,
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, intention.iw, childrenMap)));
		};
		var filteredIntentions = A2($elm$core$List$filter, include, intentions);
		return A2(
			$elm$core$List$map,
			function (i) {
				return _Utils_update(
					i,
					{
						ji: A2(
							$elm$core$List$filter,
							function (p) {
								return A2(
									$elm$core$List$any,
									function (i2) {
										return _Utils_eq(p.iw, i2.iw);
									},
									filteredIntentions);
							},
							i.ji)
					});
			},
			A2(
				$elm_community$list_extra$List$Extra$uniqueBy,
				function ($) {
					return $.iw;
				},
				filteredIntentions));
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Types$PorterMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$Children = $elm$core$Basics$identity;
var $author$project$Types$DAGLayout = F5(
	function (id, x, y, children, childLinkData) {
		return {hW: childLinkData, hX: children, iw: id, hC: x, hE: y};
	});
var $author$project$Types$Point = F2(
	function (x, y) {
		return {hC: x, hE: y};
	});
var $author$project$Types$Points = function (points) {
	return {jk: points};
};
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
function $author$project$JSON$cyclic$dagLayoutDecoder() {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_childLinkData',
		$elm$json$Json$Decode$list(
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'points',
				$elm$json$Json$Decode$list(
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'y',
						$elm$json$Json$Decode$float,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'x',
							$elm$json$Json$Decode$float,
							$elm$json$Json$Decode$succeed($author$project$Types$Point)))),
				$elm$json$Json$Decode$succeed($author$project$Types$Points))),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'children',
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Basics$identity,
				$elm$json$Json$Decode$list(
					$elm$json$Json$Decode$lazy(
						function (_v0) {
							return $author$project$JSON$cyclic$dagLayoutDecoder();
						}))),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'y',
				$elm$json$Json$Decode$float,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'x',
					$elm$json$Json$Decode$float,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						A2(
							$elm$json$Json$Decode$map,
							function (str) {
								return A2(
									$elm$core$Maybe$withDefault,
									0,
									$elm$core$String$toInt(str));
							},
							$elm$json$Json$Decode$string),
						$elm$json$Json$Decode$succeed($author$project$Types$DAGLayout))))));
}
var $author$project$JSON$dagLayoutDecoder = $author$project$JSON$cyclic$dagLayoutDecoder();
$author$project$JSON$cyclic$dagLayoutDecoder = function () {
	return $author$project$JSON$dagLayoutDecoder;
};
var $author$project$Ports$incoming = _Platform_incomingPort('incoming', $elm$json$Json$Decode$value);
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$JSON$intentionsEncoder = function (intentions) {
	return A2(
		$elm$json$Json$Encode$list,
		function (i) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'id',
						$elm$json$Json$Encode$int(i.iw)),
						_Utils_Tuple2(
						'parents',
						A2(
							$elm$json$Json$Encode$list,
							function (p) {
								return $elm$json$Json$Encode$int(p.iw);
							},
							i.ji))
					]));
		},
		intentions);
};
var $author$project$Ports$outgoing = _Platform_outgoingPort('outgoing', $elm$core$Basics$identity);
var $author$project$Ports$porterConfig = {h6: $author$project$JSON$dagLayoutDecoder, ik: $author$project$JSON$intentionsEncoder, iy: $author$project$Ports$incoming, jb: $author$project$Ports$outgoing, jm: $author$project$Types$PorterMsg};
var $peterszerzo$elm_porter$Porter$Internals$ResolveChain = function (a) {
	return {$: 2, a: a};
};
var $peterszerzo$elm_porter$Porter$Internals$ComplexRequest = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $peterszerzo$elm_porter$Porter$Internals$ShortCircuit = function (a) {
	return {$: 2, a: a};
};
var $peterszerzo$elm_porter$Porter$Internals$SimpleRequest = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $peterszerzo$elm_porter$Porter$Internals$multiMap = F2(
	function (mapfun, req) {
		switch (req.$) {
			case 0:
				var porterReq = req.a;
				var requestMapper = req.b;
				return A2(
					$peterszerzo$elm_porter$Porter$Internals$SimpleRequest,
					porterReq,
					A2($elm$core$Basics$composeR, requestMapper, mapfun));
			case 1:
				var porterReq = req.a;
				var nextRequestFun = req.b;
				return A2(
					$peterszerzo$elm_porter$Porter$Internals$ComplexRequest,
					porterReq,
					function (res) {
						return A2(
							$peterszerzo$elm_porter$Porter$Internals$multiMap,
							mapfun,
							nextRequestFun(res));
					});
			default:
				var val = req.a;
				return $peterszerzo$elm_porter$Porter$Internals$ShortCircuit(
					mapfun(val));
		}
	});
var $peterszerzo$elm_porter$Porter$Internals$RequestWithHandler = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $peterszerzo$elm_porter$Porter$Internals$SendWithNextId = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $peterszerzo$elm_porter$Porter$Internals$runSendRequest = F2(
	function (config, request) {
		return A2(
			$elm$core$Platform$Cmd$map,
			config.jm,
			A2(
				$elm$core$Task$perform,
				$elm$core$Basics$identity,
				$elm$core$Task$succeed(
					$peterszerzo$elm_porter$Porter$Internals$SendWithNextId(request))));
	});
var $peterszerzo$elm_porter$Porter$Internals$send = F3(
	function (config, responseHandler, _v0) {
		var req = _v0.a;
		var reqfuns = _v0.b;
		return A2(
			$peterszerzo$elm_porter$Porter$Internals$runSendRequest,
			config,
			A3(
				$peterszerzo$elm_porter$Porter$Internals$RequestWithHandler,
				req,
				$elm$core$List$reverse(reqfuns),
				responseHandler));
	});
var $peterszerzo$elm_porter$Porter$Internals$multiSend = F3(
	function (config, msgHandler, request) {
		var mappedRequest = A2($peterszerzo$elm_porter$Porter$Internals$multiMap, msgHandler, request);
		switch (mappedRequest.$) {
			case 0:
				var porterReq = mappedRequest.a;
				var responseHandler = mappedRequest.b;
				return A3($peterszerzo$elm_porter$Porter$Internals$send, config, responseHandler, porterReq);
			case 1:
				var porterReq = mappedRequest.a;
				var nextRequestFun = mappedRequest.b;
				var resfun = function (res) {
					return config.jm(
						$peterszerzo$elm_porter$Porter$Internals$ResolveChain(
							nextRequestFun(res)));
				};
				return A3($peterszerzo$elm_porter$Porter$Internals$send, config, resfun, porterReq);
			default:
				var val = mappedRequest.a;
				return A2(
					$elm$core$Task$perform,
					$elm$core$Basics$identity,
					$elm$core$Task$succeed(val));
		}
	});
var $peterszerzo$elm_porter$Porter$send = F3(
	function (config, msgHandler, req) {
		return A3($peterszerzo$elm_porter$Porter$Internals$multiSend, config, msgHandler, req);
	});
var $peterszerzo$elm_porter$Porter$Internals$Request = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $peterszerzo$elm_porter$Porter$request = F2(
	function (responseHandler, req) {
		return A2(
			$peterszerzo$elm_porter$Porter$Internals$SimpleRequest,
			A2($peterszerzo$elm_porter$Porter$Internals$Request, req, _List_Nil),
			responseHandler);
	});
var $peterszerzo$elm_porter$Porter$simpleRequest = $peterszerzo$elm_porter$Porter$request($elm$core$Basics$identity);
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $author$project$Utils$descendentsOf = F2(
	function (id, intentions) {
		var children = A2(
			$elm$core$List$filter,
			function (i) {
				return A2(
					$elm$core$List$any,
					function (p) {
						return _Utils_eq(p.iw, id);
					},
					i.ji);
			},
			intentions);
		return $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				function (c) {
					return A2($author$project$Utils$subGraph, c.iw, intentions);
				},
				children));
	});
var $author$project$Utils$subGraph = F2(
	function (id, intentions) {
		var self = A2(
			$elm$core$List$filter,
			function (i) {
				return _Utils_eq(i.iw, id);
			},
			intentions);
		return A2(
			$elm$core$List$append,
			self,
			A2($author$project$Utils$descendentsOf, id, intentions));
	});
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Update$updateDAGLayout = function (model) {
	var focusedIntentions = function () {
		var _v0 = model.ir;
		if (!_v0.$) {
			var id = _v0.a;
			return A2(
				$author$project$Utils$subGraph,
				id,
				$elm$core$Dict$values(model.iD));
		} else {
			return $elm$core$Dict$values(model.iD);
		}
	}();
	var filterPred = function (i) {
		return (model.jE || (i.jJ !== 1)) && A2(
			$elm$core$Maybe$withDefault,
			true,
			A2(
				$elm$core$Maybe$map,
				function (sf) {
					return A2($author$project$Utils$caseInsensitiveContains, sf, i.jU);
				},
				model.jy));
	};
	var filteredIntentions = A2($author$project$Utils$filterIntentionGraph, focusedIntentions, filterPred);
	return A3(
		$peterszerzo$elm_porter$Porter$send,
		$author$project$Ports$porterConfig,
		$author$project$Types$DAGLayoutCalculated,
		$peterszerzo$elm_porter$Porter$simpleRequest(filteredIntentions));
};
var $author$project$Update$cmdOnPageChange = function (model) {
	var _v0 = model.jd;
	switch (_v0.$) {
		case 0:
			return $elm$core$Maybe$Just(
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2($author$project$API$getAllIntentions, model.hL, model.hN),
							A2($author$project$API$getViews, model.hL, model.hN),
							$elm$core$Dict$isEmpty(model.iD) ? $elm$core$Platform$Cmd$none : $author$project$Update$updateDAGLayout(model)
						])));
		case 1:
			var parents = _v0.a;
			var editingIntentionId = _v0.b;
			return $elm$core$Maybe$Just(
				A2($author$project$API$getAllIntentions, model.hL, model.hN));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Types$NotFound = {$: 2};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {bv: frag, bC: params, bm: unvisited, aY: value, bL: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.bm;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.aY);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.aY);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.gS),
					$elm$url$Url$Parser$prepareQuery(url.g$),
					url.gf,
					$elm$core$Basics$identity)));
	});
var $author$project$Types$EditIntention = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Types$ExistingId = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$Home = function (a) {
	return {$: 0, a: a};
};
var $author$project$Types$NewId = {$: 1};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.bL;
			var unvisited = _v0.bm;
			var params = _v0.bC;
			var frag = _v0.bv;
			var value = _v0.aY;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$int = A2($elm$url$Url$Parser$custom, 'NUMBER', $elm$core$String$toInt);
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.bL;
		var unvisited = _v0.bm;
		var params = _v0.bC;
		var frag = _v0.bv;
		var value = _v0.aY;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.bL;
			var unvisited = _v1.bm;
			var params = _v1.bC;
			var frag = _v1.bv;
			var value = _v1.aY;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.bL;
		var unvisited = _v1.bm;
		var params = _v1.bC;
		var frag = _v1.bv;
		var value = _v1.aY;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.bL;
		var unvisited = _v0.bm;
		var params = _v0.bC;
		var frag = _v0.bv;
		var value = _v0.aY;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Routing$routeParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Types$Home($elm$core$Maybe$Nothing),
			$elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeL, $author$project$Types$Home, $elm$core$Maybe$Just),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('focus'),
				$elm$url$Url$Parser$int)),
			A2(
			$elm$url$Url$Parser$map,
			function (parents) {
				return A2($author$project$Types$EditIntention, parents, $author$project$Types$NewId);
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('intention'),
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$s('new'),
					A2(
						$elm$url$Url$Parser$Query$custom,
						'parent',
						$elm$core$List$filterMap($elm$core$String$toInt))))),
			A2(
			$elm$url$Url$Parser$map,
			A2(
				$elm$core$Basics$composeL,
				$author$project$Types$EditIntention(_List_Nil),
				$author$project$Types$ExistingId),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('intention'),
				$elm$url$Url$Parser$int))
		]));
var $author$project$Routing$getPage = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Types$NotFound,
		A2($elm$url$Url$Parser$parse, $author$project$Routing$routeParser, url));
};
var $CurrySoftware$elm_datepicker$DatePicker$CurrentDate = function (a) {
	return {$: 0, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$DatePicker = $elm$core$Basics$identity;
var $CurrySoftware$elm_datepicker$DatePicker$Date$initDate = A3($justinmimbs$date$Date$fromCalendarDate, 1992, 4, 31);
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $justinmimbs$date$Date$today = A3($elm$core$Task$map2, $justinmimbs$date$Date$fromPosix, $elm$time$Time$here, $elm$time$Time$now);
var $CurrySoftware$elm_datepicker$DatePicker$init = _Utils_Tuple2(
	{
		an: $elm$core$Maybe$Just($CurrySoftware$elm_datepicker$DatePicker$Date$initDate),
		a6: false,
		aC: $elm$core$Maybe$Nothing,
		aV: false,
		bj: $CurrySoftware$elm_datepicker$DatePicker$Date$initDate
	},
	A2($elm$core$Task$perform, $CurrySoftware$elm_datepicker$DatePicker$CurrentDate, $justinmimbs$date$Date$today));
var $peterszerzo$elm_porter$Porter$Model = $elm$core$Basics$identity;
var $peterszerzo$elm_porter$Porter$init = {aT: $elm$core$Dict$empty, eM: 0};
var $pablen$toasty$Toasty$Stack = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $pablen$toasty$Toasty$initialState = A2(
	$pablen$toasty$Toasty$Stack,
	_List_Nil,
	$elm$random$Random$initialSeed(0));
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $elm$core$Set$singleton = function (key) {
	return A2($elm$core$Dict$singleton, key, 0);
};
var $author$project$Update$updateModelOnPageChange = function (model) {
	var _v0 = model.jd;
	switch (_v0.$) {
		case 1:
			var parentIds = _v0.a;
			var newOrExistingId = _v0.b;
			if (A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (ei) {
						return _Utils_eq(ei.iw, newOrExistingId);
					},
					model.ih))) {
				return model;
			} else {
				if (newOrExistingId.$ === 1) {
					var rootNodeId = A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.iw;
						},
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (i) {
									return $elm$core$List$isEmpty(i.ji);
								},
								$elm$core$Dict$values(model.iD))));
					return _Utils_update(
						model,
						{
							ih: $elm$core$Maybe$Just(
								{
									ib: $elm$core$Maybe$Nothing,
									$8: $elm$core$Maybe$Nothing,
									iw: $author$project$Types$NewId,
									je: $elm$core$Maybe$Nothing,
									jf: false,
									jg: '',
									jh: $elm$core$List$isEmpty(parentIds) ? A2(
										$elm$core$Maybe$withDefault,
										$elm$core$Set$empty,
										A2(
											$elm$core$Maybe$map,
											function (id) {
												return $elm$core$Set$singleton(id);
											},
											rootNodeId)) : $elm$core$Set$fromList(parentIds),
									jJ: 0,
									jU: 'New Intention'
								})
						});
				} else {
					var id = newOrExistingId.a;
					var selectedIntention = A2($elm$core$Dict$get, id, model.iD);
					return _Utils_update(
						model,
						{
							ih: A2(
								$elm$core$Maybe$map,
								function (i) {
									return {
										ib: i.ib,
										$8: i.$8,
										iw: $author$project$Types$ExistingId(id),
										je: $elm$core$Maybe$Nothing,
										jf: false,
										jg: '',
										jh: $elm$core$Set$fromList(
											A2(
												$elm$core$List$map,
												function (x) {
													return x.iw;
												},
												i.ji)),
										jJ: i.jJ,
										jU: i.jU
									};
								},
								selectedIntention)
						});
				}
			}
		case 0:
			var focusedIntentionId = _v0.a;
			return _Utils_update(
				model,
				{ir: focusedIntentionId});
		default:
			return _Utils_update(
				model,
				{iL: false});
	}
};
var $author$project$Model$init = F3(
	function (flags, url, key) {
		var _v0 = $CurrySoftware$elm_datepicker$DatePicker$init;
		var datePicker = _v0.a;
		var datePickerCmd = _v0.b;
		var model = {
			hL: flags.hL,
			hN: flags.hN,
			hR: flags.hR,
			h3: $elm$core$Maybe$Nothing,
			h5: datePicker,
			id: 1,
			ih: $elm$core$Maybe$Nothing,
			il: $elm$core$Maybe$Nothing,
			ir: $elm$core$Maybe$Nothing,
			iC: $elm$core$Maybe$Nothing,
			iD: $elm$core$Dict$empty,
			iI: key,
			iL: true,
			jd: $author$project$Routing$getPage(url),
			jl: $peterszerzo$elm_porter$Porter$init,
			jo: $elm$core$Maybe$Nothing,
			jy: $elm$core$Maybe$Nothing,
			jz: false,
			jA: $elm$core$Maybe$Nothing,
			jE: false,
			jF: false,
			jG: false,
			jH: false,
			jI: 0,
			j$: $pablen$toasty$Toasty$initialState,
			j3: $elm$core$Maybe$Nothing,
			j4: $elm$core$Maybe$Nothing,
			j5: _List_Nil
		};
		return _Utils_Tuple2(
			$author$project$Update$updateModelOnPageChange(model),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						$author$project$Update$cmdOnPageChange(model)),
						A2($elm$core$Platform$Cmd$map, $author$project$Types$InitDatePicker, datePickerCmd)
					])));
	});
var $peterszerzo$elm_porter$Porter$Internals$Receive = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Platform$Sub$map = _Platform_map;
var $peterszerzo$elm_porter$Porter$subscriptions = function (config) {
	return A2(
		$elm$core$Platform$Sub$map,
		config.jm,
		config.iy($peterszerzo$elm_porter$Porter$Internals$Receive));
};
var $author$project$Main$subscriptions = function (model) {
	return $peterszerzo$elm_porter$Porter$subscriptions($author$project$Ports$porterConfig);
};
var $pablen$toasty$Toasty$Defaults$Error = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Types$NoOp = {$: 43};
var $author$project$Types$ShowToast = function (a) {
	return {$: 16, a: a};
};
var $pablen$toasty$Toasty$Defaults$Success = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $pablen$toasty$Toasty$Temporary = 0;
var $pablen$toasty$Toasty$Entered = 0;
var $pablen$toasty$Toasty$TransitionOut = function (a) {
	return {$: 2, a: a};
};
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $elm$random$Random$maxInt = 2147483647;
var $elm$random$Random$minInt = -2147483648;
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $pablen$toasty$Toasty$getNewId = function (seed) {
	return A2(
		$elm$random$Random$step,
		A2($elm$random$Random$int, $elm$random$Random$minInt, $elm$random$Random$maxInt),
		seed);
};
var $elm$core$Process$sleep = _Process_sleep;
var $pablen$toasty$Toasty$addToast_ = F5(
	function (removeBehaviour, _v0, tagger, toast, _v1) {
		var cfg = _v0;
		var model = _v1.a;
		var cmd = _v1.b;
		var _v2 = model.j$;
		var toasts = _v2.a;
		var seed = _v2.b;
		var _v3 = $pablen$toasty$Toasty$getNewId(seed);
		var newId = _v3.a;
		var newSeed = _v3.b;
		var task = function () {
			if (!removeBehaviour) {
				return A2(
					$elm$core$Task$perform,
					function (_v5) {
						return tagger(
							$pablen$toasty$Toasty$TransitionOut(newId));
					},
					$elm$core$Process$sleep(cfg.h8));
			} else {
				return $elm$core$Platform$Cmd$none;
			}
		}();
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					j$: A2(
						$pablen$toasty$Toasty$Stack,
						_Utils_ap(
							toasts,
							_List_fromArray(
								[
									_Utils_Tuple3(newId, 0, toast)
								])),
						newSeed)
				}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, task])));
	});
var $pablen$toasty$Toasty$addToast = $pablen$toasty$Toasty$addToast_(0);
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $author$project$Utils$constantly = function (x) {
	return function (_v0) {
		return x;
	};
};
var $elm$time$Time$Sun = 6;
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {f2: d, gG: m, hF: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		eQ: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		hF: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.hF, 0, date.eQ);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.f2;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.gG;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.eQ;
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		hz: 1 + (((rd - week1Day1) / 7) | 0),
		hA: wy,
		j6: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.hz;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.hA;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.dj(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.eJ(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.dj(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.dU(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.bM(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.bM(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.bM(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.fH(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.bM(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.bM(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.d, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	dU: $justinmimbs$date$Date$withOrdinalSuffix,
	eJ: $justinmimbs$date$Date$monthToName,
	dj: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	fH: $justinmimbs$date$Date$weekdayToName,
	bM: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $CurrySoftware$elm_datepicker$DatePicker$formatCell = function (day) {
	return $elm$html$Html$text(day);
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$formatDay = function (day) {
	switch (day) {
		case 0:
			return 'Mo';
		case 1:
			return 'Tu';
		case 2:
			return 'We';
		case 3:
			return 'Th';
		case 4:
			return 'Fr';
		case 5:
			return 'Sa';
		default:
			return 'Su';
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$Date$formatMonth = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.gX;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn) && A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d) : $elm$core$Result$Err(
			'Invalid calendar date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(mn) + (', ' + ($elm$core$String$fromInt(d) + ')'))))));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 365, od) || ((od === 366) && $justinmimbs$date$Date$isLeapYear(y))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od) : $elm$core$Result$Err(
			'Invalid ordinal date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(od) + ')'))));
	});
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn) && (A3($justinmimbs$date$Date$isBetweenInt, 1, 52, wn) || ((wn === 53) && $justinmimbs$date$Date$is53WeekYear(wy)))) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn) : $elm$core$Result$Err(
			'Invalid week date (' + ($elm$core$String$fromInt(wy) + (', ' + ($elm$core$String$fromInt(wn) + (', ' + ($elm$core$String$fromInt(wdn) + ')'))))));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($justinmimbs$date$Date$deadEndToString),
				$elm$core$Maybe$withDefault('')))));
var $CurrySoftware$elm_datepicker$DatePicker$Date$Off = {$: 0};
var $CurrySoftware$elm_datepicker$DatePicker$off = $CurrySoftware$elm_datepicker$DatePicker$Date$Off;
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$required = $elm$html$Html$Attributes$boolProperty('required');
var $CurrySoftware$elm_datepicker$DatePicker$defaultSettings = {
	dL: $CurrySoftware$elm_datepicker$DatePicker$formatCell,
	hV: $CurrySoftware$elm_datepicker$DatePicker$off,
	bR: 'elm-datepicker--',
	dP: _List_Nil,
	dS: $justinmimbs$date$Date$format('yyyy-MM-dd'),
	dT: $CurrySoftware$elm_datepicker$DatePicker$Date$formatDay,
	c5: 6,
	ek: _List_fromArray(
		[
			$elm$html$Html$Attributes$required(false)
		]),
	iB: _List_Nil,
	el: $elm$core$Maybe$Nothing,
	em: $elm$core$Maybe$Nothing,
	c8: $elm$core$Basics$always(false),
	eI: $CurrySoftware$elm_datepicker$DatePicker$Date$formatMonth,
	e6: $justinmimbs$date$Date$fromIsoString,
	jj: 'Please pick a date...',
	fO: $elm$core$String$fromInt
};
var $author$project$Types$DeleteComplete = function (a) {
	return {$: 14, a: a};
};
var $author$project$Utils$boolToString = function (b) {
	if (b) {
		return 'true';
	} else {
		return 'false';
	}
};
var $author$project$JSON$saveIntentionResponseDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'id',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Types$DBRef));
var $author$project$API$deleteIntention = F4(
	function (apiUrl, authToken, intentionId, deleteDescendants) {
		return $elm$http$Http$request(
			{
				br: $elm$http$Http$emptyBody,
				bu: A2($elm$http$Http$expectJson, $author$project$Types$DeleteComplete, $author$project$JSON$saveIntentionResponseDecoder),
				bw: $author$project$API$headers(authToken),
				bB: 'DELETE',
				bF: $elm$core$Maybe$Just(10000.0),
				bI: $elm$core$Maybe$Nothing,
				bJ: apiUrl + ('/intentions/' + ($elm$core$String$fromInt(intentionId) + ('?delete-descendants=' + $author$project$Utils$boolToString(deleteDescendants))))
			});
	});
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $author$project$API$deleteView = F3(
	function (apiUrl, authToken, viewId) {
		return $elm$http$Http$request(
			{
				br: $elm$http$Http$emptyBody,
				bu: $elm$http$Http$expectWhatever(
					function (_v0) {
						return $author$project$Types$NoOp;
					}),
				bw: $author$project$API$headers(authToken),
				bB: 'DELETE',
				bF: $elm$core$Maybe$Just(10000.0),
				bI: $elm$core$Maybe$Nothing,
				bJ: apiUrl + ('/views/' + $elm$core$String$fromInt(viewId))
			});
	});
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Utils$httpErrorToString = function (err) {
	switch (err.$) {
		case 1:
			return 'The request timed out :(. There may be something wrong with your connection, or the API may be unresponsive. Try refreshing.';
		case 2:
			return 'Looks like there\'s something wrong with your network connection. Try refreshing.';
		default:
			return 'Something went wrong - please contact the developer.';
	}
};
var $author$project$Utils$intentionFormToDTO = function (f) {
	return {ib: f.ib, $8: f.$8, iw: f.iw, jh: f.jh, jJ: f.jJ, jU: f.jU};
};
var $author$project$Utils$isSome = function (x) {
	if (!x.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Ports$logout = _Platform_outgoingPort(
	'logout',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Types$SaveComplete = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $author$project$JSON$intentionFormEncoder = function (intentionForm) {
	var statusString = function () {
		var _v0 = intentionForm.jJ;
		if (!_v0) {
			return 'todo';
		} else {
			return 'done';
		}
	}();
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$append,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					function (d) {
						return _List_fromArray(
							[
								_Utils_Tuple2(
								'due-date',
								$elm$json$Json$Encode$string(
									$justinmimbs$date$Date$toIsoString(d)))
							]);
					},
					intentionForm.$8)),
			A2(
				$elm$core$List$append,
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						function (d) {
							return _List_fromArray(
								[
									_Utils_Tuple2(
									'description',
									$elm$json$Json$Encode$string(d))
								]);
						},
						intentionForm.ib)),
				_List_fromArray(
					[
						_Utils_Tuple2(
						'title',
						$elm$json$Json$Encode$string(intentionForm.jU)),
						_Utils_Tuple2(
						'status',
						$elm$json$Json$Encode$string(statusString)),
						_Utils_Tuple2(
						'parents',
						A2(
							$elm$json$Json$Encode$list,
							$elm$json$Json$Encode$int,
							$elm$core$Set$toList(intentionForm.jh)))
					]))));
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$API$saveIntention = F3(
	function (apiUrl, authToken, intentionForm) {
		var _v0 = intentionForm.iw;
		if (!_v0.$) {
			var id = _v0.a;
			return $elm$http$Http$request(
				{
					br: $elm$http$Http$jsonBody(
						$author$project$JSON$intentionFormEncoder(intentionForm)),
					bu: A2(
						$elm$http$Http$expectJson,
						$author$project$Types$SaveComplete(intentionForm),
						$author$project$JSON$saveIntentionResponseDecoder),
					bw: $author$project$API$headers(authToken),
					bB: 'PUT',
					bF: $elm$core$Maybe$Just(10000.0),
					bI: $elm$core$Maybe$Nothing,
					bJ: apiUrl + ('/intentions/' + $elm$core$String$fromInt(id))
				});
		} else {
			return $elm$http$Http$request(
				{
					br: $elm$http$Http$jsonBody(
						$author$project$JSON$intentionFormEncoder(intentionForm)),
					bu: A2(
						$elm$http$Http$expectJson,
						$author$project$Types$SaveComplete(intentionForm),
						$author$project$JSON$saveIntentionResponseDecoder),
					bw: $author$project$API$headers(authToken),
					bB: 'POST',
					bF: $elm$core$Maybe$Just(10000.0),
					bI: $elm$core$Maybe$Nothing,
					bJ: apiUrl + '/intentions/'
				});
		}
	});
var $author$project$Types$ViewSaveComplete = F2(
	function (a, b) {
		return {$: 35, a: a, b: b};
	});
var $author$project$JSON$saveViewResponseDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'id',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Types$DBRef));
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$JSON$viewFormEncoder = function (view) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'root-node',
				$elm$json$Json$Encode$int(view.a)),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(view.b))
			]));
};
var $author$project$API$saveView = F3(
	function (apiUrl, authToken, viewForm) {
		return $elm$http$Http$request(
			{
				br: $elm$http$Http$jsonBody(
					$author$project$JSON$viewFormEncoder(viewForm)),
				bu: A2(
					$elm$http$Http$expectJson,
					$author$project$Types$ViewSaveComplete(viewForm),
					$author$project$JSON$saveViewResponseDecoder),
				bw: $author$project$API$headers(authToken),
				bB: 'POST',
				bF: $elm$core$Maybe$Just(10000.0),
				bI: $elm$core$Maybe$Nothing,
				bJ: apiUrl + '/views/'
			});
	});
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.g_;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.gf,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.g$,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.gV,
					_Utils_ap(http, url.gk)),
				url.gS)));
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $pablen$toasty$Toasty$Config = $elm$core$Basics$identity;
var $pablen$toasty$Toasty$config = {dO: _List_Nil, h8: 5000, eo: _List_Nil, fA: _List_Nil, fB: _List_Nil, fC: 0};
var $pablen$toasty$Toasty$containerAttrs = F2(
	function (attrs, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{dO: attrs});
	});
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $pablen$toasty$Toasty$Defaults$containerAttrs = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'position', 'fixed'),
		A2($elm$html$Html$Attributes$style, 'top', '0'),
		A2($elm$html$Html$Attributes$style, 'right', '0'),
		A2($elm$html$Html$Attributes$style, 'width', '100%'),
		A2($elm$html$Html$Attributes$style, 'max-width', '300px'),
		A2($elm$html$Html$Attributes$style, 'list-style-type', 'none'),
		A2($elm$html$Html$Attributes$style, 'padding', '0'),
		A2($elm$html$Html$Attributes$style, 'margin', '0')
	]);
var $pablen$toasty$Toasty$delay = F2(
	function (time, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{h8: time});
	});
var $pablen$toasty$Toasty$itemAttrs = F2(
	function (attrs, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{eo: attrs});
	});
var $pablen$toasty$Toasty$Defaults$itemAttrs = _List_fromArray(
	[
		A2($elm$html$Html$Attributes$style, 'margin', '1em 1em 0 1em'),
		A2($elm$html$Html$Attributes$style, 'max-height', '100px'),
		A2($elm$html$Html$Attributes$style, 'transition', 'max-height 0.6s, margin-top 0.6s')
	]);
var $pablen$toasty$Toasty$transitionInAttrs = F2(
	function (attrs, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{fA: attrs});
	});
var $pablen$toasty$Toasty$Defaults$transitionInAttrs = _List_fromArray(
	[
		$elm$html$Html$Attributes$class('animated bounceInRight')
	]);
var $pablen$toasty$Toasty$transitionOutAttrs = F2(
	function (attrs, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{fB: attrs});
	});
var $pablen$toasty$Toasty$Defaults$transitionOutAttrs = _List_fromArray(
	[
		$elm$html$Html$Attributes$class('animated fadeOutRightBig'),
		A2($elm$html$Html$Attributes$style, 'max-height', '0'),
		A2($elm$html$Html$Attributes$style, 'margin-top', '0')
	]);
var $pablen$toasty$Toasty$transitionOutDuration = F2(
	function (time, _v0) {
		var cfg = _v0;
		return _Utils_update(
			cfg,
			{fC: time});
	});
var $pablen$toasty$Toasty$Defaults$config = A2(
	$pablen$toasty$Toasty$delay,
	5000,
	A2(
		$pablen$toasty$Toasty$itemAttrs,
		$pablen$toasty$Toasty$Defaults$itemAttrs,
		A2(
			$pablen$toasty$Toasty$containerAttrs,
			$pablen$toasty$Toasty$Defaults$containerAttrs,
			A2(
				$pablen$toasty$Toasty$transitionInAttrs,
				$pablen$toasty$Toasty$Defaults$transitionInAttrs,
				A2(
					$pablen$toasty$Toasty$transitionOutAttrs,
					$pablen$toasty$Toasty$Defaults$transitionOutAttrs,
					A2($pablen$toasty$Toasty$transitionOutDuration, 700, $pablen$toasty$Toasty$config))))));
var $author$project$Config$toastConfig = A2(
	$pablen$toasty$Toasty$transitionOutAttrs,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('slide-out-bottom')
		]),
	A2(
		$pablen$toasty$Toasty$transitionInAttrs,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('slide-in-bottom')
			]),
		A2(
			$pablen$toasty$Toasty$containerAttrs,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('toast')
				]),
			A2(
				$pablen$toasty$Toasty$delay,
				8000,
				A2($pablen$toasty$Toasty$transitionOutDuration, 500, $pablen$toasty$Toasty$Defaults$config)))));
var $CurrySoftware$elm_datepicker$DatePicker$Disabled = function (a) {
	return {$: 2, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$EmptyString = {$: 1};
var $CurrySoftware$elm_datepicker$DatePicker$FailedInput = function (a) {
	return {$: 1, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$Invalid = function (a) {
	return {$: 0, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$None = {$: 0};
var $CurrySoftware$elm_datepicker$DatePicker$Picked = function (a) {
	return {$: 2, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$update = F3(
	function (settings, msg, _v0) {
		var model = _v0;
		var forceOpen = model.a6;
		var focused = model.an;
		switch (msg.$) {
			case 0:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							an: $elm$core$Maybe$Just(date),
							bj: date
						}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 1:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							an: $elm$core$Maybe$Just(date)
						}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 2:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{an: $elm$core$Maybe$Nothing, aC: $elm$core$Maybe$Nothing, aV: false}),
					$CurrySoftware$elm_datepicker$DatePicker$Picked(date));
			case 3:
				var text = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aC: $elm$core$Maybe$Just(text)
						}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 4:
				if (forceOpen) {
					return _Utils_Tuple2(model, $CurrySoftware$elm_datepicker$DatePicker$None);
				} else {
					var dateEvent = function () {
						var _v5 = model.aC;
						if (_v5.$ === 1) {
							return $CurrySoftware$elm_datepicker$DatePicker$FailedInput($CurrySoftware$elm_datepicker$DatePicker$EmptyString);
						} else {
							if (_v5.a === '') {
								return $CurrySoftware$elm_datepicker$DatePicker$FailedInput($CurrySoftware$elm_datepicker$DatePicker$EmptyString);
							} else {
								var rawInput = _v5.a;
								var _v6 = settings.e6(rawInput);
								if (!_v6.$) {
									var date = _v6.a;
									return settings.c8(date) ? $CurrySoftware$elm_datepicker$DatePicker$FailedInput(
										$CurrySoftware$elm_datepicker$DatePicker$Disabled(date)) : $CurrySoftware$elm_datepicker$DatePicker$Picked(date);
								} else {
									var e = _v6.a;
									return $CurrySoftware$elm_datepicker$DatePicker$FailedInput(
										$CurrySoftware$elm_datepicker$DatePicker$Invalid(e));
								}
							}
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								an: function () {
									if (dateEvent.$ === 2) {
										var date = dateEvent.a;
										return $elm$core$Maybe$Just(date);
									} else {
										return model.an;
									}
								}(),
								aC: function () {
									if (dateEvent.$ === 2) {
										return $elm$core$Maybe$Nothing;
									} else {
										return model.aC;
									}
								}()
							}),
						dateEvent);
				}
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a6: false, aV: true}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aV: forceOpen}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a6: true}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a6: false}),
					$CurrySoftware$elm_datepicker$DatePicker$None);
		}
	});
var $peterszerzo$elm_porter$Porter$encode = F3(
	function (encodeReq, id, msg) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int(id)),
					_Utils_Tuple2(
					'msg',
					encodeReq(msg))
				]));
	});
var $peterszerzo$elm_porter$Porter$handleResponse = F5(
	function (config, _v0, id, res, _v1) {
		var model = _v0;
		var req = _v1.a;
		var mappers = _v1.b;
		var finalResponseHandler = _v1.c;
		if (!mappers.b) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						aT: A2($elm$core$Dict$remove, id, model.aT)
					}),
				A2(
					$elm$core$Task$perform,
					finalResponseHandler,
					$elm$core$Task$succeed(res)));
		} else {
			var mapper = mappers.a;
			var mappersTail = mappers.b;
			var mappedResponse = mapper(res);
			var newMappers = function () {
				var reqMappers = mappedResponse.b;
				return reqMappers;
			}();
			var newReq = function () {
				var unwrappedMsg = mappedResponse.a;
				return unwrappedMsg;
			}();
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						aT: A2($elm$core$Dict$remove, id, model.aT)
					}),
				A2(
					$peterszerzo$elm_porter$Porter$Internals$runSendRequest,
					config,
					A3(
						$peterszerzo$elm_porter$Porter$Internals$RequestWithHandler,
						newReq,
						_Utils_ap(newMappers, mappersTail),
						finalResponseHandler)));
		}
	});
var $peterszerzo$elm_porter$Porter$maxSignedInt = 2147483647;
var $peterszerzo$elm_porter$Porter$safeId = F2(
	function (proposed, used) {
		safeId:
		while (true) {
			if (_Utils_cmp(proposed, $peterszerzo$elm_porter$Porter$maxSignedInt) > -1) {
				var $temp$proposed = 0,
					$temp$used = used;
				proposed = $temp$proposed;
				used = $temp$used;
				continue safeId;
			} else {
				if (A2($elm$core$Dict$member, proposed, used)) {
					var $temp$proposed = proposed + 1,
						$temp$used = used;
					proposed = $temp$proposed;
					used = $temp$used;
					continue safeId;
				} else {
					return proposed;
				}
			}
		}
	});
var $peterszerzo$elm_porter$Porter$update = F3(
	function (config, msg, _v0) {
		var model = _v0;
		switch (msg.$) {
			case 0:
				var req = msg.a;
				var id = A2($peterszerzo$elm_porter$Porter$safeId, model.eM, model.aT);
				var extractMsg = function (_v2) {
					var message = _v2.a;
					return message;
				};
				return _Utils_Tuple2(
					{
						aT: A3($elm$core$Dict$insert, id, req, model.aT),
						eM: id + 1
					},
					config.jb(
						A3(
							$peterszerzo$elm_porter$Porter$encode,
							config.ik,
							id,
							extractMsg(req))));
			case 1:
				var val = msg.a;
				return A2(
					$elm$core$Result$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Result$map,
						function (_v3) {
							var id = _v3.a;
							var res = _v3.b;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									A4($peterszerzo$elm_porter$Porter$handleResponse, config, model, id, res),
									A2($elm$core$Dict$get, id, model.aT)));
						},
						A2(
							$elm$json$Json$Decode$decodeValue,
							A3(
								$elm$json$Json$Decode$map2,
								F2(
									function (id, res) {
										return _Utils_Tuple2(id, res);
									}),
								A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
								A2($elm$json$Json$Decode$field, 'msg', config.h6)),
							val)));
			default:
				var multiRequest = msg.a;
				return _Utils_Tuple2(
					model,
					A3($peterszerzo$elm_porter$Porter$Internals$multiSend, config, $elm$core$Basics$identity, multiRequest));
		}
	});
var $pablen$toasty$Toasty$Leaving = 1;
var $pablen$toasty$Toasty$Remove = function (a) {
	return {$: 1, a: a};
};
var $pablen$toasty$Toasty$update = F4(
	function (_v0, tagger, msg, model) {
		var cfg = _v0;
		var _v1 = model.j$;
		var toasts = _v1.a;
		var seed = _v1.b;
		switch (msg.$) {
			case 0:
				var toast = msg.a;
				return A4(
					$pablen$toasty$Toasty$addToast,
					$pablen$toasty$Toasty$config,
					tagger,
					toast,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
			case 1:
				var targetId = msg.a;
				var newStack = A2(
					$elm$core$List$filter,
					function (_v3) {
						var id = _v3.a;
						var toast = _v3.b;
						var status = _v3.c;
						return !_Utils_eq(id, targetId);
					},
					toasts);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							j$: A2($pablen$toasty$Toasty$Stack, newStack, seed)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var targetId = msg.a;
				var newStack = A2(
					$elm$core$List$map,
					function (_v5) {
						var id = _v5.a;
						var status = _v5.b;
						var toast = _v5.c;
						return _Utils_eq(id, targetId) ? _Utils_Tuple3(id, 1, toast) : _Utils_Tuple3(id, status, toast);
					},
					toasts);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							j$: A2($pablen$toasty$Toasty$Stack, newStack, seed)
						}),
					A2(
						$elm$core$Task$perform,
						function (_v4) {
							return tagger(
								$pablen$toasty$Toasty$Remove(targetId));
						},
						$elm$core$Process$sleep(cfg.fC)));
		}
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $elm$core$String$toUpper = _String_toUpper;
var $elm_community$graph$Graph$AcyclicGraph = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm_community$graph$Graph$Edge = F3(
	function (from, to, label) {
		return {bY: from, iK: label, cf: to};
	});
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm_community$intdict$IntDict$Empty = {$: 0};
var $elm_community$intdict$IntDict$empty = $elm_community$intdict$IntDict$Empty;
var $elm_community$intdict$IntDict$findMin = function (dict) {
	findMin:
	while (true) {
		switch (dict.$) {
			case 0:
				return $elm$core$Maybe$Nothing;
			case 1:
				var l = dict.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(l.iI, l.aY));
			default:
				var i = dict.a;
				var $temp$dict = i.g;
				dict = $temp$dict;
				continue findMin;
		}
	}
};
var $elm_community$intdict$IntDict$Inner = function (a) {
	return {$: 2, a: a};
};
var $elm_community$intdict$IntDict$size = function (dict) {
	switch (dict.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			var i = dict.a;
			return i.bE;
	}
};
var $elm_community$intdict$IntDict$inner = F3(
	function (p, l, r) {
		var _v0 = _Utils_Tuple2(l, r);
		if (!_v0.a.$) {
			var _v1 = _v0.a;
			return r;
		} else {
			if (!_v0.b.$) {
				var _v2 = _v0.b;
				return l;
			} else {
				return $elm_community$intdict$IntDict$Inner(
					{
						g: l,
						l: p,
						h: r,
						bE: $elm_community$intdict$IntDict$size(l) + $elm_community$intdict$IntDict$size(r)
					});
			}
		}
	});
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm_community$intdict$IntDict$highestBitSet = function (n) {
	var shiftOr = F2(
		function (i, shift) {
			return i | (i >>> shift);
		});
	var n1 = A2(shiftOr, n, 1);
	var n2 = A2(shiftOr, n1, 2);
	var n3 = A2(shiftOr, n2, 4);
	var n4 = A2(shiftOr, n3, 8);
	var n5 = A2(shiftOr, n4, 16);
	return n5 & (~(n5 >>> 1));
};
var $elm_community$intdict$IntDict$signBit = $elm_community$intdict$IntDict$highestBitSet(-1);
var $elm_community$intdict$IntDict$isBranchingBitSet = function (p) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Bitwise$xor($elm_community$intdict$IntDict$signBit),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Bitwise$and(p.bQ),
			$elm$core$Basics$neq(0)));
};
var $elm_community$intdict$IntDict$higherBitMask = function (branchingBit) {
	return branchingBit ^ (~(branchingBit - 1));
};
var $elm_community$intdict$IntDict$lcp = F2(
	function (x, y) {
		var branchingBit = $elm_community$intdict$IntDict$highestBitSet(x ^ y);
		var mask = $elm_community$intdict$IntDict$higherBitMask(branchingBit);
		var prefixBits = x & mask;
		return {bQ: branchingBit, aG: prefixBits};
	});
var $elm_community$intdict$IntDict$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm_community$intdict$IntDict$leaf = F2(
	function (k, v) {
		return $elm_community$intdict$IntDict$Leaf(
			{iI: k, aY: v});
	});
var $elm_community$intdict$IntDict$prefixMatches = F2(
	function (p, n) {
		return _Utils_eq(
			n & $elm_community$intdict$IntDict$higherBitMask(p.bQ),
			p.aG);
	});
var $elm_community$intdict$IntDict$update = F3(
	function (key, alter, dict) {
		var join = F2(
			function (_v2, _v3) {
				var k1 = _v2.a;
				var l = _v2.b;
				var k2 = _v3.a;
				var r = _v3.b;
				var prefix = A2($elm_community$intdict$IntDict$lcp, k1, k2);
				return A2($elm_community$intdict$IntDict$isBranchingBitSet, prefix, k2) ? A3($elm_community$intdict$IntDict$inner, prefix, l, r) : A3($elm_community$intdict$IntDict$inner, prefix, r, l);
			});
		var alteredNode = function (mv) {
			var _v1 = alter(mv);
			if (!_v1.$) {
				var v = _v1.a;
				return A2($elm_community$intdict$IntDict$leaf, key, v);
			} else {
				return $elm_community$intdict$IntDict$empty;
			}
		};
		switch (dict.$) {
			case 0:
				return alteredNode($elm$core$Maybe$Nothing);
			case 1:
				var l = dict.a;
				return _Utils_eq(l.iI, key) ? alteredNode(
					$elm$core$Maybe$Just(l.aY)) : A2(
					join,
					_Utils_Tuple2(
						key,
						alteredNode($elm$core$Maybe$Nothing)),
					_Utils_Tuple2(l.iI, dict));
			default:
				var i = dict.a;
				return A2($elm_community$intdict$IntDict$prefixMatches, i.l, key) ? (A2($elm_community$intdict$IntDict$isBranchingBitSet, i.l, key) ? A3(
					$elm_community$intdict$IntDict$inner,
					i.l,
					i.g,
					A3($elm_community$intdict$IntDict$update, key, alter, i.h)) : A3(
					$elm_community$intdict$IntDict$inner,
					i.l,
					A3($elm_community$intdict$IntDict$update, key, alter, i.g),
					i.h)) : A2(
					join,
					_Utils_Tuple2(
						key,
						alteredNode($elm$core$Maybe$Nothing)),
					_Utils_Tuple2(i.l.aG, dict));
		}
	});
var $elm_community$intdict$IntDict$insert = F3(
	function (key, value, dict) {
		return A3(
			$elm_community$intdict$IntDict$update,
			key,
			$elm$core$Basics$always(
				$elm$core$Maybe$Just(value)),
			dict);
	});
var $elm_community$intdict$IntDict$Disjunct = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm_community$intdict$IntDict$Left = 0;
var $elm_community$intdict$IntDict$Parent = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm_community$intdict$IntDict$Right = 1;
var $elm_community$intdict$IntDict$SamePrefix = {$: 0};
var $elm_community$intdict$IntDict$combineBits = F3(
	function (a, b, mask) {
		return (a & (~mask)) | (b & mask);
	});
var $elm_community$intdict$IntDict$mostSignificantBranchingBit = F2(
	function (a, b) {
		return (_Utils_eq(a, $elm_community$intdict$IntDict$signBit) || _Utils_eq(b, $elm_community$intdict$IntDict$signBit)) ? $elm_community$intdict$IntDict$signBit : A2($elm$core$Basics$max, a, b);
	});
var $elm_community$intdict$IntDict$determineBranchRelation = F2(
	function (l, r) {
		var rp = r.l;
		var lp = l.l;
		var mask = $elm_community$intdict$IntDict$highestBitSet(
			A2($elm_community$intdict$IntDict$mostSignificantBranchingBit, lp.bQ, rp.bQ));
		var modifiedRightPrefix = A3($elm_community$intdict$IntDict$combineBits, rp.aG, ~lp.aG, mask);
		var prefix = A2($elm_community$intdict$IntDict$lcp, lp.aG, modifiedRightPrefix);
		var childEdge = F2(
			function (branchPrefix, c) {
				return A2($elm_community$intdict$IntDict$isBranchingBitSet, branchPrefix, c.l.aG) ? 1 : 0;
			});
		return _Utils_eq(lp, rp) ? $elm_community$intdict$IntDict$SamePrefix : (_Utils_eq(prefix, lp) ? A2(
			$elm_community$intdict$IntDict$Parent,
			0,
			A2(childEdge, l.l, r)) : (_Utils_eq(prefix, rp) ? A2(
			$elm_community$intdict$IntDict$Parent,
			1,
			A2(childEdge, r.l, l)) : A2(
			$elm_community$intdict$IntDict$Disjunct,
			prefix,
			A2(childEdge, prefix, l))));
	});
var $elm_community$intdict$IntDict$get = F2(
	function (key, dict) {
		get:
		while (true) {
			switch (dict.$) {
				case 0:
					return $elm$core$Maybe$Nothing;
				case 1:
					var l = dict.a;
					return _Utils_eq(l.iI, key) ? $elm$core$Maybe$Just(l.aY) : $elm$core$Maybe$Nothing;
				default:
					var i = dict.a;
					if (!A2($elm_community$intdict$IntDict$prefixMatches, i.l, key)) {
						return $elm$core$Maybe$Nothing;
					} else {
						if (A2($elm_community$intdict$IntDict$isBranchingBitSet, i.l, key)) {
							var $temp$key = key,
								$temp$dict = i.h;
							key = $temp$key;
							dict = $temp$dict;
							continue get;
						} else {
							var $temp$key = key,
								$temp$dict = i.g;
							key = $temp$key;
							dict = $temp$dict;
							continue get;
						}
					}
			}
		}
	});
var $elm_community$intdict$IntDict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm_community$intdict$IntDict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm_community$intdict$IntDict$intersect = F2(
	function (l, r) {
		intersect:
		while (true) {
			var _v0 = _Utils_Tuple2(l, r);
			_v0$1:
			while (true) {
				_v0$2:
				while (true) {
					switch (_v0.a.$) {
						case 0:
							var _v1 = _v0.a;
							return $elm_community$intdict$IntDict$Empty;
						case 1:
							switch (_v0.b.$) {
								case 0:
									break _v0$1;
								case 1:
									break _v0$2;
								default:
									break _v0$2;
							}
						default:
							switch (_v0.b.$) {
								case 0:
									break _v0$1;
								case 1:
									var lr = _v0.b.a;
									var _v3 = A2($elm_community$intdict$IntDict$get, lr.iI, l);
									if (!_v3.$) {
										var v = _v3.a;
										return A2($elm_community$intdict$IntDict$leaf, lr.iI, v);
									} else {
										return $elm_community$intdict$IntDict$Empty;
									}
								default:
									var il = _v0.a.a;
									var ir = _v0.b.a;
									var _v4 = A2($elm_community$intdict$IntDict$determineBranchRelation, il, ir);
									switch (_v4.$) {
										case 0:
											return A3(
												$elm_community$intdict$IntDict$inner,
												il.l,
												A2($elm_community$intdict$IntDict$intersect, il.g, ir.g),
												A2($elm_community$intdict$IntDict$intersect, il.h, ir.h));
										case 1:
											if (!_v4.a) {
												if (_v4.b === 1) {
													var _v5 = _v4.a;
													var _v6 = _v4.b;
													var $temp$l = il.h,
														$temp$r = r;
													l = $temp$l;
													r = $temp$r;
													continue intersect;
												} else {
													var _v9 = _v4.a;
													var _v10 = _v4.b;
													var $temp$l = il.g,
														$temp$r = r;
													l = $temp$l;
													r = $temp$r;
													continue intersect;
												}
											} else {
												if (_v4.b === 1) {
													var _v7 = _v4.a;
													var _v8 = _v4.b;
													var $temp$l = l,
														$temp$r = ir.h;
													l = $temp$l;
													r = $temp$r;
													continue intersect;
												} else {
													var _v11 = _v4.a;
													var _v12 = _v4.b;
													var $temp$l = l,
														$temp$r = ir.g;
													l = $temp$l;
													r = $temp$r;
													continue intersect;
												}
											}
										default:
											return $elm_community$intdict$IntDict$Empty;
									}
							}
					}
				}
				var ll = _v0.a.a;
				return A2($elm_community$intdict$IntDict$member, ll.iI, r) ? l : $elm_community$intdict$IntDict$Empty;
			}
			var _v2 = _v0.b;
			return $elm_community$intdict$IntDict$Empty;
		}
	});
var $elm_community$graph$Graph$crashHack = function (msg) {
	crashHack:
	while (true) {
		var $temp$msg = msg;
		msg = $temp$msg;
		continue crashHack;
	}
};
var $elm_community$graph$Graph$unGraph = function (graph) {
	var rep = graph;
	return rep;
};
var $elm_community$graph$Graph$get = function (nodeId) {
	return A2(
		$elm$core$Basics$composeR,
		$elm_community$graph$Graph$unGraph,
		$elm_community$intdict$IntDict$get(nodeId));
};
var $elm_community$graph$Graph$unsafeGet = F3(
	function (msg, id, graph) {
		var _v0 = A2($elm_community$graph$Graph$get, id, graph);
		if (_v0.$ === 1) {
			return $elm_community$graph$Graph$crashHack(msg);
		} else {
			var ctx = _v0.a;
			return ctx;
		}
	});
var $elm_community$graph$Graph$checkForBackEdges = F2(
	function (ordering, graph) {
		var success = function (_v3) {
			return A2($elm_community$graph$Graph$AcyclicGraph, graph, ordering);
		};
		var check = F2(
			function (id, _v2) {
				var backSet = _v2.a;
				var error = 'Graph.checkForBackEdges: `ordering` didn\'t contain `id`';
				var ctx = A3($elm_community$graph$Graph$unsafeGet, error, id, graph);
				var backSetWithId = A3($elm_community$intdict$IntDict$insert, id, 0, backSet);
				var backEdges = A2($elm_community$intdict$IntDict$intersect, ctx.n, backSetWithId);
				var _v0 = $elm_community$intdict$IntDict$findMin(backEdges);
				if (_v0.$ === 1) {
					return $elm$core$Result$Ok(
						_Utils_Tuple2(backSetWithId, 0));
				} else {
					var _v1 = _v0.a;
					var to = _v1.a;
					var label = _v1.b;
					return $elm$core$Result$Err(
						A3($elm_community$graph$Graph$Edge, id, to, label));
				}
			});
		return A2(
			$elm$core$Result$map,
			success,
			A3(
				$elm$core$List$foldl,
				F2(
					function (id, res) {
						return A2(
							$elm$core$Result$andThen,
							check(id),
							res);
					}),
				$elm$core$Result$Ok(
					_Utils_Tuple2($elm_community$intdict$IntDict$empty, 0)),
				ordering));
	});
var $elm_community$intdict$IntDict$foldr = F3(
	function (f, acc, dict) {
		foldr:
		while (true) {
			switch (dict.$) {
				case 0:
					return acc;
				case 1:
					var l = dict.a;
					return A3(f, l.iI, l.aY, acc);
				default:
					var i = dict.a;
					var $temp$f = f,
						$temp$acc = A3($elm_community$intdict$IntDict$foldr, f, acc, i.h),
						$temp$dict = i.g;
					f = $temp$f;
					acc = $temp$acc;
					dict = $temp$dict;
					continue foldr;
			}
		}
	});
var $elm_community$intdict$IntDict$keys = function (dict) {
	return A3(
		$elm_community$intdict$IntDict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm_community$graph$Graph$alongOutgoingEdges = function (ctx) {
	return $elm_community$intdict$IntDict$keys(ctx.n);
};
var $elm_community$graph$Graph$Graph = $elm$core$Basics$identity;
var $elm_community$intdict$IntDict$foldl = F3(
	function (f, acc, dict) {
		foldl:
		while (true) {
			switch (dict.$) {
				case 0:
					return acc;
				case 1:
					var l = dict.a;
					return A3(f, l.iI, l.aY, acc);
				default:
					var i = dict.a;
					var $temp$f = f,
						$temp$acc = A3($elm_community$intdict$IntDict$foldl, f, acc, i.g),
						$temp$dict = i.h;
					f = $temp$f;
					acc = $temp$acc;
					dict = $temp$dict;
					continue foldl;
			}
		}
	});
var $elm_community$graph$Graph$applyEdgeDiff = F3(
	function (nodeId, diff, graphRep) {
		var updateOutgoingEdge = F2(
			function (upd, node) {
				return _Utils_update(
					node,
					{
						n: A3($elm_community$intdict$IntDict$update, nodeId, upd, node.n)
					});
			});
		var updateIncomingEdge = F2(
			function (upd, node) {
				return _Utils_update(
					node,
					{
						q: A3($elm_community$intdict$IntDict$update, nodeId, upd, node.q)
					});
			});
		var flippedFoldl = F3(
			function (f, dict, acc) {
				return A3($elm_community$intdict$IntDict$foldl, f, acc, dict);
			});
		var edgeUpdateToMaybe = function (edgeUpdate) {
			if (!edgeUpdate.$) {
				var lbl = edgeUpdate.a;
				return $elm$core$Maybe$Just(lbl);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var updateAdjacency = F3(
			function (updateEdge, updatedId, edgeUpdate) {
				var updateLbl = updateEdge(
					$elm$core$Basics$always(
						edgeUpdateToMaybe(edgeUpdate)));
				return A2(
					$elm_community$intdict$IntDict$update,
					updatedId,
					$elm$core$Maybe$map(updateLbl));
			});
		return A3(
			flippedFoldl,
			updateAdjacency(updateOutgoingEdge),
			diff.n,
			A3(
				flippedFoldl,
				updateAdjacency(updateIncomingEdge),
				diff.q,
				graphRep));
	});
var $elm_community$graph$Graph$Insert = function (a) {
	return {$: 0, a: a};
};
var $elm_community$graph$Graph$Remove = function (a) {
	return {$: 1, a: a};
};
var $elm_community$graph$Graph$emptyDiff = {q: $elm_community$intdict$IntDict$empty, n: $elm_community$intdict$IntDict$empty};
var $elm_community$graph$Graph$computeEdgeDiff = F2(
	function (old, _new) {
		var collectUpdates = F3(
			function (edgeUpdate, updatedId, label) {
				var replaceUpdate = function (old_) {
					var _v5 = _Utils_Tuple2(
						old_,
						edgeUpdate(label));
					if (!_v5.a.$) {
						if (_v5.a.a.$ === 1) {
							if (!_v5.b.$) {
								var oldLbl = _v5.a.a.a;
								var newLbl = _v5.b.a;
								return _Utils_eq(oldLbl, newLbl) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
									$elm_community$graph$Graph$Insert(newLbl));
							} else {
								return $elm_community$graph$Graph$crashHack('Graph.computeEdgeDiff: Collected two removals for the same edge. This is an error in the implementation of Graph and you should file a bug report!');
							}
						} else {
							return $elm_community$graph$Graph$crashHack('Graph.computeEdgeDiff: Collected inserts before removals. This is an error in the implementation of Graph and you should file a bug report!');
						}
					} else {
						var _v6 = _v5.a;
						var eu = _v5.b;
						return $elm$core$Maybe$Just(eu);
					}
				};
				return A2($elm_community$intdict$IntDict$update, updatedId, replaceUpdate);
			});
		var collect = F3(
			function (edgeUpdate, adj, updates) {
				return A3(
					$elm_community$intdict$IntDict$foldl,
					collectUpdates(edgeUpdate),
					updates,
					adj);
			});
		var _v0 = _Utils_Tuple2(old, _new);
		if (_v0.a.$ === 1) {
			if (_v0.b.$ === 1) {
				var _v1 = _v0.a;
				var _v2 = _v0.b;
				return $elm_community$graph$Graph$emptyDiff;
			} else {
				var _v4 = _v0.a;
				var ins = _v0.b.a;
				return {
					q: A3(collect, $elm_community$graph$Graph$Insert, ins.n, $elm_community$intdict$IntDict$empty),
					n: A3(collect, $elm_community$graph$Graph$Insert, ins.q, $elm_community$intdict$IntDict$empty)
				};
			}
		} else {
			if (_v0.b.$ === 1) {
				var rem = _v0.a.a;
				var _v3 = _v0.b;
				return {
					q: A3(collect, $elm_community$graph$Graph$Remove, rem.n, $elm_community$intdict$IntDict$empty),
					n: A3(collect, $elm_community$graph$Graph$Remove, rem.q, $elm_community$intdict$IntDict$empty)
				};
			} else {
				var rem = _v0.a.a;
				var ins = _v0.b.a;
				return _Utils_eq(rem, ins) ? $elm_community$graph$Graph$emptyDiff : {
					q: A3(
						collect,
						$elm_community$graph$Graph$Insert,
						ins.n,
						A3(collect, $elm_community$graph$Graph$Remove, rem.n, $elm_community$intdict$IntDict$empty)),
					n: A3(
						collect,
						$elm_community$graph$Graph$Insert,
						ins.q,
						A3(collect, $elm_community$graph$Graph$Remove, rem.q, $elm_community$intdict$IntDict$empty))
				};
			}
		}
	});
var $elm_community$intdict$IntDict$filter = F2(
	function (predicate, dict) {
		var add = F3(
			function (k, v, d) {
				return A2(predicate, k, v) ? A3($elm_community$intdict$IntDict$insert, k, v, d) : d;
			});
		return A3($elm_community$intdict$IntDict$foldl, add, $elm_community$intdict$IntDict$empty, dict);
	});
var $elm_community$graph$Graph$update = F2(
	function (nodeId, updater) {
		var wrappedUpdater = function (rep) {
			var old = A2($elm_community$intdict$IntDict$get, nodeId, rep);
			var filterInvalidEdges = function (ctx) {
				return $elm_community$intdict$IntDict$filter(
					F2(
						function (id, _v0) {
							return _Utils_eq(id, ctx.iX.iw) || A2($elm_community$intdict$IntDict$member, id, rep);
						}));
			};
			var cleanUpEdges = function (ctx) {
				return _Utils_update(
					ctx,
					{
						q: A2(filterInvalidEdges, ctx, ctx.q),
						n: A2(filterInvalidEdges, ctx, ctx.n)
					});
			};
			var _new = A2(
				$elm$core$Maybe$map,
				cleanUpEdges,
				updater(old));
			var diff = A2($elm_community$graph$Graph$computeEdgeDiff, old, _new);
			return A3(
				$elm_community$intdict$IntDict$update,
				nodeId,
				$elm$core$Basics$always(_new),
				A3($elm_community$graph$Graph$applyEdgeDiff, nodeId, diff, rep));
		};
		return A2(
			$elm$core$Basics$composeR,
			$elm_community$graph$Graph$unGraph,
			A2($elm$core$Basics$composeR, wrappedUpdater, $elm$core$Basics$identity));
	});
var $elm_community$graph$Graph$remove = F2(
	function (nodeId, graph) {
		return A3(
			$elm_community$graph$Graph$update,
			nodeId,
			$elm$core$Basics$always($elm$core$Maybe$Nothing),
			graph);
	});
var $elm_community$graph$Graph$guidedDfs = F5(
	function (selectNeighbors, visitNode, startingSeeds, startingAcc, startingGraph) {
		var go = F3(
			function (seeds, acc, graph) {
				go:
				while (true) {
					if (!seeds.b) {
						return _Utils_Tuple2(acc, graph);
					} else {
						var next = seeds.a;
						var seeds1 = seeds.b;
						var _v1 = A2($elm_community$graph$Graph$get, next, graph);
						if (_v1.$ === 1) {
							var $temp$seeds = seeds1,
								$temp$acc = acc,
								$temp$graph = graph;
							seeds = $temp$seeds;
							acc = $temp$acc;
							graph = $temp$graph;
							continue go;
						} else {
							var ctx = _v1.a;
							var _v2 = A2(visitNode, ctx, acc);
							var accAfterDiscovery = _v2.a;
							var finishNode = _v2.b;
							var _v3 = A3(
								go,
								selectNeighbors(ctx),
								accAfterDiscovery,
								A2($elm_community$graph$Graph$remove, next, graph));
							var accBeforeFinish = _v3.a;
							var graph1 = _v3.b;
							var accAfterFinish = finishNode(accBeforeFinish);
							var $temp$seeds = seeds1,
								$temp$acc = accAfterFinish,
								$temp$graph = graph1;
							seeds = $temp$seeds;
							acc = $temp$acc;
							graph = $temp$graph;
							continue go;
						}
					}
				}
			});
		return A3(go, startingSeeds, startingAcc, startingGraph);
	});
var $elm_community$graph$Graph$nodeIds = A2($elm$core$Basics$composeR, $elm_community$graph$Graph$unGraph, $elm_community$intdict$IntDict$keys);
var $elm_community$graph$Graph$dfs = F3(
	function (visitNode, acc, graph) {
		return A5(
			$elm_community$graph$Graph$guidedDfs,
			$elm_community$graph$Graph$alongOutgoingEdges,
			visitNode,
			$elm_community$graph$Graph$nodeIds(graph),
			acc,
			graph).a;
	});
var $elm_community$graph$Graph$onFinish = F3(
	function (visitor, ctx, acc) {
		return _Utils_Tuple2(
			acc,
			visitor(ctx));
	});
var $elm_community$graph$Graph$checkAcyclic = function (graph) {
	var reversePostOrder = A3(
		$elm_community$graph$Graph$dfs,
		$elm_community$graph$Graph$onFinish(
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.iX;
				},
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.iw;
					},
					$elm$core$List$cons))),
		_List_Nil,
		graph);
	return A2($elm_community$graph$Graph$checkForBackEdges, reversePostOrder, graph);
};
var $elm_community$graph$Graph$Node = F2(
	function (id, label) {
		return {iw: id, iK: label};
	});
var $elm_community$graph$Graph$NodeContext = F3(
	function (node, incoming, outgoing) {
		return {q: incoming, iX: node, n: outgoing};
	});
var $elm_community$graph$Graph$fromNodesAndEdges = F2(
	function (nodes_, edges_) {
		var nodeRep = A3(
			$elm$core$List$foldl,
			function (n) {
				return A2(
					$elm_community$intdict$IntDict$insert,
					n.iw,
					A3($elm_community$graph$Graph$NodeContext, n, $elm_community$intdict$IntDict$empty, $elm_community$intdict$IntDict$empty));
			},
			$elm_community$intdict$IntDict$empty,
			nodes_);
		var addEdge = F2(
			function (edge, rep) {
				var updateOutgoing = function (ctx) {
					return _Utils_update(
						ctx,
						{
							n: A3($elm_community$intdict$IntDict$insert, edge.cf, edge.iK, ctx.n)
						});
				};
				var updateIncoming = function (ctx) {
					return _Utils_update(
						ctx,
						{
							q: A3($elm_community$intdict$IntDict$insert, edge.bY, edge.iK, ctx.q)
						});
				};
				return A3(
					$elm_community$intdict$IntDict$update,
					edge.cf,
					$elm$core$Maybe$map(updateIncoming),
					A3(
						$elm_community$intdict$IntDict$update,
						edge.bY,
						$elm$core$Maybe$map(updateOutgoing),
						rep));
			});
		var addEdgeIfValid = F2(
			function (edge, rep) {
				return (A2($elm_community$intdict$IntDict$member, edge.bY, rep) && A2($elm_community$intdict$IntDict$member, edge.cf, rep)) ? A2(addEdge, edge, rep) : rep;
			});
		return A3($elm$core$List$foldl, addEdgeIfValid, nodeRep, edges_);
	});
var $author$project$Utils$toGraph = function (intentions) {
	var nodes = A2(
		$elm$core$List$map,
		function (i) {
			return A2($elm_community$graph$Graph$Node, i.iw, i);
		},
		intentions);
	var edges = A2(
		$elm$core$List$concatMap,
		function (i) {
			return A2(
				$elm$core$List$map,
				function (p) {
					return A3($elm_community$graph$Graph$Edge, p.iw, i.iw, 0);
				},
				i.ji);
		},
		intentions);
	return A2($elm_community$graph$Graph$fromNodesAndEdges, nodes, edges);
};
var $elm_community$graph$Graph$topologicalSort = function (_v0) {
	var graph = _v0.a;
	var ordering = _v0.b;
	var error = 'Graph.topologicalSort: Invalid `AcyclicGraph`, where the ordering contained nodes not present in the graph';
	return A2(
		$elm$core$List$map,
		function (id) {
			return A3($elm_community$graph$Graph$unsafeGet, error, id, graph);
		},
		ordering);
};
var $author$project$Utils$topologicalSort = function (intentions) {
	var graph = $author$project$Utils$toGraph(intentions);
	var _v0 = $elm_community$graph$Graph$checkAcyclic(graph);
	if (!_v0.$) {
		var acyclic = _v0.a;
		return A2(
			$elm$core$List$map,
			function (n) {
				return n.iX.iK;
			},
			$elm_community$graph$Graph$topologicalSort(acyclic));
	} else {
		return intentions;
	}
};
var $author$project$Update$updateEditingIntention = F3(
	function (evt, editingIntention, model) {
		switch (evt.$) {
			case 2:
				var p = evt.a;
				return _Utils_update(
					editingIntention,
					{
						jf: false,
						jg: '',
						jh: A2($elm$core$Set$member, p, editingIntention.jh) ? A2($elm$core$Set$remove, p, editingIntention.jh) : A2($elm$core$Set$insert, p, editingIntention.jh)
					});
			case 0:
				var t = evt.a;
				return _Utils_update(
					editingIntention,
					{jU: t});
			case 1:
				var d = evt.a;
				var desc = $elm$core$String$isEmpty(d) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(d);
				return _Utils_update(
					editingIntention,
					{ib: desc});
			case 3:
				var s = evt.a;
				return _Utils_update(
					editingIntention,
					{jJ: s});
			case 7:
				var subMsg = evt.a;
				var _v1 = A3($CurrySoftware$elm_datepicker$DatePicker$update, $CurrySoftware$elm_datepicker$DatePicker$defaultSettings, subMsg, model.h5);
				var dateEvent = _v1.b;
				var date = function () {
					if (dateEvent.$ === 2) {
						var newDate = dateEvent.a;
						return $elm$core$Maybe$Just(newDate);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return _Utils_update(
					editingIntention,
					{$8: date});
			case 4:
				var s = evt.a;
				return _Utils_update(
					editingIntention,
					{
						jf: !$elm$core$String$isEmpty(s),
						jg: s
					});
			case 5:
				var v = evt.a;
				return _Utils_update(
					editingIntention,
					{jf: v});
			default:
				var keyboardEvent = evt.a;
				var dropdownItems = A2(
					$elm$core$List$filter,
					function (i) {
						return A2(
							$elm$core$String$contains,
							$elm$core$String$toUpper(editingIntention.jg),
							$elm$core$String$toUpper(i.jU));
					},
					A2(
						$elm$core$List$filter,
						function (i) {
							var _v4 = editingIntention.iw;
							if (!_v4.$) {
								var id = _v4.a;
								return !_Utils_eq(i.iw, id);
							} else {
								return true;
							}
						},
						$author$project$Utils$topologicalSort(
							$elm$core$Dict$values(model.iD))));
				var constrainIndex = A2(
					$elm$core$Basics$clamp,
					0,
					$elm$core$List$length(dropdownItems) - 1);
				var _v3 = keyboardEvent.iJ;
				switch (_v3.$) {
					case 29:
						return _Utils_update(
							editingIntention,
							{
								je: $elm$core$Maybe$Just(
									A2(
										$elm$core$Maybe$withDefault,
										0,
										A2(
											$elm$core$Maybe$map,
											A2(
												$elm$core$Basics$composeL,
												constrainIndex,
												function (i) {
													return i + 1;
												}),
											editingIntention.je))),
								jf: true
							});
					case 28:
						return _Utils_update(
							editingIntention,
							{
								je: $elm$core$Maybe$Just(
									A2(
										$elm$core$Maybe$withDefault,
										0,
										A2(
											$elm$core$Maybe$map,
											A2(
												$elm$core$Basics$composeL,
												constrainIndex,
												function (i) {
													return i - 1;
												}),
											editingIntention.je))),
								jf: true
							});
					case 37:
						var highlightedIntention = A2(
							$elm$core$Maybe$andThen,
							function (idx) {
								return A2($elm_community$list_extra$List$Extra$getAt, idx, dropdownItems);
							},
							editingIntention.je);
						return _Utils_update(
							editingIntention,
							{
								jh: A2(
									$elm$core$Maybe$withDefault,
									editingIntention.jh,
									A2(
										$elm$core$Maybe$map,
										function (i) {
											return A2($elm$core$Set$member, i.iw, editingIntention.jh) ? A2($elm$core$Set$remove, i.iw, editingIntention.jh) : A2($elm$core$Set$insert, i.iw, editingIntention.jh);
										},
										highlightedIntention))
							});
					case 36:
						return _Utils_update(
							editingIntention,
							{jf: false});
					default:
						return editingIntention;
				}
		}
	});
var $author$project$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				var porterMsg = msg.a;
				var _v1 = A3($peterszerzo$elm_porter$Porter$update, $author$project$Ports$porterConfig, porterMsg, model.jl);
				var porterModel = _v1.a;
				var porterCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jl: porterModel}),
					porterCmd);
			case 0:
				var result = msg.a;
				if (!result.$) {
					var intentions = result.a;
					var newModel = $author$project$Update$updateModelOnPageChange(
						_Utils_update(
							model,
							{
								iD: A3(
									$elm$core$List$foldl,
									F2(
										function (i, acc) {
											return A3($elm$core$Dict$insert, i.iw, i, acc);
										}),
									$elm$core$Dict$empty,
									intentions),
								iL: false
							}));
					return _Utils_Tuple2(
						newModel,
						$author$project$Update$updateDAGLayout(newModel));
				} else {
					var err = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								il: $elm$core$Maybe$Just(
									$author$project$Utils$httpErrorToString(err)),
								iL: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				var result = msg.a;
				if (!result.$) {
					var views = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{j5: views}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var res = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							h3: $elm$core$Maybe$Just(res)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var tab = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{id: tab, jo: $elm$core$Maybe$Nothing, jA: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.iI,
							$elm$url$Url$toString(url)));
				} else {
					if (urlRequest.a === '') {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				}
			case 6:
				var url = msg.a;
				var newModel = $author$project$Update$updateModelOnPageChange(
					_Utils_update(
						model,
						{
							iL: true,
							jd: $author$project$Routing$getPage(url)
						}));
				var command = $author$project$Update$cmdOnPageChange(newModel);
				return _Utils_Tuple2(
					_Utils_update(
						newModel,
						{
							iL: $author$project$Utils$isSome(command)
						}),
					A2($elm$core$Maybe$withDefault, $elm$core$Platform$Cmd$none, command));
			case 8:
				var evt = msg.a;
				var newModel = function () {
					if (evt.$ === 7) {
						var subMsg = evt.a;
						var _v6 = A3($CurrySoftware$elm_datepicker$DatePicker$update, $CurrySoftware$elm_datepicker$DatePicker$defaultSettings, subMsg, model.h5);
						var datePicker = _v6.a;
						return _Utils_update(
							model,
							{h5: datePicker});
					} else {
						return model;
					}
				}();
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (editingIntention) {
							return _Utils_Tuple2(
								_Utils_update(
									newModel,
									{
										ih: $elm$core$Maybe$Just(
											A3($author$project$Update$updateEditingIntention, evt, editingIntention, model))
									}),
								$elm$core$Platform$Cmd$none);
						},
						model.ih));
			case 9:
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$andThen,
						function (editingIntention) {
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(
									_Utils_update(
										model,
										{iL: true}),
									A3(
										$author$project$API$saveIntention,
										model.hL,
										model.hN,
										$author$project$Utils$intentionFormToDTO(editingIntention))));
						},
						model.ih));
			case 10:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							iC: $elm$core$Maybe$Just(
								_Utils_Tuple2(id, false))
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							iC: A2(
								$elm$core$Maybe$map,
								function (t) {
									return _Utils_Tuple2(t.a, !t.b);
								},
								model.iC)
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var _v7 = model.iC;
				if (!_v7.$) {
					var _v8 = _v7.a;
					var id = _v8.a;
					var deleteDescendants = _v8.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{iC: $elm$core$Maybe$Nothing}),
						A4($author$project$API$deleteIntention, model.hL, model.hN, id, deleteDescendants));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 15:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iC: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var editingIntention = msg.a;
				var saveResponse = msg.b;
				if (!saveResponse.$) {
					var response = saveResponse.a;
					var newModel = _Utils_update(
						model,
						{
							ih: A2(
								$elm$core$Maybe$map,
								function (ei) {
									return _Utils_update(
										ei,
										{
											iw: $author$project$Types$ExistingId(response.iw)
										});
								},
								model.ih),
							iD: A3(
								$elm$core$Dict$insert,
								response.iw,
								{
									ib: editingIntention.ib,
									$8: editingIntention.$8,
									iw: response.iw,
									ji: A2(
										$elm$core$List$map,
										function (id) {
											return {iw: id};
										},
										$elm$core$Set$toList(editingIntention.jh)),
									jJ: editingIntention.jJ,
									jU: editingIntention.jU
								},
								model.iD),
							iL: false
						});
					return A4(
						$pablen$toasty$Toasty$addToast,
						$author$project$Config$toastConfig,
						$author$project$Types$ShowToast,
						A2($pablen$toasty$Toasty$Defaults$Success, 'Saved!', 'Intention updated!'),
						_Utils_Tuple2(
							newModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Update$updateDAGLayout(newModel),
										function () {
										var _v10 = newModel.jd;
										if ((_v10.$ === 1) && (_v10.b.$ === 1)) {
											var _v11 = _v10.b;
											return A2(
												$elm$browser$Browser$Navigation$pushUrl,
												model.iI,
												'/intention/' + $elm$core$String$fromInt(response.iw));
										} else {
											return $elm$core$Platform$Cmd$none;
										}
									}()
									]))));
				} else {
					var err = saveResponse.a;
					return A4(
						$pablen$toasty$Toasty$addToast,
						$author$project$Config$toastConfig,
						$author$project$Types$ShowToast,
						A2(
							$pablen$toasty$Toasty$Defaults$Error,
							'Save Failed!',
							$author$project$Utils$httpErrorToString(err)),
						_Utils_Tuple2(
							_Utils_update(
								model,
								{iL: false}),
							$elm$core$Platform$Cmd$none));
				}
			case 14:
				var response = msg.a;
				if (!response.$) {
					return A4(
						$pablen$toasty$Toasty$addToast,
						$author$project$Config$toastConfig,
						$author$project$Types$ShowToast,
						A2($pablen$toasty$Toasty$Defaults$Success, 'Deleted', 'Intention deleted successfully'),
						_Utils_Tuple2(
							model,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($author$project$API$getAllIntentions, model.hL, model.hN),
										function () {
										var _v13 = model.jd;
										if (_v13.$ === 1) {
											return A2($elm$browser$Browser$Navigation$pushUrl, model.iI, '/');
										} else {
											return $elm$core$Platform$Cmd$none;
										}
									}()
									]))));
				} else {
					return A4(
						$pablen$toasty$Toasty$addToast,
						$author$project$Config$toastConfig,
						$author$project$Types$ShowToast,
						A2($pablen$toasty$Toasty$Defaults$Error, 'Delete failed', 'Perhaps you tried to delete the root node?'),
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
				}
			case 16:
				var subMsg = msg.a;
				return A4($pablen$toasty$Toasty$update, $author$project$Config$toastConfig, $author$project$Types$ShowToast, subMsg, model);
			case 17:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jA: $elm$core$Maybe$Just(id)
						}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var childOf = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$browser$Browser$Navigation$pushUrl,
						model.iI,
						'/intention/new?parent=' + $elm$core$String$fromInt(childOf)));
			case 19:
				var siblingOf = msg.a;
				var parents = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						function (i) {
							return i.ji;
						},
						A2($elm$core$Dict$get, siblingOf, model.iD)));
				return _Utils_Tuple2(
					model,
					A2(
						$elm$browser$Browser$Navigation$pushUrl,
						model.iI,
						'/intention/new?' + A2(
							$elm$core$String$join,
							'&',
							A2(
								$elm$core$List$map,
								function (p) {
									return 'parent=' + $elm$core$String$fromInt(p.iw);
								},
								parents))));
			case 20:
				var id = msg.a;
				var newStatus = function (i) {
					var _v14 = i.jJ;
					if (_v14 === 1) {
						return 0;
					} else {
						return 1;
					}
				};
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (i) {
							var newModel = _Utils_update(
								model,
								{
									iD: A3(
										$elm$core$Dict$update,
										id,
										$elm$core$Maybe$map(
											function (x) {
												return _Utils_update(
													x,
													{jJ: i.jJ});
											}),
										model.iD),
									iL: true
								});
							return _Utils_Tuple2(
								newModel,
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A3($author$project$API$saveIntention, model.hL, model.hN, i),
											$author$project$Update$updateDAGLayout(newModel)
										])));
						},
						A2(
							$elm$core$Maybe$map,
							function (i) {
								return {
									ib: i.ib,
									$8: i.$8,
									iw: $author$project$Types$ExistingId(id),
									jh: $elm$core$Set$fromList(
										A2(
											$elm$core$List$map,
											function ($) {
												return $.iw;
											},
											i.ji)),
									jJ: newStatus(i),
									jU: i.jU
								};
							},
							A2($elm$core$Dict$get, id, model.iD))));
			case 21:
				var value = msg.a;
				var newModel = _Utils_update(
					model,
					{jE: value});
				return _Utils_Tuple2(
					newModel,
					$author$project$Update$updateDAGLayout(newModel));
			case 22:
				var value = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jG: value}),
					$elm$core$Platform$Cmd$none);
			case 23:
				var value = msg.a;
				var newModel = _Utils_update(
					model,
					{
						jy: $elm$core$String$isEmpty(value) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(value)
					});
				return _Utils_Tuple2(
					newModel,
					$author$project$Update$updateDAGLayout(newModel));
			case 24:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jF: !model.jF}),
					$elm$core$Platform$Cmd$none);
			case 25:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							jo: $elm$core$Maybe$Just(
								_Utils_Tuple2(
									id,
									A2(
										$elm$core$Maybe$withDefault,
										'',
										A2($elm$core$Maybe$map, $elm$core$Tuple$second, model.jo))))
						}),
					A2(
						$elm$core$Task$attempt,
						$author$project$Utils$constantly($author$project$Types$NoOp),
						$elm$browser$Browser$Dom$focus('quick-add-input')));
			case 26:
				var newValue = msg.a;
				var _v15 = model.jo;
				if (!_v15.$) {
					var _v16 = _v15.a;
					var id = _v16.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								jo: $elm$core$Maybe$Just(
									_Utils_Tuple2(id, newValue))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 27:
				var _v17 = model.jo;
				if (!_v17.$) {
					var _v18 = _v17.a;
					var id = _v18.a;
					var title = _v18.b;
					var newModel = _Utils_update(
						model,
						{jo: $elm$core$Maybe$Nothing});
					var newIntention = {
						ib: $elm$core$Maybe$Nothing,
						$8: $elm$core$Maybe$Nothing,
						iw: $author$project$Types$NewId,
						jh: $elm$core$Set$singleton(id),
						jJ: 0,
						jU: title
					};
					return _Utils_Tuple2(
						newModel,
						A3($author$project$API$saveIntention, model.hL, model.hN, newIntention));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 28:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jo: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 29:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hN: ''}),
					$author$project$Ports$logout(0));
			case 30:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jH: !model.jH}),
					$elm$core$Platform$Cmd$none);
			case 31:
				var id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							j3: $elm$core$Maybe$Just(
								_Utils_Tuple2(
									id,
									A2(
										$elm$core$Maybe$withDefault,
										'',
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.jU;
											},
											A2($elm$core$Dict$get, id, model.iD)))))
						}),
					$elm$core$Platform$Cmd$none);
			case 32:
				var title = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							j3: A2(
								$elm$core$Maybe$map,
								function (v) {
									return _Utils_Tuple2(v.a, title);
								},
								model.j3)
						}),
					$elm$core$Platform$Cmd$none);
			case 33:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j3: $elm$core$Maybe$Nothing}),
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							A2($author$project$API$saveView, model.hL, model.hN),
							model.j3)));
			case 34:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j3: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 35:
				var viewForm = msg.a;
				var response = msg.b;
				if (!response.$) {
					var res = response.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								j5: A2(
									$elm$core$List$append,
									model.j5,
									_List_fromArray(
										[
											{
											iw: res.iw,
											ju: {iw: viewForm.a},
											jU: viewForm.b
										}
										]))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 36:
				var view = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							j4: $elm$core$Maybe$Just(view)
						}),
					$elm$core$Platform$Cmd$none);
			case 37:
				var v = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							j4: $elm$core$Maybe$Nothing,
							j5: A2(
								$elm$core$List$filter,
								function (v2) {
									return !_Utils_eq(v2.iw, v.iw);
								},
								model.j5)
						}),
					A3($author$project$API$deleteView, model.hL, model.hN, v.iw));
			case 38:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j4: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 39:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jz: true}),
					$elm$core$Platform$Cmd$none);
			case 40:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jz: false}),
					$elm$core$Platform$Cmd$none);
			case 41:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jA: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var sortType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jI: sortType}),
					$elm$core$Platform$Cmd$none);
			case 42:
				var subMsg = msg.a;
				var _v20 = A3($CurrySoftware$elm_datepicker$DatePicker$update, $CurrySoftware$elm_datepicker$DatePicker$defaultSettings, subMsg, model.h5);
				var newDatePicker = _v20.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h5: newDatePicker}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Types$AddView = {$: 33};
var $author$project$Types$AddViewCancelled = {$: 34};
var $author$project$Types$AddViewTitleUpdated = function (a) {
	return {$: 32, a: a};
};
var $surprisetalk$elm_bulma$Bulma$Modifiers$Danger = 10;
var $author$project$Types$DeleteCancelled = {$: 15};
var $author$project$Types$DeleteIntention = {$: 12};
var $author$project$Types$DeleteView = function (a) {
	return {$: 37, a: a};
};
var $author$project$Types$DeleteViewCancelled = {$: 38};
var $surprisetalk$elm_bulma$Bulma$Elements$H4 = 3;
var $surprisetalk$elm_bulma$Bulma$Layout$NotSpaced = 0;
var $surprisetalk$elm_bulma$Bulma$Modifiers$Primary = 5;
var $author$project$Types$ToggleDeleteDescendants = {$: 13};
var $elm$html$Html$b = _VirtualDom_node('b');
var $surprisetalk$elm_bulma$Bulma$Classes$box = $elm$html$Html$Attributes$class('box');
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $surprisetalk$elm_bulma$Helpers$node = F3(
	function (tag, attrs_, attrs) {
		return A2(
			$elm$html$Html$node,
			tag,
			_Utils_ap(attrs, attrs_));
	});
var $surprisetalk$elm_bulma$Bulma$Elements$box = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'div',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$box]));
var $surprisetalk$elm_bulma$Bulma$Classes$button = $elm$html$Html$Attributes$class('button');
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $surprisetalk$elm_bulma$Bulma$Classes$icon = $elm$html$Html$Attributes$class('icon');
var $surprisetalk$elm_bulma$Bulma$Classes$isLarge = $elm$html$Html$Attributes$class('is-large');
var $surprisetalk$elm_bulma$Bulma$Classes$isMedium = $elm$html$Html$Attributes$class('is-medium');
var $surprisetalk$elm_bulma$Bulma$Classes$isSmall = $elm$html$Html$Attributes$class('is-small');
var $surprisetalk$elm_bulma$Bulma$Classes$none = $elm$html$Html$Attributes$class('');
var $surprisetalk$elm_bulma$Bulma$Elements$icon = function (size) {
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		'span',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$icon,
				function () {
				switch (size) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$isSmall;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
				}
			}()
			]));
};
var $surprisetalk$elm_bulma$Bulma$Classes$isActive = $elm$html$Html$Attributes$class('is-active');
var $surprisetalk$elm_bulma$Bulma$Classes$isBlack = $elm$html$Html$Attributes$class('is-black');
var $surprisetalk$elm_bulma$Bulma$Classes$isDanger = $elm$html$Html$Attributes$class('is-danger');
var $surprisetalk$elm_bulma$Bulma$Classes$isDark = $elm$html$Html$Attributes$class('is-dark');
var $surprisetalk$elm_bulma$Bulma$Classes$isFocused = $elm$html$Html$Attributes$class('is-focused');
var $surprisetalk$elm_bulma$Bulma$Classes$isHovered = $elm$html$Html$Attributes$class('is-hovered');
var $surprisetalk$elm_bulma$Bulma$Classes$isInfo = $elm$html$Html$Attributes$class('is-info');
var $surprisetalk$elm_bulma$Bulma$Classes$isInverted = $elm$html$Html$Attributes$class('is-inverted');
var $surprisetalk$elm_bulma$Bulma$Classes$isLight = $elm$html$Html$Attributes$class('is-light');
var $surprisetalk$elm_bulma$Bulma$Classes$isLink = $elm$html$Html$Attributes$class('is-link');
var $surprisetalk$elm_bulma$Bulma$Classes$isLoading = $elm$html$Html$Attributes$class('is-loading');
var $surprisetalk$elm_bulma$Bulma$Classes$isOutlined = $elm$html$Html$Attributes$class('is-outlined');
var $surprisetalk$elm_bulma$Bulma$Classes$isPrimary = $elm$html$Html$Attributes$class('is-primary');
var $surprisetalk$elm_bulma$Bulma$Classes$isRounded = $elm$html$Html$Attributes$class('is-rounded');
var $surprisetalk$elm_bulma$Bulma$Classes$isSelected = $elm$html$Html$Attributes$class('is-selected');
var $surprisetalk$elm_bulma$Bulma$Classes$isStatic = $elm$html$Html$Attributes$class('is-static');
var $surprisetalk$elm_bulma$Bulma$Classes$isSuccess = $elm$html$Html$Attributes$class('is-success');
var $surprisetalk$elm_bulma$Bulma$Classes$isWarning = $elm$html$Html$Attributes$class('is-warning');
var $surprisetalk$elm_bulma$Bulma$Classes$isWhite = $elm$html$Html$Attributes$class('is-white');
var $elm$html$Html$span = _VirtualDom_node('span');
var $surprisetalk$elm_bulma$Bulma$Elements$button = F3(
	function (_v0, attrs, htmls) {
		var disabled = _v0.dY;
		var outlined = _v0.jc;
		var inverted = _v0.iG;
		var rounded = _v0.g9;
		var size = _v0.bE;
		var state = _v0.D;
		var color = _v0.H;
		var _static = _v0.fo;
		var iconLeft = _v0.go;
		var iconRight = _v0.ec;
		var iconRight_ = function () {
			if (!iconRight.$) {
				var _v13 = iconRight.a;
				var size_ = _v13.a;
				var attrs_ = _v13.b;
				var body = _v13.c;
				return _List_fromArray(
					[
						A3(
						$surprisetalk$elm_bulma$Bulma$Elements$icon,
						size_,
						attrs_,
						_List_fromArray(
							[body]))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var iconLeft_ = function () {
			if (!iconLeft.$) {
				var _v11 = iconLeft.a;
				var size_ = _v11.a;
				var attrs_ = _v11.b;
				var body = _v11.c;
				return _List_fromArray(
					[
						A3(
						$surprisetalk$elm_bulma$Bulma$Elements$icon,
						size_,
						attrs_,
						_List_fromArray(
							[body]))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var htmls_ = function () {
			if (!htmls.b) {
				return _Utils_ap(iconLeft_, iconRight_);
			} else {
				var htmls__ = htmls;
				return _Utils_ap(
					iconLeft_,
					_Utils_ap(
						_List_fromArray(
							[
								A2($elm$html$Html$span, _List_Nil, htmls__)
							]),
						iconRight_));
			}
		}();
		return A4(
			$surprisetalk$elm_bulma$Helpers$node,
			'a',
			_List_fromArray(
				[
					$elm$html$Html$Attributes$disabled(disabled),
					$surprisetalk$elm_bulma$Bulma$Classes$button,
					function () {
					if (_static) {
						return $surprisetalk$elm_bulma$Bulma$Classes$isStatic;
					} else {
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					}
				}(),
					function () {
					if (outlined) {
						return $surprisetalk$elm_bulma$Bulma$Classes$isOutlined;
					} else {
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					}
				}(),
					function () {
					if (inverted) {
						return $surprisetalk$elm_bulma$Bulma$Classes$isInverted;
					} else {
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					}
				}(),
					function () {
					if (rounded) {
						return $surprisetalk$elm_bulma$Bulma$Classes$isRounded;
					} else {
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					}
				}(),
					function () {
					if (!color) {
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					} else {
						return $surprisetalk$elm_bulma$Bulma$Classes$isSelected;
					}
				}(),
					function () {
					switch (color) {
						case 0:
							return $surprisetalk$elm_bulma$Bulma$Classes$none;
						case 1:
							return $surprisetalk$elm_bulma$Bulma$Classes$isWhite;
						case 2:
							return $surprisetalk$elm_bulma$Bulma$Classes$isLight;
						case 3:
							return $surprisetalk$elm_bulma$Bulma$Classes$isDark;
						case 4:
							return $surprisetalk$elm_bulma$Bulma$Classes$isBlack;
						case 5:
							return $surprisetalk$elm_bulma$Bulma$Classes$isPrimary;
						case 6:
							return $surprisetalk$elm_bulma$Bulma$Classes$isLink;
						case 7:
							return $surprisetalk$elm_bulma$Bulma$Classes$isInfo;
						case 8:
							return $surprisetalk$elm_bulma$Bulma$Classes$isSuccess;
						case 9:
							return $surprisetalk$elm_bulma$Bulma$Classes$isWarning;
						default:
							return $surprisetalk$elm_bulma$Bulma$Classes$isDanger;
					}
				}(),
					function () {
					switch (size) {
						case 0:
							return $surprisetalk$elm_bulma$Bulma$Classes$isSmall;
						case 1:
							return $surprisetalk$elm_bulma$Bulma$Classes$none;
						case 2:
							return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
						default:
							return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
					}
				}(),
					function () {
					switch (state) {
						case 0:
							return $surprisetalk$elm_bulma$Bulma$Classes$none;
						case 1:
							return $surprisetalk$elm_bulma$Bulma$Classes$isHovered;
						case 2:
							return $surprisetalk$elm_bulma$Bulma$Classes$isFocused;
						case 3:
							return $surprisetalk$elm_bulma$Bulma$Classes$isActive;
						default:
							return $surprisetalk$elm_bulma$Bulma$Classes$isLoading;
					}
				}()
				]),
			attrs,
			htmls_);
	});
var $surprisetalk$elm_bulma$Bulma$Modifiers$Blur = 0;
var $surprisetalk$elm_bulma$Bulma$Modifiers$Default = 0;
var $surprisetalk$elm_bulma$Bulma$Modifiers$Standard = 1;
var $surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers = {H: 0, dY: false, go: $elm$core$Maybe$Nothing, ec: $elm$core$Maybe$Nothing, iG: false, jc: false, g9: false, bE: 1, D: 0, fo: false};
var $surprisetalk$elm_bulma$Bulma$Classes$container = $elm$html$Html$Attributes$class('container');
var $surprisetalk$elm_bulma$Bulma$Layout$container = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'div',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$container]));
var $surprisetalk$elm_bulma$Bulma$Classes$control = $elm$html$Html$Attributes$class('control');
var $surprisetalk$elm_bulma$Bulma$Classes$hasIconsLeft = $elm$html$Html$Attributes$class('has-icons-left');
var $surprisetalk$elm_bulma$Bulma$Classes$hasIconsRight = $elm$html$Html$Attributes$class('has-icons-right');
var $surprisetalk$elm_bulma$Bulma$Classes$isExpanded = $elm$html$Html$Attributes$class('is-expanded');
var $surprisetalk$elm_bulma$Bulma$Form$control = F3(
	function (_v0, attrs, htmls) {
		var loading = _v0.iL;
		var expanded = _v0.ak;
		var iconLeft = _v0.go;
		var iconRight = _v0.ec;
		return A4(
			$surprisetalk$elm_bulma$Helpers$node,
			'p',
			_List_fromArray(
				[
					$surprisetalk$elm_bulma$Bulma$Classes$control,
					function () {
					if (!loading.$) {
						return $surprisetalk$elm_bulma$Bulma$Classes$isLoading;
					} else {
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					}
				}(),
					function () {
					_v2$3:
					while (true) {
						if (!loading.$) {
							switch (loading.a) {
								case 0:
									var _v3 = loading.a;
									return $surprisetalk$elm_bulma$Bulma$Classes$isSmall;
								case 2:
									var _v4 = loading.a;
									return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
								case 3:
									var _v5 = loading.a;
									return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
								default:
									break _v2$3;
							}
						} else {
							break _v2$3;
						}
					}
					return $surprisetalk$elm_bulma$Bulma$Classes$none;
				}(),
					function () {
					if (expanded) {
						return $surprisetalk$elm_bulma$Bulma$Classes$isExpanded;
					} else {
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					}
				}(),
					function () {
					if (!iconLeft.$) {
						return $surprisetalk$elm_bulma$Bulma$Classes$hasIconsLeft;
					} else {
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					}
				}(),
					function () {
					if (!iconRight.$) {
						return $surprisetalk$elm_bulma$Bulma$Classes$hasIconsRight;
					} else {
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					}
				}()
				]),
			attrs,
			_Utils_ap(
				htmls,
				_Utils_ap(
					A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (_v9) {
								var size_ = _v9.a;
								var attrs_ = _v9.b;
								var iconBody = _v9.c;
								return _List_fromArray(
									[
										A3(
										$surprisetalk$elm_bulma$Bulma$Elements$icon,
										size_,
										A2(
											$elm$core$List$cons,
											$elm$html$Html$Attributes$class('is-left'),
											attrs_),
										_List_fromArray(
											[iconBody]))
									]);
							},
							iconLeft)),
					_Utils_ap(
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								function (_v10) {
									var size_ = _v10.a;
									var attrs_ = _v10.b;
									var iconBody = _v10.c;
									return _List_fromArray(
										[
											A3(
											$surprisetalk$elm_bulma$Bulma$Elements$icon,
											size_,
											A2(
												$elm$core$List$cons,
												$elm$html$Html$Attributes$class('is-right'),
												attrs_),
											_List_fromArray(
												[iconBody]))
										]);
								},
								iconRight)),
						_List_Nil))));
	});
var $surprisetalk$elm_bulma$Bulma$Classes$input = $elm$html$Html$Attributes$class('input');
var $surprisetalk$elm_bulma$Helpers$ls = function (x) {
	return _List_fromArray(
		[x]);
};
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $surprisetalk$elm_bulma$Bulma$Form$controlInput = F3(
	function (mods, attrs, attrs_) {
		var size = mods.bE;
		var state = mods.D;
		var color = mods.H;
		var expanded = mods.ak;
		var rounded = mods.g9;
		var readonly = mods.b5;
		var disabled = mods.dY;
		var iconLeft = mods.go;
		var iconRight = mods.ec;
		var controlMods = {
			ak: expanded,
			go: iconLeft,
			ec: iconRight,
			iL: function () {
				if (state === 4) {
					return $elm$core$Maybe$Just(size);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}()
		};
		return A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				A2($surprisetalk$elm_bulma$Bulma$Form$control, controlMods, attrs),
				$surprisetalk$elm_bulma$Helpers$ls),
			A3(
				$surprisetalk$elm_bulma$Helpers$node,
				'input',
				_List_fromArray(
					[
						$surprisetalk$elm_bulma$Bulma$Classes$input,
						$elm$html$Html$Attributes$disabled(disabled),
						function () {
						if (readonly) {
							return $elm$html$Html$Attributes$readonly(readonly);
						} else {
							return $surprisetalk$elm_bulma$Bulma$Classes$none;
						}
					}(),
						function () {
						switch (size) {
							case 0:
								return $surprisetalk$elm_bulma$Bulma$Classes$isSmall;
							case 1:
								return $surprisetalk$elm_bulma$Bulma$Classes$none;
							case 2:
								return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
							default:
								return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
						}
					}(),
						function () {
						switch (state) {
							case 1:
								return $surprisetalk$elm_bulma$Bulma$Classes$isHovered;
							case 2:
								return $surprisetalk$elm_bulma$Bulma$Classes$isFocused;
							case 3:
								return $surprisetalk$elm_bulma$Bulma$Classes$isActive;
							case 4:
								return $surprisetalk$elm_bulma$Bulma$Classes$isLoading;
							default:
								return $surprisetalk$elm_bulma$Bulma$Classes$none;
						}
					}(),
						function () {
						switch (color) {
							case 0:
								return $surprisetalk$elm_bulma$Bulma$Classes$none;
							case 1:
								return $surprisetalk$elm_bulma$Bulma$Classes$isWhite;
							case 2:
								return $surprisetalk$elm_bulma$Bulma$Classes$isLight;
							case 3:
								return $surprisetalk$elm_bulma$Bulma$Classes$isDark;
							case 4:
								return $surprisetalk$elm_bulma$Bulma$Classes$isBlack;
							case 5:
								return $surprisetalk$elm_bulma$Bulma$Classes$isPrimary;
							case 7:
								return $surprisetalk$elm_bulma$Bulma$Classes$isInfo;
							case 8:
								return $surprisetalk$elm_bulma$Bulma$Classes$isSuccess;
							case 9:
								return $surprisetalk$elm_bulma$Bulma$Classes$isWarning;
							case 10:
								return $surprisetalk$elm_bulma$Bulma$Classes$isDanger;
							default:
								return $surprisetalk$elm_bulma$Bulma$Classes$isLink;
						}
					}(),
						function () {
						if (!rounded) {
							return $surprisetalk$elm_bulma$Bulma$Classes$none;
						} else {
							return $surprisetalk$elm_bulma$Bulma$Classes$isRounded;
						}
					}()
					]),
				attrs_));
	});
var $surprisetalk$elm_bulma$Bulma$Form$controlInputModifiers = {H: 0, dY: false, ak: false, go: $elm$core$Maybe$Nothing, ec: $elm$core$Maybe$Nothing, b5: false, g9: false, bE: 1, D: 0};
var $surprisetalk$elm_bulma$Bulma$Classes$label = $elm$html$Html$Attributes$class('label');
var $surprisetalk$elm_bulma$Bulma$Form$label = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'label',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$label]));
var $surprisetalk$elm_bulma$Bulma$Form$controlLabel = $surprisetalk$elm_bulma$Bulma$Form$label;
var $surprisetalk$elm_bulma$Bulma$Classes$modalBackground = $elm$html$Html$Attributes$class('modal-background');
var $surprisetalk$elm_bulma$Bulma$Components$modalBackground = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'div',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$modalBackground]));
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $surprisetalk$elm_bulma$Bulma$Components$easyModalBackground = F2(
	function (attrs, onClickBackground) {
		return A2(
			$surprisetalk$elm_bulma$Bulma$Components$modalBackground,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Events$onClick(onClickBackground),
				attrs),
			_List_Nil);
	});
var $surprisetalk$elm_bulma$Bulma$Classes$modalClose = $elm$html$Html$Attributes$class('modal-close');
var $surprisetalk$elm_bulma$Bulma$Components$modalClose = function (size) {
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		'a',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$modalClose,
				function () {
				switch (size) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$isSmall;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
				}
			}()
			]));
};
var $surprisetalk$elm_bulma$Bulma$Components$easyModalClose = F3(
	function (size, attrs, onClickModal) {
		return A3(
			$surprisetalk$elm_bulma$Bulma$Components$modalClose,
			size,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Events$onClick(onClickModal),
				attrs),
			_List_Nil);
	});
var $surprisetalk$elm_bulma$Bulma$Classes$modal = $elm$html$Html$Attributes$class('modal');
var $surprisetalk$elm_bulma$Bulma$Components$modal = function (active) {
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		'div',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$modal,
				function () {
				if (active) {
					return $surprisetalk$elm_bulma$Bulma$Classes$isActive;
				} else {
					return $surprisetalk$elm_bulma$Bulma$Classes$none;
				}
			}()
			]));
};
var $surprisetalk$elm_bulma$Bulma$Classes$modalContent = $elm$html$Html$Attributes$class('modal-content');
var $surprisetalk$elm_bulma$Bulma$Components$modalContent = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'div',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$modalContent]));
var $surprisetalk$elm_bulma$Bulma$Components$easyModal = F4(
	function (active, attrs, close, content) {
		return A3(
			$surprisetalk$elm_bulma$Bulma$Components$modal,
			active,
			attrs,
			_List_fromArray(
				[
					A2($surprisetalk$elm_bulma$Bulma$Components$easyModalBackground, _List_Nil, close),
					A2($surprisetalk$elm_bulma$Bulma$Components$modalContent, _List_Nil, content),
					A3($surprisetalk$elm_bulma$Bulma$Components$easyModalClose, 1, _List_Nil, close)
				]));
	});
var $author$project$Types$AddChildIntention = function (a) {
	return {$: 18, a: a};
};
var $author$project$Types$AddSiblingIntention = function (a) {
	return {$: 19, a: a};
};
var $author$project$Types$DateUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$Types$DescriptionChanged = function (a) {
	return {$: 1, a: a};
};
var $surprisetalk$elm_bulma$Bulma$Elements$H2 = 1;
var $author$project$Types$IntentionEdited = function (a) {
	return {$: 8, a: a};
};
var $surprisetalk$elm_bulma$Bulma$Modifiers$Large = 3;
var $surprisetalk$elm_bulma$Bulma$Modifiers$Medium = 2;
var $author$project$Types$ParentDropdownFocusChanged = function (a) {
	return {$: 5, a: a};
};
var $author$project$Types$ParentDropdownKeyDown = function (a) {
	return {$: 6, a: a};
};
var $author$project$Types$ParentDropdownUpdated = function (a) {
	return {$: 4, a: a};
};
var $author$project$Types$ParentSelected = function (a) {
	return {$: 2, a: a};
};
var $author$project$Types$RequestDeleteIntention = function (a) {
	return {$: 10, a: a};
};
var $author$project$Types$SaveIntention = {$: 9};
var $author$project$Types$StatusChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Types$TitleChanged = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $surprisetalk$elm_bulma$Bulma$Form$controlModifiers = {ak: false, go: $elm$core$Maybe$Nothing, ec: $elm$core$Maybe$Nothing, iL: $elm$core$Maybe$Nothing};
var $surprisetalk$elm_bulma$Bulma$Form$controlButton = F3(
	function (mods, attrs, attrs_) {
		return A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				A2($surprisetalk$elm_bulma$Bulma$Form$control, $surprisetalk$elm_bulma$Bulma$Form$controlModifiers, attrs),
				$surprisetalk$elm_bulma$Helpers$ls),
			A2($surprisetalk$elm_bulma$Bulma$Elements$button, mods, attrs_));
	});
var $surprisetalk$elm_bulma$Bulma$Classes$textarea = $elm$html$Html$Attributes$class('textarea');
var $surprisetalk$elm_bulma$Bulma$Form$controlTextArea = F3(
	function (mods, attrs, attrs_) {
		var size = mods.bE;
		var state = mods.D;
		var color = mods.H;
		var readonly = mods.b5;
		var disabled = mods.dY;
		var controlMods = {
			ak: false,
			go: $elm$core$Maybe$Nothing,
			ec: $elm$core$Maybe$Nothing,
			iL: function () {
				if (state === 4) {
					return $elm$core$Maybe$Just(size);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}()
		};
		return A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				A2($surprisetalk$elm_bulma$Bulma$Form$control, controlMods, attrs),
				$surprisetalk$elm_bulma$Helpers$ls),
			A3(
				$surprisetalk$elm_bulma$Helpers$node,
				'textarea',
				_List_fromArray(
					[
						$surprisetalk$elm_bulma$Bulma$Classes$textarea,
						$elm$html$Html$Attributes$disabled(disabled),
						function () {
						if (readonly) {
							return $elm$html$Html$Attributes$readonly(readonly);
						} else {
							return $surprisetalk$elm_bulma$Bulma$Classes$none;
						}
					}(),
						function () {
						switch (size) {
							case 0:
								return $surprisetalk$elm_bulma$Bulma$Classes$isSmall;
							case 1:
								return $surprisetalk$elm_bulma$Bulma$Classes$none;
							case 2:
								return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
							default:
								return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
						}
					}(),
						function () {
						switch (state) {
							case 1:
								return $surprisetalk$elm_bulma$Bulma$Classes$isHovered;
							case 2:
								return $surprisetalk$elm_bulma$Bulma$Classes$isFocused;
							case 3:
								return $surprisetalk$elm_bulma$Bulma$Classes$isActive;
							case 4:
								return $surprisetalk$elm_bulma$Bulma$Classes$isLoading;
							default:
								return $surprisetalk$elm_bulma$Bulma$Classes$none;
						}
					}(),
						function () {
						switch (color) {
							case 0:
								return $surprisetalk$elm_bulma$Bulma$Classes$none;
							case 1:
								return $surprisetalk$elm_bulma$Bulma$Classes$isWhite;
							case 2:
								return $surprisetalk$elm_bulma$Bulma$Classes$isLight;
							case 3:
								return $surprisetalk$elm_bulma$Bulma$Classes$isDark;
							case 4:
								return $surprisetalk$elm_bulma$Bulma$Classes$isBlack;
							case 5:
								return $surprisetalk$elm_bulma$Bulma$Classes$isPrimary;
							case 7:
								return $surprisetalk$elm_bulma$Bulma$Classes$isInfo;
							case 8:
								return $surprisetalk$elm_bulma$Bulma$Classes$isSuccess;
							case 9:
								return $surprisetalk$elm_bulma$Bulma$Classes$isWarning;
							case 10:
								return $surprisetalk$elm_bulma$Bulma$Classes$isDanger;
							default:
								return $surprisetalk$elm_bulma$Bulma$Classes$isLink;
						}
					}()
					]),
				attrs_));
	});
var $surprisetalk$elm_bulma$Bulma$Form$controlTextAreaModifiers = {H: 0, dY: false, b5: false, bE: 1, D: 0};
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Utils$empty = $elm$html$Html$text('');
var $surprisetalk$elm_bulma$Bulma$Classes$field = $elm$html$Html$Attributes$class('field');
var $surprisetalk$elm_bulma$Bulma$Form$field = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'div',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$field]));
var $elm$html$Html$form = _VirtualDom_node('form');
var $surprisetalk$elm_bulma$Bulma$Classes$help = $elm$html$Html$Attributes$class('help');
var $surprisetalk$elm_bulma$Bulma$Form$help = function (color) {
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		'p',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$help,
				function () {
				switch (color) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWhite;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLight;
					case 3:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDark;
					case 4:
						return $surprisetalk$elm_bulma$Bulma$Classes$isBlack;
					case 5:
						return $surprisetalk$elm_bulma$Bulma$Classes$isPrimary;
					case 6:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLink;
					case 7:
						return $surprisetalk$elm_bulma$Bulma$Classes$isInfo;
					case 8:
						return $surprisetalk$elm_bulma$Bulma$Classes$isSuccess;
					case 9:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWarning;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDanger;
				}
			}()
			]));
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Utils$intentionStatusToString = function (status) {
	if (!status) {
		return 'active';
	} else {
		return 'done';
	}
};
var $surprisetalk$elm_bulma$Bulma$Classes$level = $elm$html$Html$Attributes$class('level');
var $surprisetalk$elm_bulma$Bulma$Layout$level = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'nav',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$level]));
var $surprisetalk$elm_bulma$Bulma$Classes$levelItem = $elm$html$Html$Attributes$class('level-item');
var $surprisetalk$elm_bulma$Bulma$Layout$levelItem = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'div',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$levelItem]));
var $surprisetalk$elm_bulma$Bulma$Classes$levelLeft = $elm$html$Html$Attributes$class('level-left');
var $surprisetalk$elm_bulma$Bulma$Layout$levelLeft = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'div',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$levelLeft]));
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $CurrySoftware$elm_datepicker$DatePicker$Date$MoreOrLess = function (a) {
	return {$: 1, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$moreOrLess = function (range) {
	return $CurrySoftware$elm_datepicker$DatePicker$Date$MoreOrLess(range);
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter = {$: 37};
var $surprisetalk$elm_bulma$Bulma$Modifiers$Small = 0;
var $Gizra$elm_keyboard_event$Keyboard$Event$KeyboardEvent = F7(
	function (altKey, ctrlKey, key, keyCode, metaKey, repeat, shiftKey) {
		return {hK: altKey, h2: ctrlKey, iI: key, iJ: keyCode, iQ: metaKey, js: repeat, jD: shiftKey};
	});
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKey = $elm$json$Json$Decode$maybe(
	A2(
		$elm$json$Json$Decode$andThen,
		function (key) {
			return $elm$core$String$isEmpty(key) ? $elm$json$Json$Decode$fail('empty key') : $elm$json$Json$Decode$succeed(key);
		},
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string)));
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero = A2(
	$elm$json$Json$Decode$andThen,
	function (code) {
		return (!code) ? $elm$json$Json$Decode$fail('code was zero') : $elm$json$Json$Decode$succeed(code);
	},
	$elm$json$Json$Decode$int);
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyCode = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$field, 'keyCode', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			A2($elm$json$Json$Decode$field, 'which', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			A2($elm$json$Json$Decode$field, 'charCode', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			$elm$json$Json$Decode$succeed(0)
		]));
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$A = {$: 0};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add = {$: 85};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt = {$: 32};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous = function (a) {
	return {$: 89, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$B = {$: 1};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace = {$: 38};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$C = {$: 2};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock = {$: 34};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch = {$: 59};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command = {$: 58};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl = function (a) {
	return {$: 31, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$D = {$: 3};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal = {$: 87};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete = {$: 39};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide = {$: 88};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down = {$: 29};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$E = {$: 4};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight = {$: 52};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$End = {$: 42};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape = {$: 36};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F = {$: 5};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1 = {$: 62};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10 = {$: 71};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11 = {$: 72};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12 = {$: 73};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2 = {$: 63};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3 = {$: 64};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4 = {$: 65};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5 = {$: 66};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6 = {$: 67};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7 = {$: 68};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8 = {$: 69};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9 = {$: 70};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five = {$: 49};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four = {$: 48};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$G = {$: 6};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$H = {$: 7};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home = {$: 43};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$I = {$: 8};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert = {$: 54};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$J = {$: 9};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$K = {$: 10};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$L = {$: 11};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left = {$: 26};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$M = {$: 12};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply = {$: 84};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$N = {$: 13};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine = {$: 53};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock = {$: 60};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight = {$: 82};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive = {$: 79};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour = {$: 78};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine = {$: 83};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne = {$: 75};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven = {$: 81};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix = {$: 80};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree = {$: 77};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo = {$: 76};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero = {$: 74};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$O = {$: 14};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$One = {$: 45};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$P = {$: 15};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown = {$: 41};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp = {$: 40};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak = {$: 56};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen = {$: 55};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q = {$: 16};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$R = {$: 17};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right = {$: 27};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$S = {$: 18};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock = {$: 61};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven = {$: 51};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift = function (a) {
	return {$: 30, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six = {$: 50};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar = {$: 35};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract = {$: 86};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$T = {$: 19};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab = {$: 33};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three = {$: 47};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two = {$: 46};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$U = {$: 20};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown = function (a) {
	return {$: 90, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up = {$: 28};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$V = {$: 21};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$W = {$: 22};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows = {$: 57};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$X = {$: 23};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y = {$: 24};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z = {$: 25};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero = {$: 44};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode = function (keyCode) {
	switch (keyCode) {
		case 8:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace;
		case 9:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab;
		case 13:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter;
		case 16:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift($elm$core$Maybe$Nothing);
		case 17:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl($elm$core$Maybe$Nothing);
		case 18:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt;
		case 19:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak;
		case 20:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock;
		case 27:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape;
		case 32:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar;
		case 33:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp;
		case 34:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown;
		case 35:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$End;
		case 36:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home;
		case 37:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left;
		case 38:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up;
		case 39:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right;
		case 40:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down;
		case 44:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen;
		case 45:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert;
		case 46:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete;
		case 48:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero;
		case 49:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$One;
		case 50:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two;
		case 51:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three;
		case 52:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four;
		case 53:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five;
		case 54:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six;
		case 55:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven;
		case 56:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight;
		case 57:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine;
		case 65:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$A;
		case 66:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$B;
		case 67:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$C;
		case 68:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$D;
		case 69:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$E;
		case 70:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F;
		case 71:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$G;
		case 72:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$H;
		case 73:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$I;
		case 74:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$J;
		case 75:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$K;
		case 76:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$L;
		case 77:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$M;
		case 78:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$N;
		case 79:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$O;
		case 80:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$P;
		case 81:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q;
		case 82:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$R;
		case 83:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$S;
		case 84:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$T;
		case 85:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$U;
		case 86:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$V;
		case 87:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$W;
		case 88:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$X;
		case 89:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y;
		case 90:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z;
		case 91:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous(
				_List_fromArray(
					[$SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows, $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command, $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch]));
		case 96:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero;
		case 97:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne;
		case 98:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo;
		case 99:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree;
		case 100:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour;
		case 101:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive;
		case 102:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix;
		case 103:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven;
		case 104:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight;
		case 105:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine;
		case 106:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply;
		case 107:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add;
		case 109:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract;
		case 110:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal;
		case 111:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide;
		case 112:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1;
		case 113:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2;
		case 114:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3;
		case 115:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4;
		case 116:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5;
		case 117:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6;
		case 118:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7;
		case 119:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8;
		case 120:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9;
		case 121:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10;
		case 122:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11;
		case 123:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12;
		case 144:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock;
		case 145:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock;
		default:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown(keyCode);
	}
};
var $elm$json$Json$Decode$map7 = _Json_map7;
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyboardEvent = A8(
	$elm$json$Json$Decode$map7,
	$Gizra$elm_keyboard_event$Keyboard$Event$KeyboardEvent,
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	$Gizra$elm_keyboard_event$Keyboard$Event$decodeKey,
	A2($elm$json$Json$Decode$map, $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode, $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyCode),
	A2($elm$json$Json$Decode$field, 'metaKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'repeat', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $author$project$JSON$decodeRelatedTargetId = A2(
	$elm$json$Json$Decode$field,
	'relatedTarget',
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string)));
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Components$multiSelect = F8(
	function (val, highlightedIndex, toggleSelected, onValueChanged, onFocusChanged, onKeyDown, dropdownOpen, items) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('multi-select'),
					$elm$html$Html$Attributes$id('multi-select'),
					A2(
					$elm$html$Html$Events$preventDefaultOn,
					'keydown',
					A2(
						$elm$json$Json$Decode$map,
						function (k) {
							return _Utils_Tuple2(
								onKeyDown(k),
								_Utils_eq(k.iJ, $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter));
						},
						$Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyboardEvent)),
					A2(
					$elm$html$Html$Events$on,
					'focusout',
					A2(
						$elm$json$Json$Decode$andThen,
						function (id) {
							return (!_Utils_eq(
								id,
								$elm$core$Maybe$Just('multi-select'))) ? $elm$json$Json$Decode$succeed(
								onFocusChanged(false)) : $elm$json$Json$Decode$succeed($author$project$Types$NoOp);
						},
						$author$project$JSON$decodeRelatedTargetId)),
					$elm$html$Html$Attributes$tabindex(0)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							'dropdown ' + (dropdownOpen ? 'is-active' : ''))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-trigger')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$input,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('input'),
											$elm$html$Html$Attributes$type_('text'),
											$elm$html$Html$Attributes$value(val),
											$elm$html$Html$Events$onInput(onValueChanged),
											$elm$html$Html$Attributes$placeholder('Start typing to search...'),
											$elm$html$Html$Events$onFocus(
											onFocusChanged(true))
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-menu')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('dropdown-content')
										]),
									A2(
										$elm$core$List$map,
										function (t) {
											var _v1 = t;
											var idx = _v1.a;
											var i = _v1.b;
											var _v2 = i;
											var id = _v2.a;
											var display = _v2.b;
											var selected = _v2.c;
											return A2(
												$elm$html$Html$a,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$classList(
														_List_fromArray(
															[
																_Utils_Tuple2('dropdown-item', true),
																_Utils_Tuple2('is-selected', selected),
																_Utils_Tuple2(
																'is-highlighted',
																A2(
																	$elm$core$Maybe$withDefault,
																	false,
																	A2(
																		$elm$core$Maybe$map,
																		function (hi) {
																			return _Utils_eq(hi, idx);
																		},
																		highlightedIndex)))
															])),
														$elm$html$Html$Events$onClick(
														toggleSelected(i))
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(display)
													]));
										},
										A2(
											$elm$core$List$indexedMap,
											F2(
												function (idx, i) {
													return _Utils_Tuple2(idx, i);
												}),
											A2(
												$elm$core$List$filter,
												function (t) {
													var _v0 = t;
													var display = _v0.b;
													return A2(
														$elm$core$String$contains,
														$elm$core$String$toUpper(val),
														$elm$core$String$toUpper(display));
												},
												items))))
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('selected-tags')
						]),
					A2(
						$elm$core$List$filterMap,
						function (i) {
							var _v3 = i;
							var id = _v3.a;
							var display = _v3.b;
							var selected = _v3.c;
							return selected ? $elm$core$Maybe$Just(
								A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('tag is-primary'),
											$elm$html$Html$Events$onClick(
											toggleSelected(i))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(display),
											A3(
											$surprisetalk$elm_bulma$Bulma$Elements$icon,
											0,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('fa fa-times')
														]),
													_List_Nil)
												]))
										]))) : $elm$core$Maybe$Nothing;
						},
						items))
				]));
	});
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $surprisetalk$elm_bulma$Bulma$Classes$progress = $elm$html$Html$Attributes$class('progress');
var $surprisetalk$elm_bulma$Bulma$Elements$progress = function (_v0) {
	var size = _v0.bE;
	var color = _v0.H;
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		'progress',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$progress,
				function () {
				switch (size) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$isSmall;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
				}
			}(),
				function () {
				switch (color) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWhite;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLight;
					case 3:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDark;
					case 4:
						return $surprisetalk$elm_bulma$Bulma$Classes$isBlack;
					case 5:
						return $surprisetalk$elm_bulma$Bulma$Classes$isPrimary;
					case 7:
						return $surprisetalk$elm_bulma$Bulma$Classes$isInfo;
					case 8:
						return $surprisetalk$elm_bulma$Bulma$Classes$isSuccess;
					case 9:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWarning;
					case 10:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDanger;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLink;
				}
			}()
			]));
};
var $surprisetalk$elm_bulma$Bulma$Elements$progressModifiers = {H: 0, bE: 1};
var $author$project$View$statusColour = function (status) {
	if (!status) {
		return 5;
	} else {
		return 5;
	}
};
var $surprisetalk$elm_bulma$Bulma$Classes$is1 = $elm$html$Html$Attributes$class('is-1');
var $surprisetalk$elm_bulma$Bulma$Classes$is2 = $elm$html$Html$Attributes$class('is-2');
var $surprisetalk$elm_bulma$Bulma$Classes$is3 = $elm$html$Html$Attributes$class('is-3');
var $surprisetalk$elm_bulma$Bulma$Classes$is4 = $elm$html$Html$Attributes$class('is-4');
var $surprisetalk$elm_bulma$Bulma$Classes$is5 = $elm$html$Html$Attributes$class('is-5');
var $surprisetalk$elm_bulma$Bulma$Classes$is6 = $elm$html$Html$Attributes$class('is-6');
var $surprisetalk$elm_bulma$Bulma$Classes$title = $elm$html$Html$Attributes$class('title');
var $surprisetalk$elm_bulma$Bulma$Elements$title = function (size) {
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		function () {
			switch (size) {
				case 0:
					return 'h1';
				case 1:
					return 'h2';
				case 2:
					return 'h3';
				case 3:
					return 'h4';
				case 4:
					return 'h5';
				default:
					return 'h6';
			}
		}(),
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$title,
				function () {
				switch (size) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$is1;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$is2;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$is3;
					case 3:
						return $surprisetalk$elm_bulma$Bulma$Classes$is4;
					case 4:
						return $surprisetalk$elm_bulma$Bulma$Classes$is5;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$is6;
				}
			}()
			]));
};
var $CurrySoftware$elm_datepicker$DatePicker$Blur = {$: 6};
var $CurrySoftware$elm_datepicker$DatePicker$Focus = {$: 5};
var $CurrySoftware$elm_datepicker$DatePicker$SubmitText = {$: 4};
var $CurrySoftware$elm_datepicker$DatePicker$Text = function (a) {
	return {$: 3, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$ChangeFocus = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Date$Months = 1;
var $CurrySoftware$elm_datepicker$DatePicker$MouseDown = {$: 7};
var $CurrySoftware$elm_datepicker$DatePicker$MouseUp = {$: 8};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.hF - 1)) + ($justinmimbs$date$Date$monthToNumber(date.gG) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.f2,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$Date$changeYear = F2(
	function (current, newYear) {
		var _v0 = $elm$core$String$toInt(newYear);
		if (!_v0.$) {
			var year = _v0.a;
			return A3(
				$justinmimbs$date$Date$fromCalendarDate,
				year,
				$justinmimbs$date$Date$month(current),
				$justinmimbs$date$Date$day(current));
		} else {
			return current;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$groupDates = function (dates) {
	var go = F4(
		function (i, xs, racc, acc) {
			go:
			while (true) {
				if (!xs.b) {
					return $elm$core$List$reverse(acc);
				} else {
					var x = xs.a;
					var xxs = xs.b;
					if (i === 6) {
						var $temp$i = 0,
							$temp$xs = xxs,
							$temp$racc = _List_Nil,
							$temp$acc = A2(
							$elm$core$List$cons,
							$elm$core$List$reverse(
								A2($elm$core$List$cons, x, racc)),
							acc);
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					} else {
						var $temp$i = i + 1,
							$temp$xs = xxs,
							$temp$racc = A2($elm$core$List$cons, x, racc),
							$temp$acc = acc;
						i = $temp$i;
						xs = $temp$xs;
						racc = $temp$racc;
						acc = $temp$acc;
						continue go;
					}
				}
			}
		});
	return A4(go, 0, dates, _List_Nil, _List_Nil);
};
var $CurrySoftware$elm_datepicker$DatePicker$maybeOr = F2(
	function (lhs, rhs) {
		if (!rhs.$) {
			return rhs;
		} else {
			return lhs;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$mkClass = F2(
	function (_v0, c) {
		var classNamespace = _v0.bR;
		return $elm$html$Html$Attributes$class(
			_Utils_ap(classNamespace, c));
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $elm$html$Html$option = _VirtualDom_node('option');
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$date$Date$Weeks = 2;
var $justinmimbs$date$Date$Years = 0;
var $justinmimbs$date$Date$intervalToUnits = function (interval) {
	switch (interval) {
		case 0:
			return _Utils_Tuple2(1, 0);
		case 1:
			return _Utils_Tuple2(3, 1);
		case 2:
			return _Utils_Tuple2(1, 1);
		case 11:
			return _Utils_Tuple2(1, 3);
		default:
			var week = interval;
			return _Utils_Tuple2(1, 2);
	}
};
var $justinmimbs$date$Date$ceiling = F2(
	function (interval, date) {
		var floored = A2($justinmimbs$date$Date$floor, interval, date);
		if (_Utils_eq(date, floored)) {
			return date;
		} else {
			var _v0 = $justinmimbs$date$Date$intervalToUnits(interval);
			var n = _v0.a;
			var unit = _v0.b;
			return A3($justinmimbs$date$Date$add, unit, n, floored);
		}
	});
var $justinmimbs$date$Date$rangeHelp = F5(
	function (unit, step, until, revList, current) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(current, until) < 0) {
				var _v0 = A3($justinmimbs$date$Date$add, unit, step, current);
				var next = _v0;
				var $temp$unit = unit,
					$temp$step = step,
					$temp$until = until,
					$temp$revList = A2($elm$core$List$cons, current, revList),
					$temp$current = next;
				unit = $temp$unit;
				step = $temp$step;
				until = $temp$until;
				revList = $temp$revList;
				current = $temp$current;
				continue rangeHelp;
			} else {
				return $elm$core$List$reverse(revList);
			}
		}
	});
var $justinmimbs$date$Date$range = F4(
	function (interval, step, _v0, _v1) {
		var start = _v0;
		var until = _v1;
		var _v2 = $justinmimbs$date$Date$intervalToUnits(interval);
		var n = _v2.a;
		var unit = _v2.b;
		var _v3 = A2($justinmimbs$date$Date$ceiling, interval, start);
		var first = _v3;
		return (_Utils_cmp(first, until) < 0) ? A5(
			$justinmimbs$date$Date$rangeHelp,
			unit,
			A2($elm$core$Basics$max, 1, step) * n,
			until,
			_List_Nil,
			first) : _List_Nil;
	});
var $justinmimbs$date$Date$Friday = 8;
var $justinmimbs$date$Date$Monday = 4;
var $justinmimbs$date$Date$Saturday = 9;
var $justinmimbs$date$Date$Sunday = 10;
var $justinmimbs$date$Date$Thursday = 7;
var $justinmimbs$date$Date$Tuesday = 5;
var $justinmimbs$date$Date$Wednesday = 6;
var $CurrySoftware$elm_datepicker$DatePicker$Date$weekdayToInterval = function (weekday) {
	switch (weekday) {
		case 0:
			return 4;
		case 1:
			return 5;
		case 2:
			return 6;
		case 3:
			return 7;
		case 4:
			return 8;
		case 5:
			return 9;
		default:
			return 10;
	}
};
var $CurrySoftware$elm_datepicker$DatePicker$prepareDates = F2(
	function (date, firstDayOfWeek) {
		var weekdayAsInterval = $CurrySoftware$elm_datepicker$DatePicker$Date$weekdayToInterval(firstDayOfWeek);
		var start = A2(
			$justinmimbs$date$Date$floor,
			weekdayAsInterval,
			A3(
				$justinmimbs$date$Date$fromCalendarDate,
				$justinmimbs$date$Date$year(date),
				$justinmimbs$date$Date$month(date),
				1));
		var firstOfMonth = A3(
			$justinmimbs$date$Date$fromCalendarDate,
			$justinmimbs$date$Date$year(date),
			$justinmimbs$date$Date$month(date),
			1);
		var end = A2(
			$justinmimbs$date$Date$ceiling,
			weekdayAsInterval,
			A3($justinmimbs$date$Date$add, 1, 1, firstOfMonth));
		return {
			f0: A4($justinmimbs$date$Date$range, 11, 1, start, end),
			dR: date
		};
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $CurrySoftware$elm_datepicker$DatePicker$Pick = function (a) {
	return {$: 2, a: a};
};
var $CurrySoftware$elm_datepicker$DatePicker$mkClassList = F2(
	function (_v0, cs) {
		var classNamespace = _v0.bR;
		return $elm$html$Html$Attributes$classList(
			A2(
				$elm$core$List$map,
				function (_v1) {
					var c = _v1.a;
					var b = _v1.b;
					return _Utils_Tuple2(
						_Utils_ap(classNamespace, c),
						b);
				},
				cs));
	});
var $CurrySoftware$elm_datepicker$DatePicker$viewDay = F5(
	function (settings, picked, isOtherMonth, isToday, d) {
		var disabled = settings.c8(d);
		var props = (!disabled) ? _List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				$CurrySoftware$elm_datepicker$DatePicker$Pick(d))
			]) : _List_Nil;
		var classList = $CurrySoftware$elm_datepicker$DatePicker$mkClassList(settings);
		return A2(
			$elm$html$Html$td,
			_Utils_ap(
				_List_fromArray(
					[
						classList(
						_List_fromArray(
							[
								_Utils_Tuple2('day', true),
								_Utils_Tuple2('disabled', disabled),
								_Utils_Tuple2(
								'picked',
								picked(d)),
								_Utils_Tuple2(
								'today',
								isToday(d)),
								_Utils_Tuple2(
								'other-month',
								isOtherMonth(d))
							]))
					]),
				props),
			_List_fromArray(
				[
					settings.dL(
					$elm$core$String$fromInt(
						$justinmimbs$date$Date$day(d)))
				]));
	});
var $CurrySoftware$elm_datepicker$DatePicker$Date$yearRange = F2(
	function (_v0, range) {
		var currentMonth = _v0.dR;
		var today = _v0.bj;
		switch (range.$) {
			case 1:
				var num = range.a;
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(currentMonth) - num,
					$justinmimbs$date$Date$year(currentMonth) + num);
			case 2:
				var start = range.a;
				var end = range.b;
				return A2($elm$core$List$range, start, end);
			case 3:
				var year_ = range.a;
				return A2(
					$elm$core$List$range,
					year_,
					$justinmimbs$date$Date$year(today));
			case 4:
				var year_ = range.a;
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(today),
					year_);
			default:
				return _List_Nil;
		}
	});
var $CurrySoftware$elm_datepicker$DatePicker$yearRangeActive = function (yearRange) {
	return !_Utils_eq(yearRange, $CurrySoftware$elm_datepicker$DatePicker$Date$Off);
};
var $CurrySoftware$elm_datepicker$DatePicker$datePicker = F3(
	function (pickedDate, settings, model) {
		var focused = model.an;
		var today = model.bj;
		var picked = function (d) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (pdate) {
						return _Utils_eq(
							$justinmimbs$date$Date$toRataDie(pdate),
							$justinmimbs$date$Date$toRataDie(d));
					},
					pickedDate));
		};
		var onChange = function (handler) {
			return A2(
				$elm$html$Html$Events$on,
				'change',
				A2($elm$json$Json$Decode$map, handler, $elm$html$Html$Events$targetValue));
		};
		var isToday = function (d) {
			return _Utils_eq(
				$justinmimbs$date$Date$toRataDie(d),
				$justinmimbs$date$Date$toRataDie(today));
		};
		var firstDayOffset = $justinmimbs$date$Date$weekdayToNumber(settings.c5) - 1;
		var dpClass = $CurrySoftware$elm_datepicker$DatePicker$mkClass(settings);
		var currentDate = A2(
			$elm$core$Maybe$withDefault,
			today,
			A2($CurrySoftware$elm_datepicker$DatePicker$maybeOr, pickedDate, focused));
		var isOtherMonth = function (d) {
			return !_Utils_eq(
				$justinmimbs$date$Date$month(currentDate),
				$justinmimbs$date$Date$month(d));
		};
		var arrow = F2(
			function (className, message) {
				return A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							dpClass(className),
							$elm$html$Html$Events$onClick(message),
							$elm$html$Html$Attributes$tabindex(-1),
							$elm$html$Html$Attributes$type_('button')
						]),
					_List_Nil);
			});
		var _v0 = A2($CurrySoftware$elm_datepicker$DatePicker$prepareDates, currentDate, settings.c5);
		var currentMonth = _v0.dR;
		var currentDates = _v0.f0;
		var dayList = A2(
			$elm$core$List$map,
			function (rowDays) {
				return A2(
					$elm$html$Html$tr,
					_List_fromArray(
						[
							dpClass('row')
						]),
					A2(
						$elm$core$List$map,
						A4($CurrySoftware$elm_datepicker$DatePicker$viewDay, settings, picked, isOtherMonth, isToday),
						rowDays));
			},
			$CurrySoftware$elm_datepicker$DatePicker$groupDates(currentDates));
		var isCurrentYear = function (selectedYear) {
			return _Utils_eq(
				$justinmimbs$date$Date$year(currentMonth),
				selectedYear);
		};
		var yearOption = F2(
			function (index, selectedYear) {
				return _Utils_Tuple2(
					$elm$core$String$fromInt(index),
					A2(
						$elm$html$Html$option,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$value(
								$elm$core$String$fromInt(selectedYear)),
								$elm$html$Html$Attributes$selected(
								isCurrentYear(selectedYear))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$elm$core$String$fromInt(selectedYear))
							])));
			});
		var _v1 = function () {
			var front = function (to_) {
				return A2(
					$elm$core$List$range,
					$justinmimbs$date$Date$year(currentMonth),
					to_);
			};
			var back = function (from_) {
				return A2(
					$elm$core$List$range,
					from_,
					$justinmimbs$date$Date$year(currentMonth));
			};
			var _v2 = settings.hV;
			switch (_v2.$) {
				case 3:
					var from_ = _v2.a;
					return _Utils_Tuple2(
						front(from_ - 1),
						back(
							$justinmimbs$date$Date$year(today) + 1));
				case 4:
					var to_ = _v2.a;
					return _Utils_Tuple2(
						front(
							$justinmimbs$date$Date$year(today) - 1),
						back(to_ + 1));
				case 2:
					var from_ = _v2.a;
					var to_ = _v2.b;
					return _Utils_Tuple2(
						front(from_ - 1),
						back(to_ + 1));
				case 1:
					var y = _v2.a;
					return _Utils_Tuple2(_List_Nil, _List_Nil);
				default:
					return _Utils_Tuple2(_List_Nil, _List_Nil);
			}
		}();
		var addedYearsFront = _v1.a;
		var addedYearsBack = _v1.b;
		var dropdownYear = A3(
			$elm$html$Html$Keyed$node,
			'select',
			_List_fromArray(
				[
					onChange(
					A2(
						$elm$core$Basics$composeR,
						$CurrySoftware$elm_datepicker$DatePicker$Date$changeYear(currentDate),
						$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus)),
					dpClass('year-menu')
				]),
			A2(
				$elm$core$List$indexedMap,
				yearOption,
				$elm$core$List$concat(
					_List_fromArray(
						[
							addedYearsFront,
							A2(
							$CurrySoftware$elm_datepicker$DatePicker$Date$yearRange,
							{dR: currentMonth, bj: today},
							settings.hV),
							addedYearsBack
						]))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					dpClass('picker'),
					A2(
					$elm$html$Html$Events$stopPropagationOn,
					'mousedown',
					$elm$json$Json$Decode$succeed(
						_Utils_Tuple2($CurrySoftware$elm_datepicker$DatePicker$MouseDown, true))),
					A2(
					$elm$html$Html$Events$stopPropagationOn,
					'mouseup',
					$elm$json$Json$Decode$succeed(
						_Utils_Tuple2($CurrySoftware$elm_datepicker$DatePicker$MouseUp, true)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							dpClass('picker-header')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('prev-container')
								]),
							_List_fromArray(
								[
									A2(
									arrow,
									'prev',
									$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus(
										A3($justinmimbs$date$Date$add, 1, -1, currentDate)))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('month-container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											dpClass('month')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											settings.eI(
												$justinmimbs$date$Date$month(currentMonth)))
										])),
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											dpClass('year')
										]),
									_List_fromArray(
										[
											(!$CurrySoftware$elm_datepicker$DatePicker$yearRangeActive(settings.hV)) ? $elm$html$Html$text(
											settings.fO(
												$justinmimbs$date$Date$year(currentMonth))) : A3(
											$elm$html$Html$Keyed$node,
											'span',
											_List_Nil,
											_List_fromArray(
												[
													_Utils_Tuple2(
													$elm$core$String$fromInt(
														$justinmimbs$date$Date$year(currentMonth)),
													dropdownYear)
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									dpClass('next-container')
								]),
							_List_fromArray(
								[
									A2(
									arrow,
									'next',
									$CurrySoftware$elm_datepicker$DatePicker$ChangeFocus(
										A3($justinmimbs$date$Date$add, 1, 1, currentDate)))
								]))
						])),
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							dpClass('table')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_fromArray(
								[
									dpClass('weekdays')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									A2(
										$elm$core$List$map,
										function (d) {
											return A2(
												$elm$html$Html$td,
												_List_fromArray(
													[
														dpClass('dow')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														settings.dT(d))
													]));
										},
										A2(
											$elm$core$List$take,
											7,
											A2(
												$elm$core$List$drop,
												firstDayOffset,
												$elm$core$List$concat(
													A2(
														$elm$core$List$repeat,
														2,
														_List_fromArray(
															[0, 1, 2, 3, 4, 5, 6])))))))
								])),
							A2(
							$elm$html$Html$tbody,
							_List_fromArray(
								[
									dpClass('days')
								]),
							dayList)
						]))
				]));
	});
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $CurrySoftware$elm_datepicker$DatePicker$view = F3(
	function (pickedDate, settings, _v0) {
		var datepicker = _v0;
		var model = datepicker;
		var potentialInputId = A3(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$elm$core$List$filterMap($elm$core$Basics$identity),
			A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, settings.el));
		var inputClasses = _Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(settings.bR + 'input', true)
				]),
			settings.iB);
		var inputCommon = function (xs) {
			return A2(
				$elm$html$Html$input,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(inputClasses),
							$elm$html$Html$Attributes$name(
							A2($elm$core$Maybe$withDefault, '', settings.em)),
							$elm$html$Html$Attributes$type_('text'),
							A2(
							$elm$html$Html$Events$on,
							'change',
							$elm$json$Json$Decode$succeed($CurrySoftware$elm_datepicker$DatePicker$SubmitText)),
							$elm$html$Html$Events$onInput($CurrySoftware$elm_datepicker$DatePicker$Text),
							$elm$html$Html$Events$onBlur($CurrySoftware$elm_datepicker$DatePicker$Blur),
							$elm$html$Html$Events$onClick($CurrySoftware$elm_datepicker$DatePicker$Focus),
							$elm$html$Html$Events$onFocus($CurrySoftware$elm_datepicker$DatePicker$Focus)
						]),
					_Utils_ap(
						settings.ek,
						_Utils_ap(potentialInputId, xs))),
				_List_Nil);
		};
		var dateInput = inputCommon(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$placeholder(settings.jj),
					$elm$html$Html$Attributes$value(
					A2(
						$elm$core$Maybe$withDefault,
						A2(
							$elm$core$Maybe$withDefault,
							'',
							A2($elm$core$Maybe$map, settings.dS, pickedDate)),
						model.aC))
				]));
		var containerClassList = A2(
			$elm$core$List$cons,
			_Utils_Tuple2(settings.bR + 'container', true),
			settings.dP);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(containerClassList)
				]),
			_List_fromArray(
				[
					dateInput,
					model.aV ? A3($CurrySoftware$elm_datepicker$DatePicker$datePicker, pickedDate, settings, model) : $elm$html$Html$text('')
				]));
	});
var $author$project$View$editIntention = F2(
	function (intentionId, model) {
		var maybeIntention = function () {
			if (!intentionId.$) {
				var i = intentionId.a;
				return A2($elm$core$Dict$get, i, model.iD);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var isNewIntention = function () {
			if (intentionId.$ === 1) {
				return true;
			} else {
				return false;
			}
		}();
		return A2(
			$elm$core$Maybe$withDefault,
			_List_fromArray(
				[
					A3(
					$surprisetalk$elm_bulma$Bulma$Elements$progress,
					_Utils_update(
						$surprisetalk$elm_bulma$Bulma$Elements$progressModifiers,
						{H: 5, bE: 3}),
					_List_Nil,
					_List_Nil)
				]),
			A2(
				$elm$core$Maybe$map,
				function (editingIntention) {
					var _v0 = _Utils_Tuple2(maybeIntention, intentionId);
					if ((_v0.a.$ === 1) && (!_v0.b.$)) {
						var _v1 = _v0.a;
						var id = _v0.b.a;
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Could not find intention with id: ' + $elm$core$String$fromInt(id))
									]))
							]);
					} else {
						return _List_fromArray(
							[
								A2(
								$surprisetalk$elm_bulma$Bulma$Layout$level,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$surprisetalk$elm_bulma$Bulma$Layout$levelLeft,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$surprisetalk$elm_bulma$Bulma$Layout$levelItem,
												_List_Nil,
												_List_fromArray(
													[
														A3(
														$surprisetalk$elm_bulma$Bulma$Elements$title,
														1,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(editingIntention.jU)
															]))
													])),
												A2(
												$surprisetalk$elm_bulma$Bulma$Layout$levelItem,
												_List_Nil,
												_List_fromArray(
													[
														function () {
														if (!intentionId.$) {
															var id = intentionId.a;
															return A2(
																$elm$html$Html$a,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('button button-default'),
																		$elm$html$Html$Attributes$href(
																		'/focus/' + $elm$core$String$fromInt(id))
																	]),
																_List_fromArray(
																	[
																		A3(
																		$surprisetalk$elm_bulma$Bulma$Elements$icon,
																		2,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$i,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('fa fa-eye')
																					]),
																				_List_Nil)
																			]))
																	]));
														} else {
															return $author$project$Utils$empty;
														}
													}()
													]))
											]))
									])),
								A2(
								$elm$html$Html$form,
								_List_fromArray(
									[
										$elm$html$Html$Events$onSubmit($author$project$Types$SaveIntention)
									]),
								_List_fromArray(
									[
										A2(
										$surprisetalk$elm_bulma$Bulma$Form$field,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$surprisetalk$elm_bulma$Bulma$Form$controlLabel,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Title')
													])),
												A4(
												$surprisetalk$elm_bulma$Bulma$Form$controlInput,
												$surprisetalk$elm_bulma$Bulma$Form$controlInputModifiers,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$placeholder('Title'),
														$elm$html$Html$Attributes$value(editingIntention.jU),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$Types$IntentionEdited, $author$project$Types$TitleChanged))
													]),
												_List_Nil)
											])),
										A2(
										$surprisetalk$elm_bulma$Bulma$Form$field,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$surprisetalk$elm_bulma$Bulma$Form$controlLabel,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Description')
													])),
												A4(
												$surprisetalk$elm_bulma$Bulma$Form$controlTextArea,
												$surprisetalk$elm_bulma$Bulma$Form$controlTextAreaModifiers,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$placeholder('Description'),
														$elm$html$Html$Attributes$value(
														A2($elm$core$Maybe$withDefault, '', editingIntention.ib)),
														$elm$html$Html$Events$onInput(
														A2($elm$core$Basics$composeL, $author$project$Types$IntentionEdited, $author$project$Types$DescriptionChanged))
													]),
												_List_Nil),
												A3(
												$surprisetalk$elm_bulma$Bulma$Form$help,
												0,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Markdown is supported')
													]))
											])),
										(!$elm$core$Dict$isEmpty(model.iD)) ? A2(
										$surprisetalk$elm_bulma$Bulma$Form$field,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$surprisetalk$elm_bulma$Bulma$Form$controlLabel,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Parents')
													])),
												A8(
												$author$project$Components$multiSelect,
												editingIntention.jg,
												editingIntention.je,
												function (i) {
													var _v4 = i;
													var id = _v4.a;
													return $author$project$Types$IntentionEdited(
														$author$project$Types$ParentSelected(id));
												},
												A2($elm$core$Basics$composeL, $author$project$Types$IntentionEdited, $author$project$Types$ParentDropdownUpdated),
												A2($elm$core$Basics$composeL, $author$project$Types$IntentionEdited, $author$project$Types$ParentDropdownFocusChanged),
												A2($elm$core$Basics$composeL, $author$project$Types$IntentionEdited, $author$project$Types$ParentDropdownKeyDown),
												editingIntention.jf,
												A2(
													$elm$core$List$map,
													function (i) {
														return _Utils_Tuple3(
															i.iw,
															i.jU,
															A2($elm$core$Set$member, i.iw, editingIntention.jh));
													},
													A2(
														$elm$core$List$filter,
														function (i) {
															var _v3 = editingIntention.iw;
															if (!_v3.$) {
																var id = _v3.a;
																return !_Utils_eq(i.iw, id);
															} else {
																return true;
															}
														},
														$author$project$Utils$topologicalSort(
															$elm$core$Dict$values(model.iD)))))
											])) : $author$project$Utils$empty,
										isNewIntention ? $author$project$Utils$empty : A2(
										$surprisetalk$elm_bulma$Bulma$Form$field,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$surprisetalk$elm_bulma$Bulma$Form$controlLabel,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Status')
													])),
												A2(
												$surprisetalk$elm_bulma$Bulma$Form$field,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('has-addons status-field')
													]),
												A2(
													$elm$core$List$map,
													function (status) {
														return A4(
															$surprisetalk$elm_bulma$Bulma$Form$controlButton,
															_Utils_update(
																$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
																{
																	H: _Utils_eq(editingIntention.jJ, status) ? $author$project$View$statusColour(status) : 0,
																	bE: 2
																}),
															_List_Nil,
															_List_fromArray(
																[
																	$elm$html$Html$Events$onClick(
																	$author$project$Types$IntentionEdited(
																		$author$project$Types$StatusChanged(status)))
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text(
																	$author$project$Utils$intentionStatusToString(status))
																]));
													},
													_List_fromArray(
														[0, 1])))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field')
											]),
										_List_fromArray(
											[
												A2(
												$surprisetalk$elm_bulma$Bulma$Form$label,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('label')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Due Date')
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('control')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$map,
														function (msg) {
															return $author$project$Types$IntentionEdited(
																$author$project$Types$DateUpdated(msg));
														},
														A3(
															$CurrySoftware$elm_datepicker$DatePicker$view,
															editingIntention.$8,
															_Utils_update(
																$CurrySoftware$elm_datepicker$DatePicker$defaultSettings,
																{
																	hV: $CurrySoftware$elm_datepicker$DatePicker$moreOrLess(3),
																	iB: _List_fromArray(
																		[
																			_Utils_Tuple2('input', true)
																		]),
																	jj: 'Due Date'
																}),
															model.h5))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('columns control-buttons')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('column')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-primary is-large is-fullwidth'),
																$elm$html$Html$Attributes$classList(
																_List_fromArray(
																	[
																		_Utils_Tuple2('is-loading', model.iL),
																		_Utils_Tuple2('is-disabled', model.iL)
																	])),
																$elm$html$Html$Attributes$type_('submit')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Save')
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('column')
													]),
												_List_fromArray(
													[
														function () {
														var _v5 = editingIntention.iw;
														if (!_v5.$) {
															var id = _v5.a;
															return A2(
																$elm$html$Html$button,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('button is-large is-fullwidth'),
																		$elm$html$Html$Attributes$classList(
																		_List_fromArray(
																			[
																				_Utils_Tuple2('is-loading', model.iL),
																				_Utils_Tuple2('is-disabled', model.iL)
																			])),
																		$elm$html$Html$Events$onClick(
																		$author$project$Types$AddChildIntention(id)),
																		$elm$html$Html$Attributes$type_('button')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Add Sub-Task')
																	]));
														} else {
															return $author$project$Utils$empty;
														}
													}()
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('column')
													]),
												_List_fromArray(
													[
														function () {
														var _v6 = editingIntention.iw;
														if (!_v6.$) {
															var id = _v6.a;
															return A2(
																$elm$html$Html$button,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('button is-large is-fullwidth'),
																		$elm$html$Html$Attributes$classList(
																		_List_fromArray(
																			[
																				_Utils_Tuple2('is-loading', model.iL),
																				_Utils_Tuple2('is-disabled', model.iL)
																			])),
																		$elm$html$Html$Events$onClick(
																		$author$project$Types$AddSiblingIntention(id)),
																		$elm$html$Html$Attributes$type_('button')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Add Sibling')
																	]));
														} else {
															return $author$project$Utils$empty;
														}
													}()
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('column')
													]),
												_List_fromArray(
													[
														function () {
														var _v7 = editingIntention.iw;
														if (!_v7.$) {
															var id = _v7.a;
															return A2(
																$elm$html$Html$button,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('button is-large is-fullwidth is-danger'),
																		$elm$html$Html$Attributes$classList(
																		_List_fromArray(
																			[
																				_Utils_Tuple2('is-loading', model.iL),
																				_Utils_Tuple2('is-disabled', model.iL)
																			])),
																		$elm$html$Html$Events$onClick(
																		$author$project$Types$RequestDeleteIntention(id)),
																		$elm$html$Html$Attributes$type_('button')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Delete')
																	]));
														} else {
															return $author$project$Utils$empty;
														}
													}()
													]))
											]))
									]))
							]);
					}
				},
				model.ih));
	});
var $author$project$Types$DAGNodeUnselected = {$: 41};
var $author$project$Types$List = 0;
var $author$project$Types$MostParents = 1;
var $author$project$Types$RequestAddView = function (a) {
	return {$: 31, a: a};
};
var $author$project$Types$RequestDeleteView = function (a) {
	return {$: 36, a: a};
};
var $author$project$Types$SearchFilterUpdated = function (a) {
	return {$: 23, a: a};
};
var $author$project$Types$SearchFocused = {$: 39};
var $author$project$Types$SearchUnfocused = {$: 40};
var $author$project$Types$SetDoneFilter = function (a) {
	return {$: 21, a: a};
};
var $author$project$Types$SetLeavesFilter = function (a) {
	return {$: 22, a: a};
};
var $author$project$Types$SetSortType = function (a) {
	return {$: 5, a: a};
};
var $author$project$Types$SwitchTab = function (a) {
	return {$: 4, a: a};
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $surprisetalk$elm_bulma$Bulma$Classes$breadcrumb = $elm$html$Html$Attributes$class('breadcrumb');
var $surprisetalk$elm_bulma$Bulma$Classes$hasArrowSeparator = $elm$html$Html$Attributes$class('has-arrow-separator');
var $surprisetalk$elm_bulma$Bulma$Classes$hasBulletSeparator = $elm$html$Html$Attributes$class('has-bullet-separator');
var $surprisetalk$elm_bulma$Bulma$Classes$hasDotSeparator = $elm$html$Html$Attributes$class('has-dot-separator');
var $surprisetalk$elm_bulma$Bulma$Classes$hasSucceedsSeparator = $elm$html$Html$Attributes$class('has-succeeds-separator');
var $surprisetalk$elm_bulma$Bulma$Classes$isCentered = $elm$html$Html$Attributes$class('is-centered');
var $surprisetalk$elm_bulma$Bulma$Classes$isRight = $elm$html$Html$Attributes$class('is-right');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $surprisetalk$elm_bulma$Bulma$Components$breadcrumb = F3(
	function (_v0, attrs, attrs_) {
		var separator = _v0.hh;
		var alignment = _v0.bO;
		var size = _v0.bE;
		return A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				A3(
					$surprisetalk$elm_bulma$Helpers$node,
					'nav',
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'aria-label', 'breadcrumb'),
							$surprisetalk$elm_bulma$Bulma$Classes$breadcrumb,
							function () {
							switch (separator) {
								case 0:
									return $surprisetalk$elm_bulma$Bulma$Classes$none;
								case 1:
									return $surprisetalk$elm_bulma$Bulma$Classes$hasArrowSeparator;
								case 2:
									return $surprisetalk$elm_bulma$Bulma$Classes$hasBulletSeparator;
								case 3:
									return $surprisetalk$elm_bulma$Bulma$Classes$hasDotSeparator;
								default:
									return $surprisetalk$elm_bulma$Bulma$Classes$hasSucceedsSeparator;
							}
						}(),
							function () {
							switch (size) {
								case 0:
									return $surprisetalk$elm_bulma$Bulma$Classes$isSmall;
								case 1:
									return $surprisetalk$elm_bulma$Bulma$Classes$none;
								case 2:
									return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
								default:
									return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
							}
						}(),
							function () {
							switch (alignment) {
								case 0:
									return $surprisetalk$elm_bulma$Bulma$Classes$none;
								case 1:
									return $surprisetalk$elm_bulma$Bulma$Classes$isCentered;
								default:
									return $surprisetalk$elm_bulma$Bulma$Classes$isRight;
							}
						}()
						]),
					attrs),
				$surprisetalk$elm_bulma$Helpers$ls),
			$elm$html$Html$ul(attrs_));
	});
var $surprisetalk$elm_bulma$Bulma$Modifiers$Left = 0;
var $surprisetalk$elm_bulma$Bulma$Components$Slash = 0;
var $surprisetalk$elm_bulma$Bulma$Components$breadcrumbModifiers = {bO: 0, hh: 0, bE: 1};
var $elm$html$Html$li = _VirtualDom_node('li');
var $surprisetalk$elm_bulma$Bulma$Components$crumblet = F3(
	function (isActive, attrs, attrs_) {
		return A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				$elm$html$Html$li(
					isActive ? A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('is-active'),
						attrs) : attrs),
				$surprisetalk$elm_bulma$Helpers$ls),
			$elm$html$Html$a(attrs_));
	});
var $author$project$Types$DAGNodeClicked = function (a) {
	return {$: 17, a: a};
};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$foreignObject = $elm$svg$Svg$trustedNode('foreignObject');
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $author$project$Utils$getPointsString = function (points) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$map,
			function (p) {
				return $elm$core$String$fromFloat(p.hC) + (',' + $elm$core$String$fromFloat(p.hE));
			},
			points));
};
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $author$project$Utils$combinations = function (xs) {
	if (xs.b) {
		var x = xs.a;
		var rest = xs.b;
		return A2(
			$elm$core$List$append,
			$author$project$Utils$combinations(rest),
			A2(
				$elm$core$List$map,
				function (r) {
					return _Utils_Tuple2(x, r);
				},
				rest));
	} else {
		return _List_Nil;
	}
};
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Basics$pow = _Basics_pow;
var $author$project$Utils$nearestNeighbourDistance = function (layout) {
	var distance = F2(
		function (p1, p2) {
			return A2(
				$elm$core$Basics$pow,
				A2($elm$core$Basics$pow, p2.hC - p1.hC, 2) + A2($elm$core$Basics$pow, p2.hE - p1.hE, 2),
				0.5);
		});
	var allPoints = function (l) {
		var _v0 = l.hX;
		var cs = _v0;
		return A2(
			$elm$core$List$cons,
			{hC: l.hC, hE: l.hE},
			A2($elm$core$List$concatMap, allPoints, cs));
	};
	return $elm$core$List$minimum(
		A2(
			$elm$core$List$map,
			function (t) {
				return A2(distance, t.a, t.b);
			},
			$author$project$Utils$combinations(
				A2(
					$elm_community$list_extra$List$Extra$uniqueBy,
					function (p) {
						return $elm$core$String$fromFloat(p.hC) + ('::' + $elm$core$String$fromFloat(p.hE));
					},
					allPoints(layout)))));
};
var $elm$svg$Svg$Attributes$points = _VirtualDom_attribute('points');
var $elm$svg$Svg$polyline = $elm$svg$Svg$trustedNode('polyline');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Utils$treeSeq = F3(
	function (isBranch, children, tree) {
		return isBranch(tree) ? A2(
			$elm$core$List$cons,
			tree,
			A2(
				$elm$core$List$concatMap,
				A2($author$project$Utils$treeSeq, isBranch, children),
				children(tree))) : _List_fromArray(
			[tree]);
	});
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $author$project$View$dagDisplay = F3(
	function (goodBrowser, intentionsMap, layout) {
		var nodes = A3(
			$author$project$Utils$treeSeq,
			$author$project$Utils$constantly(true),
			function (n) {
				var _v0 = n.hX;
				var cs = _v0;
				return cs;
			},
			layout);
		var nodeSize = A2(
			$elm$core$Maybe$withDefault,
			2000.0,
			A2(
				$elm$core$Maybe$map,
				function (x) {
					return x * 0.45;
				},
				$author$project$Utils$nearestNeighbourDistance(layout)));
		var feSize = nodeSize / 1.414;
		return A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$id('dag')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$viewBox('0 0 10000 10000')
						]),
					A2(
						$elm$core$List$append,
						A2(
							$elm$core$List$concatMap,
							function (n) {
								return A2(
									$elm$core$List$map,
									function (link) {
										return A2(
											$elm$svg$Svg$polyline,
											_List_fromArray(
												[
													$elm$svg$Svg$Attributes$fill('None'),
													$elm$svg$Svg$Attributes$stroke('hsl(171, 100%, 41%)'),
													$elm$svg$Svg$Attributes$strokeWidth(
													$elm$core$String$fromFloat(nodeSize / 10)),
													$elm$svg$Svg$Attributes$points(
													$author$project$Utils$getPointsString(link.jk))
												]),
											_List_Nil);
									},
									n.hW);
							},
							nodes),
						A2(
							$elm$core$List$concatMap,
							function (n) {
								var maybeIntention = A2($elm$core$Dict$get, n.iw, intentionsMap);
								var modifier = A2(
									$elm$core$Maybe$withDefault,
									'',
									A2(
										$elm$core$Maybe$map,
										function (i) {
											return $author$project$Utils$intentionStatusToString(i.jJ);
										},
										maybeIntention));
								var title = A2(
									$elm$core$Maybe$withDefault,
									'',
									A2(
										$elm$core$Maybe$map,
										function (i) {
											return i.jU;
										},
										maybeIntention));
								return _List_fromArray(
									[
										A2(
										$elm$svg$Svg$circle,
										_List_fromArray(
											[
												$elm$svg$Svg$Attributes$cx(
												$elm$core$String$fromFloat(n.hC)),
												$elm$svg$Svg$Attributes$cy(
												$elm$core$String$fromFloat(n.hE)),
												$elm$svg$Svg$Attributes$r(
												$elm$core$String$fromFloat(nodeSize)),
												$elm$svg$Svg$Attributes$class('dag-node-circle dag-node-circle--' + modifier),
												$elm$html$Html$Events$onClick(
												$author$project$Types$DAGNodeClicked(n.iw))
											]),
										_List_Nil),
										A2(
										$elm$svg$Svg$foreignObject,
										_List_fromArray(
											[
												$elm$svg$Svg$Attributes$class('dag-node-foreign-object'),
												$elm$svg$Svg$Attributes$x(
												$elm$core$String$fromFloat(n.hC - feSize)),
												$elm$svg$Svg$Attributes$y(
												$elm$core$String$fromFloat(n.hE - feSize)),
												$elm$svg$Svg$Attributes$width(
												$elm$core$String$fromFloat(feSize * 2)),
												$elm$svg$Svg$Attributes$height(
												$elm$core$String$fromFloat(feSize * 2)),
												$elm$html$Html$Events$onClick(
												$author$project$Types$DAGNodeClicked(n.iw))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$classList(
														_List_fromArray(
															[
																_Utils_Tuple2('dag-node-text', true),
																_Utils_Tuple2('dag-node-text--good-browser', goodBrowser),
																_Utils_Tuple2('dag-node-text--' + modifier, true)
															]))
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('textFitted')
															]),
														_List_fromArray(
															[
																$elm$svg$Svg$text(title)
															]))
													]))
											]))
									]);
							},
							nodes)))
				]));
	});
var $author$project$Types$RequestQuickAddChild = function (a) {
	return {$: 25, a: a};
};
var $author$project$Types$ToggleIntentionDone = function (a) {
	return {$: 20, a: a};
};
var $author$project$Utils$distanceFromRoot = F2(
	function (intentions, intention) {
		var parents = A2(
			$elm$core$List$filter,
			function (i) {
				return A2(
					$elm$core$Set$member,
					i.iw,
					$elm$core$Set$fromList(
						A2(
							$elm$core$List$map,
							function ($) {
								return $.iw;
							},
							intention.ji)));
			},
			intentions);
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$List$minimum(
				A2(
					$elm$core$List$map,
					$author$project$Utils$distanceFromRoot(intentions),
					parents))) + 1;
	});
var $surprisetalk$elm_bulma$Bulma$Classes$delete = $elm$html$Html$Attributes$class('delete');
var $surprisetalk$elm_bulma$Bulma$Elements$delete = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'a',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$delete]));
var $surprisetalk$elm_bulma$Bulma$Elements$easyDelete = F2(
	function (attrs, msg) {
		return A2(
			$surprisetalk$elm_bulma$Bulma$Elements$delete,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Events$onClick(msg),
				attrs),
			_List_Nil);
	});
var $elm$html$Html$s = _VirtualDom_node('s');
var $pablohirafuji$elm_markdown$Markdown$Block$BlockQuote = function (a) {
	return {$: 5, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Block$List = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$Paragraph = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$core$String$trim = _String_trim;
var $pablohirafuji$elm_markdown$Markdown$Block$formatParagraphLine = function (rawParagraph) {
	return (A2($elm$core$String$right, 2, rawParagraph) === '  ') ? ($elm$core$String$trim(rawParagraph) + '  ') : $elm$core$String$trim(rawParagraph);
};
var $pablohirafuji$elm_markdown$Markdown$Block$addToParagraph = F2(
	function (paragraph, rawLine) {
		return A2(
			$pablohirafuji$elm_markdown$Markdown$Block$Paragraph,
			paragraph + ('\n' + $pablohirafuji$elm_markdown$Markdown$Block$formatParagraphLine(rawLine)),
			_List_Nil);
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {iz: index, iN: match, i4: number, jO: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{hS: false, iT: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $pablohirafuji$elm_markdown$Markdown$Block$blockQuoteLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(?:>[ ]?)(.*)$'));
var $pablohirafuji$elm_markdown$Markdown$Block$blankLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\s*$'));
var $elm$regex$Regex$contains = _Regex_contains;
var $pablohirafuji$elm_markdown$Markdown$Block$calcListIndentLength = function (_v0) {
	var listBlock = _v0.a;
	var indentSpace = _v0.b;
	var rawLine = _v0.c;
	var indentSpaceLength = $elm$core$String$length(indentSpace);
	var isIndentedCode = indentSpaceLength >= 4;
	var updtRawLine = isIndentedCode ? _Utils_ap(indentSpace, rawLine) : rawLine;
	var indentLength = (isIndentedCode || A2($elm$regex$Regex$contains, $pablohirafuji$elm_markdown$Markdown$Block$blankLineRegex, rawLine)) ? (listBlock.S - indentSpaceLength) : listBlock.S;
	return _Utils_Tuple2(
		_Utils_update(
			listBlock,
			{S: indentLength}),
		updtRawLine);
};
var $pablohirafuji$elm_markdown$Markdown$Block$atxHeadingLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(#{1,6})' + ('(?:[ \\t]+[ \\t#]+$|[ \\t]+|$)' + '(.*?)(?:\\s+[ \\t#]*)?$')));
var $pablohirafuji$elm_markdown$Markdown$Block$Heading = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$extractATXHeadingRM = function (match) {
	var _v0 = match.jO;
	if ((_v0.b && (!_v0.a.$)) && _v0.b.b) {
		var lvl = _v0.a.a;
		var _v1 = _v0.b;
		var maybeHeading = _v1.a;
		return $elm$core$Maybe$Just(
			A3(
				$pablohirafuji$elm_markdown$Markdown$Block$Heading,
				A2($elm$core$Maybe$withDefault, '', maybeHeading),
				$elm$core$String$length(lvl),
				_List_Nil));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$checkATXHeadingLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			function (a) {
				return A2($elm$core$List$cons, a, ast);
			},
			A2(
				$elm$core$Maybe$andThen,
				$pablohirafuji$elm_markdown$Markdown$Block$extractATXHeadingRM,
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$atxHeadingLineRegex, rawLine)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$BlankLine = function (a) {
	return {$: 0, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Block$CodeBlock = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$Fenced = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$addBlankLineToListBlock = F2(
	function (match, asts) {
		if (!asts.b) {
			return _List_fromArray(
				[
					_List_fromArray(
					[
						$pablohirafuji$elm_markdown$Markdown$Block$BlankLine(match.iN)
					])
				]);
		} else {
			var ast = asts.a;
			var astsTail = asts.b;
			return A2(
				$elm$core$List$cons,
				A2($pablohirafuji$elm_markdown$Markdown$Block$parseBlankLine, ast, match),
				astsTail);
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseBlankLine = F2(
	function (ast, match) {
		_v0$2:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 3:
						if ((ast.a.a.$ === 1) && ast.a.a.a) {
							var _v1 = ast.a;
							var _v2 = _v1.a;
							var fence = _v2.b;
							var code = _v1.b;
							var astTail = ast.b;
							return function (a) {
								return A2($elm$core$List$cons, a, astTail);
							}(
								A2(
									$pablohirafuji$elm_markdown$Markdown$Block$CodeBlock,
									A2($pablohirafuji$elm_markdown$Markdown$Block$Fenced, true, fence),
									code + '\n'));
						} else {
							break _v0$2;
						}
					case 6:
						var _v3 = ast.a;
						var model = _v3.a;
						var items = _v3.b;
						var astTail = ast.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$pablohirafuji$elm_markdown$Markdown$Block$List,
								model,
								A2($pablohirafuji$elm_markdown$Markdown$Block$addBlankLineToListBlock, match, items)),
							astTail);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return A2(
			$elm$core$List$cons,
			$pablohirafuji$elm_markdown$Markdown$Block$BlankLine(match.iN),
			ast);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$checkBlankLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$Block$parseBlankLine(ast),
			$elm$core$List$head(
				A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$blankLineRegex, rawLine))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$indentedCodeLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^(?: {4,4}| {0,3}\\t)(.*)$'));
var $pablohirafuji$elm_markdown$Markdown$Block$Indented = {$: 0};
var $pablohirafuji$elm_markdown$Markdown$Block$blocksAfterBlankLines = F2(
	function (ast, blankLines) {
		blocksAfterBlankLines:
		while (true) {
			if (ast.b && (!ast.a.$)) {
				var blankStr = ast.a.a;
				var astTail = ast.b;
				var $temp$ast = astTail,
					$temp$blankLines = A2($elm$core$List$cons, blankStr, blankLines);
				ast = $temp$ast;
				blankLines = $temp$blankLines;
				continue blocksAfterBlankLines;
			} else {
				return _Utils_Tuple2(ast, blankLines);
			}
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph = F2(
	function (rawLine, ast) {
		_v0$3:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 4:
						var _v1 = ast.a;
						var paragraph = _v1.a;
						var astTail = ast.b;
						return $elm$core$Maybe$Just(
							A2(
								$elm$core$List$cons,
								A2($pablohirafuji$elm_markdown$Markdown$Block$addToParagraph, paragraph, rawLine),
								astTail));
					case 5:
						var bqAST = ast.a.a;
						var astTail = ast.b;
						return A2(
							$elm$core$Maybe$map,
							function (updtBqAST) {
								return A2(
									$elm$core$List$cons,
									$pablohirafuji$elm_markdown$Markdown$Block$BlockQuote(updtBqAST),
									astTail);
							},
							A2($pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph, rawLine, bqAST));
					case 6:
						var _v2 = ast.a;
						var model = _v2.a;
						var items = _v2.b;
						var astTail = ast.b;
						if (items.b) {
							var itemAST = items.a;
							var itemASTTail = items.b;
							return A2(
								$elm$core$Maybe$map,
								A2(
									$elm$core$Basics$composeR,
									function (a) {
										return A2($elm$core$List$cons, a, itemASTTail);
									},
									A2(
										$elm$core$Basics$composeR,
										$pablohirafuji$elm_markdown$Markdown$Block$List(model),
										function (a) {
											return A2($elm$core$List$cons, a, astTail);
										})),
								A2($pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph, rawLine, itemAST));
						} else {
							return $elm$core$Maybe$Nothing;
						}
					default:
						break _v0$3;
				}
			} else {
				break _v0$3;
			}
		}
		return $elm$core$Maybe$Nothing;
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elm$regex$Regex$replaceAtMost = _Regex_replaceAtMost;
var $pablohirafuji$elm_markdown$Markdown$Helpers$tabRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\t'));
var $pablohirafuji$elm_markdown$Markdown$Helpers$indentLine = function (indentLength_) {
	return A2(
		$elm$core$Basics$composeR,
		A2(
			$elm$regex$Regex$replace,
			$pablohirafuji$elm_markdown$Markdown$Helpers$tabRegex,
			function (_v0) {
				return '    ';
			}),
		A3(
			$elm$regex$Regex$replaceAtMost,
			1,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString(
					'^ {0,' + ($elm$core$String$fromInt(indentLength_) + '}'))),
			function (_v1) {
				return '';
			}));
};
var $pablohirafuji$elm_markdown$Markdown$Block$resumeIndentedCodeBlock = F2(
	function (codeLine, _v0) {
		var remainBlocks = _v0.a;
		var blankLines = _v0.b;
		if ((remainBlocks.b && (remainBlocks.a.$ === 3)) && (!remainBlocks.a.a.$)) {
			var _v2 = remainBlocks.a;
			var _v3 = _v2.a;
			var codeStr = _v2.b;
			var remainBlocksTail = remainBlocks.b;
			return $elm$core$Maybe$Just(
				function (a) {
					return A2($elm$core$List$cons, a, remainBlocksTail);
				}(
					A2(
						$pablohirafuji$elm_markdown$Markdown$Block$CodeBlock,
						$pablohirafuji$elm_markdown$Markdown$Block$Indented,
						function (a) {
							return a + (codeLine + '\n');
						}(
							_Utils_ap(
								codeStr,
								$elm$core$String$concat(
									A2(
										$elm$core$List$map,
										function (bl) {
											return A2($pablohirafuji$elm_markdown$Markdown$Helpers$indentLine, 4, bl) + '\n';
										},
										blankLines)))))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseIndentedCodeLine = F2(
	function (ast, codeLine) {
		_v0$2:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 3:
						if (!ast.a.a.$) {
							var _v1 = ast.a;
							var _v2 = _v1.a;
							var codeStr = _v1.b;
							var astTail = ast.b;
							return function (a) {
								return A2($elm$core$List$cons, a, astTail);
							}(
								A2($pablohirafuji$elm_markdown$Markdown$Block$CodeBlock, $pablohirafuji$elm_markdown$Markdown$Block$Indented, codeStr + (codeLine + '\n')));
						} else {
							break _v0$2;
						}
					case 0:
						var blankStr = ast.a.a;
						var astTail = ast.b;
						return A2(
							$elm$core$Maybe$withDefault,
							function (a) {
								return A2($elm$core$List$cons, a, ast);
							}(
								A2($pablohirafuji$elm_markdown$Markdown$Block$CodeBlock, $pablohirafuji$elm_markdown$Markdown$Block$Indented, codeLine + '\n')),
							A2(
								$pablohirafuji$elm_markdown$Markdown$Block$resumeIndentedCodeBlock,
								codeLine,
								A2(
									$pablohirafuji$elm_markdown$Markdown$Block$blocksAfterBlankLines,
									astTail,
									_List_fromArray(
										[blankStr]))));
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return A2(
			$elm$core$Maybe$withDefault,
			function (a) {
				return A2($elm$core$List$cons, a, ast);
			}(
				A2($pablohirafuji$elm_markdown$Markdown$Block$CodeBlock, $pablohirafuji$elm_markdown$Markdown$Block$Indented, codeLine + '\n')),
			A2($pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph, codeLine, ast));
	});
var $pablohirafuji$elm_markdown$Markdown$Block$checkIndentedCode = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$Block$parseIndentedCodeLine(ast),
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Maybe$Nothing,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.jO;
							},
							$elm$core$List$head),
						$elm$core$List$head(
							A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$indentedCodeLineRegex, rawLine)))))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$decimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#([0-9]{1,8});'));
var $elm$core$Char$fromCode = _Char_fromCode;
var $pablohirafuji$elm_markdown$Markdown$Entity$isBadEndUnicode = function (_int) {
	var remain_ = A2($elm$core$Basics$modBy, 16, _int);
	var remain = A2($elm$core$Basics$modBy, 131070, _int);
	return (_int >= 131070) && ((((0 <= remain) && (remain <= 15)) || ((65536 <= remain) && (remain <= 65551))) && ((remain_ === 14) || (remain_ === 15)));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$isValidUnicode = function (_int) {
	return (_int === 9) || ((_int === 10) || ((_int === 13) || ((_int === 133) || (((32 <= _int) && (_int <= 126)) || (((160 <= _int) && (_int <= 55295)) || (((57344 <= _int) && (_int <= 64975)) || (((65008 <= _int) && (_int <= 65533)) || ((65536 <= _int) && (_int <= 1114109)))))))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$validUnicode = function (_int) {
	return ($pablohirafuji$elm_markdown$Markdown$Entity$isValidUnicode(_int) && (!$pablohirafuji$elm_markdown$Markdown$Entity$isBadEndUnicode(_int))) ? $elm$core$String$fromChar(
		$elm$core$Char$fromCode(_int)) : $elm$core$String$fromChar(
		$elm$core$Char$fromCode(65533));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceDecimal = function (match) {
	return A2(
		$elm$core$Maybe$withDefault,
		match.iN,
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$Entity$validUnicode,
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$String$toInt,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					$elm$core$List$head(match.jO)))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceDecimals = A2($elm$regex$Regex$replace, $pablohirafuji$elm_markdown$Markdown$Entity$decimalRegex, $pablohirafuji$elm_markdown$Markdown$Entity$replaceDecimal);
var $pablohirafuji$elm_markdown$Markdown$Entity$entitiesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&([0-9a-zA-Z]+);'));
var $pablohirafuji$elm_markdown$Markdown$Entity$entities = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('quot', 34),
			_Utils_Tuple2('amp', 38),
			_Utils_Tuple2('apos', 39),
			_Utils_Tuple2('lt', 60),
			_Utils_Tuple2('gt', 62),
			_Utils_Tuple2('nbsp', 160),
			_Utils_Tuple2('iexcl', 161),
			_Utils_Tuple2('cent', 162),
			_Utils_Tuple2('pound', 163),
			_Utils_Tuple2('curren', 164),
			_Utils_Tuple2('yen', 165),
			_Utils_Tuple2('brvbar', 166),
			_Utils_Tuple2('sect', 167),
			_Utils_Tuple2('uml', 168),
			_Utils_Tuple2('copy', 169),
			_Utils_Tuple2('ordf', 170),
			_Utils_Tuple2('laquo', 171),
			_Utils_Tuple2('not', 172),
			_Utils_Tuple2('shy', 173),
			_Utils_Tuple2('reg', 174),
			_Utils_Tuple2('macr', 175),
			_Utils_Tuple2('deg', 176),
			_Utils_Tuple2('plusmn', 177),
			_Utils_Tuple2('sup2', 178),
			_Utils_Tuple2('sup3', 179),
			_Utils_Tuple2('acute', 180),
			_Utils_Tuple2('micro', 181),
			_Utils_Tuple2('para', 182),
			_Utils_Tuple2('middot', 183),
			_Utils_Tuple2('cedil', 184),
			_Utils_Tuple2('sup1', 185),
			_Utils_Tuple2('ordm', 186),
			_Utils_Tuple2('raquo', 187),
			_Utils_Tuple2('frac14', 188),
			_Utils_Tuple2('frac12', 189),
			_Utils_Tuple2('frac34', 190),
			_Utils_Tuple2('iquest', 191),
			_Utils_Tuple2('Agrave', 192),
			_Utils_Tuple2('Aacute', 193),
			_Utils_Tuple2('Acirc', 194),
			_Utils_Tuple2('Atilde', 195),
			_Utils_Tuple2('Auml', 196),
			_Utils_Tuple2('Aring', 197),
			_Utils_Tuple2('AElig', 198),
			_Utils_Tuple2('Ccedil', 199),
			_Utils_Tuple2('Egrave', 200),
			_Utils_Tuple2('Eacute', 201),
			_Utils_Tuple2('Ecirc', 202),
			_Utils_Tuple2('Euml', 203),
			_Utils_Tuple2('Igrave', 204),
			_Utils_Tuple2('Iacute', 205),
			_Utils_Tuple2('Icirc', 206),
			_Utils_Tuple2('Iuml', 207),
			_Utils_Tuple2('ETH', 208),
			_Utils_Tuple2('Ntilde', 209),
			_Utils_Tuple2('Ograve', 210),
			_Utils_Tuple2('Oacute', 211),
			_Utils_Tuple2('Ocirc', 212),
			_Utils_Tuple2('Otilde', 213),
			_Utils_Tuple2('Ouml', 214),
			_Utils_Tuple2('times', 215),
			_Utils_Tuple2('Oslash', 216),
			_Utils_Tuple2('Ugrave', 217),
			_Utils_Tuple2('Uacute', 218),
			_Utils_Tuple2('Ucirc', 219),
			_Utils_Tuple2('Uuml', 220),
			_Utils_Tuple2('Yacute', 221),
			_Utils_Tuple2('THORN', 222),
			_Utils_Tuple2('szlig', 223),
			_Utils_Tuple2('agrave', 224),
			_Utils_Tuple2('aacute', 225),
			_Utils_Tuple2('acirc', 226),
			_Utils_Tuple2('atilde', 227),
			_Utils_Tuple2('auml', 228),
			_Utils_Tuple2('aring', 229),
			_Utils_Tuple2('aelig', 230),
			_Utils_Tuple2('ccedil', 231),
			_Utils_Tuple2('egrave', 232),
			_Utils_Tuple2('eacute', 233),
			_Utils_Tuple2('ecirc', 234),
			_Utils_Tuple2('euml', 235),
			_Utils_Tuple2('igrave', 236),
			_Utils_Tuple2('iacute', 237),
			_Utils_Tuple2('icirc', 238),
			_Utils_Tuple2('iuml', 239),
			_Utils_Tuple2('eth', 240),
			_Utils_Tuple2('ntilde', 241),
			_Utils_Tuple2('ograve', 242),
			_Utils_Tuple2('oacute', 243),
			_Utils_Tuple2('ocirc', 244),
			_Utils_Tuple2('otilde', 245),
			_Utils_Tuple2('ouml', 246),
			_Utils_Tuple2('divide', 247),
			_Utils_Tuple2('oslash', 248),
			_Utils_Tuple2('ugrave', 249),
			_Utils_Tuple2('uacute', 250),
			_Utils_Tuple2('ucirc', 251),
			_Utils_Tuple2('uuml', 252),
			_Utils_Tuple2('yacute', 253),
			_Utils_Tuple2('thorn', 254),
			_Utils_Tuple2('yuml', 255),
			_Utils_Tuple2('OElig', 338),
			_Utils_Tuple2('oelig', 339),
			_Utils_Tuple2('Scaron', 352),
			_Utils_Tuple2('scaron', 353),
			_Utils_Tuple2('Yuml', 376),
			_Utils_Tuple2('fnof', 402),
			_Utils_Tuple2('circ', 710),
			_Utils_Tuple2('tilde', 732),
			_Utils_Tuple2('Alpha', 913),
			_Utils_Tuple2('Beta', 914),
			_Utils_Tuple2('Gamma', 915),
			_Utils_Tuple2('Delta', 916),
			_Utils_Tuple2('Epsilon', 917),
			_Utils_Tuple2('Zeta', 918),
			_Utils_Tuple2('Eta', 919),
			_Utils_Tuple2('Theta', 920),
			_Utils_Tuple2('Iota', 921),
			_Utils_Tuple2('Kappa', 922),
			_Utils_Tuple2('Lambda', 923),
			_Utils_Tuple2('Mu', 924),
			_Utils_Tuple2('Nu', 925),
			_Utils_Tuple2('Xi', 926),
			_Utils_Tuple2('Omicron', 927),
			_Utils_Tuple2('Pi', 928),
			_Utils_Tuple2('Rho', 929),
			_Utils_Tuple2('Sigma', 931),
			_Utils_Tuple2('Tau', 932),
			_Utils_Tuple2('Upsilon', 933),
			_Utils_Tuple2('Phi', 934),
			_Utils_Tuple2('Chi', 935),
			_Utils_Tuple2('Psi', 936),
			_Utils_Tuple2('Omega', 937),
			_Utils_Tuple2('alpha', 945),
			_Utils_Tuple2('beta', 946),
			_Utils_Tuple2('gamma', 947),
			_Utils_Tuple2('delta', 948),
			_Utils_Tuple2('epsilon', 949),
			_Utils_Tuple2('zeta', 950),
			_Utils_Tuple2('eta', 951),
			_Utils_Tuple2('theta', 952),
			_Utils_Tuple2('iota', 953),
			_Utils_Tuple2('kappa', 954),
			_Utils_Tuple2('lambda', 955),
			_Utils_Tuple2('mu', 956),
			_Utils_Tuple2('nu', 957),
			_Utils_Tuple2('xi', 958),
			_Utils_Tuple2('omicron', 959),
			_Utils_Tuple2('pi', 960),
			_Utils_Tuple2('rho', 961),
			_Utils_Tuple2('sigmaf', 962),
			_Utils_Tuple2('sigma', 963),
			_Utils_Tuple2('tau', 964),
			_Utils_Tuple2('upsilon', 965),
			_Utils_Tuple2('phi', 966),
			_Utils_Tuple2('chi', 967),
			_Utils_Tuple2('psi', 968),
			_Utils_Tuple2('omega', 969),
			_Utils_Tuple2('thetasym', 977),
			_Utils_Tuple2('upsih', 978),
			_Utils_Tuple2('piv', 982),
			_Utils_Tuple2('ensp', 8194),
			_Utils_Tuple2('emsp', 8195),
			_Utils_Tuple2('thinsp', 8201),
			_Utils_Tuple2('zwnj', 8204),
			_Utils_Tuple2('zwj', 8205),
			_Utils_Tuple2('lrm', 8206),
			_Utils_Tuple2('rlm', 8207),
			_Utils_Tuple2('ndash', 8211),
			_Utils_Tuple2('mdash', 8212),
			_Utils_Tuple2('lsquo', 8216),
			_Utils_Tuple2('rsquo', 8217),
			_Utils_Tuple2('sbquo', 8218),
			_Utils_Tuple2('ldquo', 8220),
			_Utils_Tuple2('rdquo', 8221),
			_Utils_Tuple2('bdquo', 8222),
			_Utils_Tuple2('dagger', 8224),
			_Utils_Tuple2('Dagger', 8225),
			_Utils_Tuple2('bull', 8226),
			_Utils_Tuple2('hellip', 8230),
			_Utils_Tuple2('permil', 8240),
			_Utils_Tuple2('prime', 8242),
			_Utils_Tuple2('Prime', 8243),
			_Utils_Tuple2('lsaquo', 8249),
			_Utils_Tuple2('rsaquo', 8250),
			_Utils_Tuple2('oline', 8254),
			_Utils_Tuple2('frasl', 8260),
			_Utils_Tuple2('euro', 8364),
			_Utils_Tuple2('image', 8465),
			_Utils_Tuple2('weierp', 8472),
			_Utils_Tuple2('real', 8476),
			_Utils_Tuple2('trade', 8482),
			_Utils_Tuple2('alefsym', 8501),
			_Utils_Tuple2('larr', 8592),
			_Utils_Tuple2('uarr', 8593),
			_Utils_Tuple2('rarr', 8594),
			_Utils_Tuple2('darr', 8595),
			_Utils_Tuple2('harr', 8596),
			_Utils_Tuple2('crarr', 8629),
			_Utils_Tuple2('lArr', 8656),
			_Utils_Tuple2('uArr', 8657),
			_Utils_Tuple2('rArr', 8658),
			_Utils_Tuple2('dArr', 8659),
			_Utils_Tuple2('hArr', 8660),
			_Utils_Tuple2('forall', 8704),
			_Utils_Tuple2('part', 8706),
			_Utils_Tuple2('exist', 8707),
			_Utils_Tuple2('empty', 8709),
			_Utils_Tuple2('nabla', 8711),
			_Utils_Tuple2('isin', 8712),
			_Utils_Tuple2('notin', 8713),
			_Utils_Tuple2('ni', 8715),
			_Utils_Tuple2('prod', 8719),
			_Utils_Tuple2('sum', 8721),
			_Utils_Tuple2('minus', 8722),
			_Utils_Tuple2('lowast', 8727),
			_Utils_Tuple2('radic', 8730),
			_Utils_Tuple2('prop', 8733),
			_Utils_Tuple2('infin', 8734),
			_Utils_Tuple2('ang', 8736),
			_Utils_Tuple2('and', 8743),
			_Utils_Tuple2('or', 8744),
			_Utils_Tuple2('cap', 8745),
			_Utils_Tuple2('cup', 8746),
			_Utils_Tuple2('int', 8747),
			_Utils_Tuple2('there4', 8756),
			_Utils_Tuple2('sim', 8764),
			_Utils_Tuple2('cong', 8773),
			_Utils_Tuple2('asymp', 8776),
			_Utils_Tuple2('ne', 8800),
			_Utils_Tuple2('equiv', 8801),
			_Utils_Tuple2('le', 8804),
			_Utils_Tuple2('ge', 8805),
			_Utils_Tuple2('sub', 8834),
			_Utils_Tuple2('sup', 8835),
			_Utils_Tuple2('nsub', 8836),
			_Utils_Tuple2('sube', 8838),
			_Utils_Tuple2('supe', 8839),
			_Utils_Tuple2('oplus', 8853),
			_Utils_Tuple2('otimes', 8855),
			_Utils_Tuple2('perp', 8869),
			_Utils_Tuple2('sdot', 8901),
			_Utils_Tuple2('lceil', 8968),
			_Utils_Tuple2('rceil', 8969),
			_Utils_Tuple2('lfloor', 8970),
			_Utils_Tuple2('rfloor', 8971),
			_Utils_Tuple2('lang', 9001),
			_Utils_Tuple2('rang', 9002),
			_Utils_Tuple2('loz', 9674),
			_Utils_Tuple2('spades', 9824),
			_Utils_Tuple2('clubs', 9827),
			_Utils_Tuple2('hearts', 9829),
			_Utils_Tuple2('diams', 9830)
		]));
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceEntity = function (match) {
	return A2(
		$elm$core$Maybe$withDefault,
		match.iN,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			A2(
				$elm$core$Maybe$andThen,
				function (a) {
					return A2($elm$core$Dict$get, a, $pablohirafuji$elm_markdown$Markdown$Entity$entities);
				},
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					$elm$core$List$head(match.jO)))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceEntities = A2($elm$regex$Regex$replace, $pablohirafuji$elm_markdown$Markdown$Entity$entitiesRegex, $pablohirafuji$elm_markdown$Markdown$Entity$replaceEntity);
var $pablohirafuji$elm_markdown$Markdown$Helpers$escapableRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\+)([!\"#$%&\\\'()*+,./:;<=>?@[\\\\\\]^_`{|}~-])'));
var $pablohirafuji$elm_markdown$Markdown$Helpers$replaceEscapable = A2(
	$elm$regex$Regex$replace,
	$pablohirafuji$elm_markdown$Markdown$Helpers$escapableRegex,
	function (regexMatch) {
		var _v0 = regexMatch.jO;
		if (((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) {
			var backslashes = _v0.a.a;
			var _v1 = _v0.b;
			var escapedStr = _v1.a.a;
			return _Utils_ap(
				A2(
					$elm$core$String$repeat,
					($elm$core$String$length(backslashes) / 2) | 0,
					'\\'),
				escapedStr);
		} else {
			return regexMatch.iN;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Entity$hexadecimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#[Xx]([0-9a-fA-F]{1,8});'));
var $pablohirafuji$elm_markdown$Markdown$Entity$hexToInt = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toLower,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toList,
		A2(
			$elm$core$List$foldl,
			F2(
				function (hexDigit, _int) {
					return ((_int * 16) + A2(
						$elm$core$Basics$modBy,
						39,
						$elm$core$Char$toCode(hexDigit))) - 9;
				}),
			0)));
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceHexadecimal = function (match) {
	return A2(
		$elm$core$Maybe$withDefault,
		match.iN,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $pablohirafuji$elm_markdown$Markdown$Entity$hexToInt, $pablohirafuji$elm_markdown$Markdown$Entity$validUnicode),
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Maybe$Nothing,
				$elm$core$List$head(match.jO))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceHexadecimals = A2($elm$regex$Regex$replace, $pablohirafuji$elm_markdown$Markdown$Entity$hexadecimalRegex, $pablohirafuji$elm_markdown$Markdown$Entity$replaceHexadecimal);
var $pablohirafuji$elm_markdown$Markdown$Helpers$formatStr = function (str) {
	return $pablohirafuji$elm_markdown$Markdown$Entity$replaceHexadecimals(
		$pablohirafuji$elm_markdown$Markdown$Entity$replaceDecimals(
			$pablohirafuji$elm_markdown$Markdown$Entity$replaceEntities(
				$pablohirafuji$elm_markdown$Markdown$Helpers$replaceEscapable(str))));
};
var $elm$core$String$words = _String_words;
var $pablohirafuji$elm_markdown$Markdown$Block$extractOpenCodeFenceRM = function (match) {
	var _v0 = match.jO;
	if (((_v0.b && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) {
		var maybeIndent = _v0.a;
		var _v1 = _v0.b;
		var fence = _v1.a.a;
		var _v2 = _v1.b;
		var maybeLanguage = _v2.a;
		return $elm$core$Maybe$Just(
			A2(
				$pablohirafuji$elm_markdown$Markdown$Block$Fenced,
				true,
				{
					d1: A2($elm$core$String$left, 1, fence),
					d2: $elm$core$String$length(fence),
					S: A2(
						$elm$core$Maybe$withDefault,
						0,
						A2($elm$core$Maybe$map, $elm$core$String$length, maybeIndent)),
					eq: A2(
						$elm$core$Maybe$map,
						$pablohirafuji$elm_markdown$Markdown$Helpers$formatStr,
						A2(
							$elm$core$Maybe$andThen,
							function (lang) {
								return (lang === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(lang);
							},
							$elm$core$List$head(
								A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2($elm$core$Maybe$map, $elm$core$String$words, maybeLanguage)))))
				}));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$openCodeFenceLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^( {0,3})(`{3,}(?!.*`)|~{3,}(?!.*~))(.*)$'));
var $pablohirafuji$elm_markdown$Markdown$Block$checkOpenCodeFenceLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			function (a) {
				return A2($elm$core$List$cons, a, ast);
			},
			A2(
				$elm$core$Maybe$map,
				function (f) {
					return A2($pablohirafuji$elm_markdown$Markdown$Block$CodeBlock, f, '');
				},
				A2(
					$elm$core$Maybe$andThen,
					$pablohirafuji$elm_markdown$Markdown$Block$extractOpenCodeFenceRM,
					$elm$core$List$head(
						A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$openCodeFenceLineRegex, rawLine))))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$Ordered = function (a) {
	return {$: 1, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Block$Unordered = {$: 0};
var $pablohirafuji$elm_markdown$Markdown$Block$extractOrderedListRM = function (match) {
	var _v0 = match.jO;
	if (((((((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) && (!_v0.b.b.a.$)) && _v0.b.b.b.b) && _v0.b.b.b.b.b) {
		var indentString = _v0.a.a;
		var _v1 = _v0.b;
		var start = _v1.a.a;
		var _v2 = _v1.b;
		var delimiter = _v2.a.a;
		var _v3 = _v2.b;
		var maybeIndentSpace = _v3.a;
		var _v4 = _v3.b;
		var maybeRawLine = _v4.a;
		return $elm$core$Maybe$Just(
			_Utils_Tuple3(
				{
					h9: delimiter,
					S: $elm$core$String$length(indentString) + 1,
					a7: false,
					cV: A2(
						$elm$core$Maybe$withDefault,
						$pablohirafuji$elm_markdown$Markdown$Block$Unordered,
						A2(
							$elm$core$Maybe$map,
							$pablohirafuji$elm_markdown$Markdown$Block$Ordered,
							$elm$core$String$toInt(start)))
				},
				A2($elm$core$Maybe$withDefault, '', maybeIndentSpace),
				A2($elm$core$Maybe$withDefault, '', maybeRawLine)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$orderedListLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^( *(\\d{1,9})([.)])( {0,4}))(?:[ \\t](.*))?$'));
var $pablohirafuji$elm_markdown$Markdown$Block$checkOrderedListLine = function (rawLine) {
	return A2(
		$elm$core$Result$fromMaybe,
		rawLine,
		A2(
			$elm$core$Maybe$andThen,
			$pablohirafuji$elm_markdown$Markdown$Block$extractOrderedListRM,
			$elm$core$List$head(
				A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$orderedListLineRegex, rawLine))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$extractSetextHeadingRM = function (match) {
	var _v0 = match.jO;
	if (_v0.b && (!_v0.a.$)) {
		var delimiter = _v0.a.a;
		return A2($elm$core$String$startsWith, '=', delimiter) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(1, delimiter)) : $elm$core$Maybe$Just(
			_Utils_Tuple2(2, delimiter));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$parseSetextHeadingLine = F3(
	function (rawLine, ast, _v0) {
		var lvl = _v0.a;
		var delimiter = _v0.b;
		if (ast.b && (ast.a.$ === 4)) {
			var _v2 = ast.a;
			var rawText = _v2.a;
			var astTail = ast.b;
			return $elm$core$Maybe$Just(
				A2(
					$elm$core$List$cons,
					A3($pablohirafuji$elm_markdown$Markdown$Block$Heading, rawText, lvl, _List_Nil),
					astTail));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$setextHeadingLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(=+|-+)[ \\t]*$'));
var $pablohirafuji$elm_markdown$Markdown$Block$checkSetextHeadingLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$andThen,
			A2($pablohirafuji$elm_markdown$Markdown$Block$parseSetextHeadingLine, rawLine, ast),
			A2(
				$elm$core$Maybe$andThen,
				$pablohirafuji$elm_markdown$Markdown$Block$extractSetextHeadingRM,
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$setextHeadingLineRegex, rawLine)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$ThematicBreak = {$: 1};
var $pablohirafuji$elm_markdown$Markdown$Block$thematicBreakLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(?:' + ('(?:\\*[ \\t]*){3,}' + ('|(?:_[ \\t]*){3,}' + '|(?:-[ \\t]*){3,})[ \\t]*$'))));
var $pablohirafuji$elm_markdown$Markdown$Block$checkThematicBreakLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			function (_v1) {
				return A2($elm$core$List$cons, $pablohirafuji$elm_markdown$Markdown$Block$ThematicBreak, ast);
			},
			$elm$core$List$head(
				A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$thematicBreakLineRegex, rawLine))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$extractUnorderedListRM = function (match) {
	var _v0 = match.jO;
	if ((((((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) && _v0.b.b.b.b) && (!_v0.b.b.b.b.b)) {
		var indentString = _v0.a.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var _v2 = _v1.b;
		var maybeIndentSpace = _v2.a;
		var _v3 = _v2.b;
		var maybeRawLine = _v3.a;
		return $elm$core$Maybe$Just(
			_Utils_Tuple3(
				{
					h9: delimiter,
					S: $elm$core$String$length(indentString) + 1,
					a7: false,
					cV: $pablohirafuji$elm_markdown$Markdown$Block$Unordered
				},
				A2($elm$core$Maybe$withDefault, '', maybeIndentSpace),
				A2($elm$core$Maybe$withDefault, '', maybeRawLine)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$unorderedListLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^( *([\\*\\-\\+])( {0,4}))(?:[ \\t](.*))?$'));
var $pablohirafuji$elm_markdown$Markdown$Block$checkUnorderedListLine = function (rawLine) {
	return A2(
		$elm$core$Result$fromMaybe,
		rawLine,
		A2(
			$elm$core$Maybe$andThen,
			$pablohirafuji$elm_markdown$Markdown$Block$extractUnorderedListRM,
			$elm$core$List$head(
				A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$unorderedListLineRegex, rawLine))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$closeCodeFenceLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(`{3,}|~{3,})\\s*$'));
var $pablohirafuji$elm_markdown$Markdown$Block$isCloseFenceLineHelp = F2(
	function (fence, match) {
		var _v0 = match.jO;
		if (_v0.b && (!_v0.a.$)) {
			var fenceStr = _v0.a.a;
			return (_Utils_cmp(
				$elm$core$String$length(fenceStr),
				fence.d2) > -1) && _Utils_eq(
				A2($elm$core$String$left, 1, fenceStr),
				fence.d1);
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$isCloseFenceLine = function (fence) {
	return A2(
		$elm$core$Basics$composeR,
		A2($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$closeCodeFenceLineRegex),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(
					$pablohirafuji$elm_markdown$Markdown$Block$isCloseFenceLineHelp(fence)),
				$elm$core$Maybe$withDefault(false))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$continueOrCloseCodeFence = F3(
	function (fence, previousCode, rawLine) {
		return A2($pablohirafuji$elm_markdown$Markdown$Block$isCloseFenceLine, fence, rawLine) ? A2(
			$pablohirafuji$elm_markdown$Markdown$Block$CodeBlock,
			A2($pablohirafuji$elm_markdown$Markdown$Block$Fenced, false, fence),
			previousCode) : A2(
			$pablohirafuji$elm_markdown$Markdown$Block$CodeBlock,
			A2($pablohirafuji$elm_markdown$Markdown$Block$Fenced, true, fence),
			previousCode + (A2($pablohirafuji$elm_markdown$Markdown$Helpers$indentLine, fence.S, rawLine) + '\n'));
	});
var $pablohirafuji$elm_markdown$Markdown$Helpers$ifError = F2(
	function (_function, result) {
		if (!result.$) {
			return result;
		} else {
			var err = result.a;
			return _function(err);
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Helpers$initSpacesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ +'));
var $pablohirafuji$elm_markdown$Markdown$Helpers$indentLength = A2(
	$elm$core$Basics$composeR,
	A2(
		$elm$regex$Regex$replace,
		$pablohirafuji$elm_markdown$Markdown$Helpers$tabRegex,
		function (_v0) {
			return '    ';
		}),
	A2(
		$elm$core$Basics$composeR,
		A2($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Helpers$initSpacesRegex),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.iN;
						},
						$elm$core$String$length)),
				$elm$core$Maybe$withDefault(0)))));
var $pablohirafuji$elm_markdown$Markdown$Block$isBlankLineLast = function (items) {
	isBlankLineLast:
	while (true) {
		if (!items.b) {
			return false;
		} else {
			var item = items.a;
			var itemsTail = items.b;
			_v1$3:
			while (true) {
				if (item.b) {
					switch (item.a.$) {
						case 0:
							if (!item.b.b) {
								return false;
							} else {
								return true;
							}
						case 6:
							var _v2 = item.a;
							var items_ = _v2.b;
							var $temp$items = items_;
							items = $temp$items;
							continue isBlankLineLast;
						default:
							break _v1$3;
					}
				} else {
					break _v1$3;
				}
			}
			return false;
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$parseTextLine = F2(
	function (rawLine, ast) {
		return A2(
			$elm$core$Maybe$withDefault,
			A2(
				$elm$core$List$cons,
				A2(
					$pablohirafuji$elm_markdown$Markdown$Block$Paragraph,
					$pablohirafuji$elm_markdown$Markdown$Block$formatParagraphLine(rawLine),
					_List_Nil),
				ast),
			A2($pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph, rawLine, ast));
	});
var $pablohirafuji$elm_markdown$Markdown$Block$checkBlockQuote = function (_v16) {
	var rawLine = _v16.a;
	var ast = _v16.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$Block$parseBlockQuoteLine(ast),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.jO;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$head,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault($elm$core$Maybe$Nothing),
							$elm$core$Maybe$withDefault('')))),
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$blockQuoteLineRegex, rawLine)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$checkListLine = function (_v15) {
	var rawLine = _v15.a;
	var ast = _v15.b;
	return A2(
		$elm$core$Result$mapError,
		function (e) {
			return _Utils_Tuple2(e, ast);
		},
		A2(
			$elm$core$Result$map,
			A2($pablohirafuji$elm_markdown$Markdown$Block$parseListLine, rawLine, ast),
			A2(
				$elm$core$Result$map,
				$pablohirafuji$elm_markdown$Markdown$Block$calcListIndentLength,
				A2(
					$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
					$pablohirafuji$elm_markdown$Markdown$Block$checkUnorderedListLine,
					$pablohirafuji$elm_markdown$Markdown$Block$checkOrderedListLine(rawLine)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$incorporateLine = F2(
	function (rawLine, ast) {
		_v11$2:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 3:
						if ((ast.a.a.$ === 1) && ast.a.a.a) {
							var _v12 = ast.a;
							var _v13 = _v12.a;
							var fence = _v13.b;
							var code = _v12.b;
							var astTail = ast.b;
							return function (a) {
								return A2($elm$core$List$cons, a, astTail);
							}(
								A3($pablohirafuji$elm_markdown$Markdown$Block$continueOrCloseCodeFence, fence, code, rawLine));
						} else {
							break _v11$2;
						}
					case 6:
						var _v14 = ast.a;
						var model = _v14.a;
						var items = _v14.b;
						var astTail = ast.b;
						return (_Utils_cmp(
							$pablohirafuji$elm_markdown$Markdown$Helpers$indentLength(rawLine),
							model.S) > -1) ? A5($pablohirafuji$elm_markdown$Markdown$Block$parseIndentedListLine, rawLine, model, items, ast, astTail) : A2(
							$elm$core$Result$withDefault,
							A2($pablohirafuji$elm_markdown$Markdown$Block$parseTextLine, rawLine, ast),
							A2(
								$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
								$pablohirafuji$elm_markdown$Markdown$Block$checkBlockQuote,
								A2(
									$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
									$pablohirafuji$elm_markdown$Markdown$Block$checkATXHeadingLine,
									A2(
										$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
										$pablohirafuji$elm_markdown$Markdown$Block$checkSetextHeadingLine,
										A2(
											$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
											$pablohirafuji$elm_markdown$Markdown$Block$checkOpenCodeFenceLine,
											A2(
												$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
												$pablohirafuji$elm_markdown$Markdown$Block$checkIndentedCode,
												A2(
													$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
													$pablohirafuji$elm_markdown$Markdown$Block$checkBlankLine,
													A2(
														$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
														$pablohirafuji$elm_markdown$Markdown$Block$checkListLine,
														$pablohirafuji$elm_markdown$Markdown$Block$checkThematicBreakLine(
															_Utils_Tuple2(rawLine, ast))))))))));
					default:
						break _v11$2;
				}
			} else {
				break _v11$2;
			}
		}
		return A2($pablohirafuji$elm_markdown$Markdown$Block$parseRawLine, rawLine, ast);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseBlockQuoteLine = F2(
	function (ast, rawLine) {
		if (ast.b && (ast.a.$ === 5)) {
			var bqAST = ast.a.a;
			var astTail = ast.b;
			return function (a) {
				return A2($elm$core$List$cons, a, astTail);
			}(
				$pablohirafuji$elm_markdown$Markdown$Block$BlockQuote(
					A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, rawLine, bqAST)));
		} else {
			return function (a) {
				return A2($elm$core$List$cons, a, ast);
			}(
				$pablohirafuji$elm_markdown$Markdown$Block$BlockQuote(
					A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, rawLine, _List_Nil)));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseIndentedListLine = F5(
	function (rawLine, model, items, ast, astTail) {
		if (!items.b) {
			return function (a) {
				return A2($elm$core$List$cons, a, astTail);
			}(
				A2(
					$pablohirafuji$elm_markdown$Markdown$Block$List,
					model,
					function (a) {
						return A2($elm$core$List$cons, a, _List_Nil);
					}(
						function (a) {
							return A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, a, _List_Nil);
						}(
							A2($pablohirafuji$elm_markdown$Markdown$Helpers$indentLine, model.S, rawLine)))));
		} else {
			var item = items.a;
			var itemsTail = items.b;
			var indentedRawLine = A2($pablohirafuji$elm_markdown$Markdown$Helpers$indentLine, model.S, rawLine);
			var updateList = function (model_) {
				return function (a) {
					return A2($elm$core$List$cons, a, astTail);
				}(
					A2(
						$pablohirafuji$elm_markdown$Markdown$Block$List,
						model_,
						function (a) {
							return A2($elm$core$List$cons, a, itemsTail);
						}(
							A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, indentedRawLine, item))));
			};
			_v7$3:
			while (true) {
				if (item.b) {
					switch (item.a.$) {
						case 0:
							if (!item.b.b) {
								return updateList(model);
							} else {
								var itemTail = item.b;
								return A2(
									$elm$core$List$all,
									function (block) {
										if (!block.$) {
											return true;
										} else {
											return false;
										}
									},
									itemTail) ? A2($pablohirafuji$elm_markdown$Markdown$Block$parseRawLine, rawLine, ast) : updateList(
									_Utils_update(
										model,
										{a7: true}));
							}
						case 6:
							var _v9 = item.a;
							var model_ = _v9.a;
							var items_ = _v9.b;
							var itemTail = item.b;
							return (_Utils_cmp(
								$pablohirafuji$elm_markdown$Markdown$Helpers$indentLength(indentedRawLine),
								model_.S) > -1) ? updateList(model) : ($pablohirafuji$elm_markdown$Markdown$Block$isBlankLineLast(items_) ? updateList(
								_Utils_update(
									model,
									{a7: true})) : updateList(model));
						default:
							break _v7$3;
					}
				} else {
					break _v7$3;
				}
			}
			return updateList(model);
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseListLine = F3(
	function (rawLine, ast, _v0) {
		var listBlock = _v0.a;
		var listRawLine = _v0.b;
		var parsedRawLine = A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, listRawLine, _List_Nil);
		var newList = A2(
			$elm$core$List$cons,
			A2(
				$pablohirafuji$elm_markdown$Markdown$Block$List,
				listBlock,
				_List_fromArray(
					[parsedRawLine])),
			ast);
		_v1$2:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 6:
						var _v2 = ast.a;
						var model = _v2.a;
						var items = _v2.b;
						var astTail = ast.b;
						return _Utils_eq(listBlock.h9, model.h9) ? function (a) {
							return A2($elm$core$List$cons, a, astTail);
						}(
							A2(
								$pablohirafuji$elm_markdown$Markdown$Block$List,
								_Utils_update(
									model,
									{
										S: listBlock.S,
										a7: model.a7 || $pablohirafuji$elm_markdown$Markdown$Block$isBlankLineLast(items)
									}),
								A2($elm$core$List$cons, parsedRawLine, items))) : newList;
					case 4:
						var _v3 = ast.a;
						var rawText = _v3.a;
						var inlines = _v3.b;
						var astTail = ast.b;
						if ((parsedRawLine.b && (!parsedRawLine.a.$)) && (!parsedRawLine.b.b)) {
							return A2(
								$elm$core$List$cons,
								A2($pablohirafuji$elm_markdown$Markdown$Block$addToParagraph, rawText, rawLine),
								astTail);
						} else {
							var _v5 = listBlock.cV;
							if (_v5.$ === 1) {
								if (_v5.a === 1) {
									return newList;
								} else {
									var _int = _v5.a;
									return A2(
										$elm$core$List$cons,
										A2($pablohirafuji$elm_markdown$Markdown$Block$addToParagraph, rawText, rawLine),
										astTail);
								}
							} else {
								return newList;
							}
						}
					default:
						break _v1$2;
				}
			} else {
				break _v1$2;
			}
		}
		return newList;
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseRawLine = F2(
	function (rawLine, ast) {
		return A2(
			$elm$core$Result$withDefault,
			A2($pablohirafuji$elm_markdown$Markdown$Block$parseTextLine, rawLine, ast),
			A2(
				$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
				$pablohirafuji$elm_markdown$Markdown$Block$checkListLine,
				A2(
					$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
					$pablohirafuji$elm_markdown$Markdown$Block$checkThematicBreakLine,
					A2(
						$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
						$pablohirafuji$elm_markdown$Markdown$Block$checkBlockQuote,
						A2(
							$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
							$pablohirafuji$elm_markdown$Markdown$Block$checkATXHeadingLine,
							A2(
								$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
								$pablohirafuji$elm_markdown$Markdown$Block$checkSetextHeadingLine,
								A2(
									$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
									$pablohirafuji$elm_markdown$Markdown$Block$checkOpenCodeFenceLine,
									A2(
										$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
										$pablohirafuji$elm_markdown$Markdown$Block$checkIndentedCode,
										$pablohirafuji$elm_markdown$Markdown$Block$checkBlankLine(
											_Utils_Tuple2(rawLine, ast))))))))));
	});
var $pablohirafuji$elm_markdown$Markdown$Block$incorporateLines = F2(
	function (rawLines, ast) {
		if (!rawLines.b) {
			return ast;
		} else {
			var rawLine = rawLines.a;
			var rawLinesTail = rawLines.b;
			return A2(
				$pablohirafuji$elm_markdown$Markdown$Block$incorporateLines,
				rawLinesTail,
				A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, rawLine, ast));
		}
	});
var $elm$core$String$lines = _String_lines;
var $pablohirafuji$elm_markdown$Markdown$Block$Custom = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$PlainInlines = function (a) {
	return {$: 7, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Config$Sanitize = function (a) {
	return {$: 1, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Config$defaultAllowedHtmlAttributes = _List_fromArray(
	['name', 'class']);
var $pablohirafuji$elm_markdown$Markdown$Config$defaultAllowedHtmlElements = _List_fromArray(
	['address', 'article', 'aside', 'b', 'blockquote', 'br', 'caption', 'center', 'cite', 'code', 'col', 'colgroup', 'dd', 'details', 'div', 'dl', 'dt', 'figcaption', 'figure', 'footer', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'i', 'legend', 'li', 'menu', 'menuitem', 'nav', 'ol', 'optgroup', 'option', 'p', 'pre', 'section', 'strike', 'summary', 'small', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'ul']);
var $pablohirafuji$elm_markdown$Markdown$Config$defaultSanitizeOptions = {fS: $pablohirafuji$elm_markdown$Markdown$Config$defaultAllowedHtmlAttributes, fT: $pablohirafuji$elm_markdown$Markdown$Config$defaultAllowedHtmlElements};
var $pablohirafuji$elm_markdown$Markdown$Config$defaultOptions = {
	g2: $pablohirafuji$elm_markdown$Markdown$Config$Sanitize($pablohirafuji$elm_markdown$Markdown$Config$defaultSanitizeOptions),
	hk: false
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$initParser = F3(
	function (options, refs, rawText) {
		return {e: _List_Nil, i8: options, ac: rawText, jq: refs, v: _List_Nil};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$CodeInline = function (a) {
	return {$: 2, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Inline$Emphasis = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$HardLineBreak = {$: 1};
var $pablohirafuji$elm_markdown$Markdown$Inline$HtmlInline = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$Image = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$Link = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$Text = function (a) {
	return {$: 0, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$matchToInline = function (_v0) {
	var match = _v0;
	var _v1 = match.cV;
	switch (_v1.$) {
		case 0:
			return $pablohirafuji$elm_markdown$Markdown$Inline$Text(match.fu);
		case 1:
			return $pablohirafuji$elm_markdown$Markdown$Inline$HardLineBreak;
		case 2:
			return $pablohirafuji$elm_markdown$Markdown$Inline$CodeInline(match.fu);
		case 3:
			var _v2 = _v1.a;
			var text = _v2.a;
			var url = _v2.b;
			return A3(
				$pablohirafuji$elm_markdown$Markdown$Inline$Link,
				url,
				$elm$core$Maybe$Nothing,
				_List_fromArray(
					[
						$pablohirafuji$elm_markdown$Markdown$Inline$Text(text)
					]));
		case 4:
			var _v3 = _v1.a;
			var url = _v3.a;
			var maybeTitle = _v3.b;
			return A3(
				$pablohirafuji$elm_markdown$Markdown$Inline$Link,
				url,
				maybeTitle,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(match.e));
		case 5:
			var _v4 = _v1.a;
			var url = _v4.a;
			var maybeTitle = _v4.b;
			return A3(
				$pablohirafuji$elm_markdown$Markdown$Inline$Image,
				url,
				maybeTitle,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(match.e));
		case 6:
			var model = _v1.a;
			return A3(
				$pablohirafuji$elm_markdown$Markdown$Inline$HtmlInline,
				model.ft,
				model.fW,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(match.e));
		default:
			var length = _v1.a;
			return A2(
				$pablohirafuji$elm_markdown$Markdown$Inline$Emphasis,
				length,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(match.e));
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines = function (matches) {
	return A2($elm$core$List$map, $pablohirafuji$elm_markdown$Markdown$InlineParser$matchToInline, matches);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$Match = $elm$core$Basics$identity;
var $pablohirafuji$elm_markdown$Markdown$InlineParser$prepareChildMatch = F2(
	function (parentMatch, childMatch) {
		return _Utils_update(
			childMatch,
			{f9: childMatch.f9 - parentMatch.aI, fn: childMatch.fn - parentMatch.aI, cb: childMatch.cb - parentMatch.aI, aI: childMatch.aI - parentMatch.aI});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$addChild = F2(
	function (parentMatch, childMatch) {
		return _Utils_update(
			parentMatch,
			{
				e: A2(
					$elm$core$List$cons,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$prepareChildMatch, parentMatch, childMatch),
					parentMatch.e)
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatch = F2(
	function (_v0, matches) {
		var match = _v0;
		if (!matches.b) {
			return _List_fromArray(
				[match]);
		} else {
			var prevMatch = matches.a;
			var matchesTail = matches.b;
			return (_Utils_cmp(prevMatch.f9, match.fn) < 1) ? A2($elm$core$List$cons, match, matches) : (((_Utils_cmp(prevMatch.fn, match.fn) < 0) && (_Utils_cmp(prevMatch.f9, match.f9) > 0)) ? A2(
				$elm$core$List$cons,
				A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addChild, prevMatch, match),
				matchesTail) : matches);
		}
	});
var $elm$core$List$sortBy = _List_sortBy;
function $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches() {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$sortBy(
			function (_v0) {
				var match = _v0;
				return match.fn;
			}),
		A2(
			$elm$core$Basics$composeR,
			A2($elm$core$List$foldl, $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatch, _List_Nil),
			$elm$core$List$map(
				function (_v1) {
					var match = _v1;
					return _Utils_update(
						match,
						{
							e: $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches()(match.e)
						});
				})));
}
var $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatches = $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches();
$pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches = function () {
	return $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatches;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeParserMatches = function (model) {
	return _Utils_update(
		model,
		{
			e: $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatches(model.e)
		});
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$NormalType = {$: 0};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch = function (text) {
	return {
		f9: 0,
		e: _List_Nil,
		fn: 0,
		fu: $pablohirafuji$elm_markdown$Markdown$Helpers$formatStr(text),
		cb: 0,
		aI: 0,
		cV: $pablohirafuji$elm_markdown$Markdown$InlineParser$NormalType
	};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatch = F3(
	function (rawText, _v2, parsedMatches) {
		var matchModel = _v2;
		var updtMatch = _Utils_update(
			matchModel,
			{
				e: A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatches, matchModel.fu, _List_Nil, matchModel.e)
			});
		if (!parsedMatches.b) {
			var finalStr = A2($elm$core$String$dropLeft, matchModel.f9, rawText);
			return $elm$core$String$isEmpty(finalStr) ? _List_fromArray(
				[updtMatch]) : _List_fromArray(
				[
					updtMatch,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch(finalStr)
				]);
		} else {
			var matchHead = parsedMatches.a;
			var matchesTail = parsedMatches.b;
			return _Utils_eq(matchHead.cV, $pablohirafuji$elm_markdown$Markdown$InlineParser$NormalType) ? A2($elm$core$List$cons, updtMatch, parsedMatches) : (_Utils_eq(matchModel.f9, matchHead.fn) ? A2($elm$core$List$cons, updtMatch, parsedMatches) : ((_Utils_cmp(matchModel.f9, matchHead.fn) < 0) ? A2(
				$elm$core$List$cons,
				updtMatch,
				A2(
					$elm$core$List$cons,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch(
						A3($elm$core$String$slice, matchModel.f9, matchHead.fn, rawText)),
					parsedMatches)) : parsedMatches));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatches = F3(
	function (rawText, parsedMatches, matches) {
		parseTextMatches:
		while (true) {
			if (!matches.b) {
				if (!parsedMatches.b) {
					return $elm$core$String$isEmpty(rawText) ? _List_Nil : _List_fromArray(
						[
							$pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch(rawText)
						]);
				} else {
					var matchModel = parsedMatches.a;
					return (matchModel.fn > 0) ? A2(
						$elm$core$List$cons,
						$pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch(
							A2($elm$core$String$left, matchModel.fn, rawText)),
						parsedMatches) : parsedMatches;
				}
			} else {
				var match = matches.a;
				var matchesTail = matches.b;
				var $temp$rawText = rawText,
					$temp$parsedMatches = A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatch, rawText, match, parsedMatches),
					$temp$matches = matchesTail;
				rawText = $temp$rawText;
				parsedMatches = $temp$parsedMatches;
				matches = $temp$matches;
				continue parseTextMatches;
			}
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$parseText = function (model) {
	return _Utils_update(
		model,
		{
			e: A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatches, model.ac, _List_Nil, model.e)
		});
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\<)'));
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken = function (a) {
	return {$: 3, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Helpers$isEven = function (_int) {
	return !A2($elm$core$Basics$modBy, 2, _int);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken = function (regMatch) {
	var _v0 = regMatch.jO;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{
				iz: regMatch.iz + backslashesLength,
				db: 1,
				k: $pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken('<')
			}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\>)'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$RightAngleBracket = function (a) {
	return {$: 4, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken = function (regMatch) {
	var _v0 = regMatch.jO;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				iz: regMatch.iz + backslashesLength,
				db: 1,
				k: $pablohirafuji$elm_markdown$Markdown$InlineParser$RightAngleBracket(
					!$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength))
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^*])?(\\*+)([^*])?'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$EmphasisToken = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$punctuationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[!-#%-\\*,-/:;\\?@\\[-\\]_\\{\\}]'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$containPunctuation = $elm$regex$Regex$contains($pablohirafuji$elm_markdown$Markdown$InlineParser$punctuationRegex);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$spaceRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\s'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$containSpace = $elm$regex$Regex$contains($pablohirafuji$elm_markdown$Markdown$InlineParser$spaceRegex);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$charFringeRank = function (_char) {
	var string = $elm$core$String$fromChar(_char);
	return $pablohirafuji$elm_markdown$Markdown$InlineParser$containSpace(string) ? 0 : ($pablohirafuji$elm_markdown$Markdown$InlineParser$containPunctuation(string) ? 1 : 2);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$maybeCharFringeRank = function (maybeChar) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2($elm$core$Maybe$map, $pablohirafuji$elm_markdown$Markdown$InlineParser$charFringeRank, maybeChar));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$getFringeRank = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$String$uncons,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($elm$core$Tuple$first),
				$pablohirafuji$elm_markdown$Markdown$InlineParser$maybeCharFringeRank))),
	$elm$core$Maybe$withDefault(0));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken = F3(
	function (_char, rawText, regMatch) {
		var _v0 = regMatch.jO;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) && _v0.b.b.b.b) {
			var maybeBackslashes = _v0.a;
			var _v1 = _v0.b;
			var maybeLeftFringe = _v1.a;
			var _v2 = _v1.b;
			var delimiter = _v2.a.a;
			var _v3 = _v2.b;
			var maybeRightFringe = _v3.a;
			var leftFringeLength = A2(
				$elm$core$Maybe$withDefault,
				0,
				A2($elm$core$Maybe$map, $elm$core$String$length, maybeLeftFringe));
			var mLeftFringe = ((!(!regMatch.iz)) && (!leftFringeLength)) ? $elm$core$Maybe$Just(
				A3($elm$core$String$slice, regMatch.iz - 1, regMatch.iz, rawText)) : maybeLeftFringe;
			var backslashesLength = A2(
				$elm$core$Maybe$withDefault,
				0,
				A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
			var isEscaped = ((!$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) && (!leftFringeLength)) || _Utils_eq(
				mLeftFringe,
				$elm$core$Maybe$Just('\\'));
			var delimiterLength = isEscaped ? ($elm$core$String$length(delimiter) - 1) : $elm$core$String$length(delimiter);
			var fringeRank = _Utils_Tuple2(
				isEscaped ? 1 : $pablohirafuji$elm_markdown$Markdown$InlineParser$getFringeRank(mLeftFringe),
				$pablohirafuji$elm_markdown$Markdown$InlineParser$getFringeRank(maybeRightFringe));
			var index = ((regMatch.iz + backslashesLength) + leftFringeLength) + (isEscaped ? 1 : 0);
			return ((delimiterLength <= 0) || ((_char === '_') && _Utils_eq(
				fringeRank,
				_Utils_Tuple2(2, 2)))) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				{
					iz: index,
					db: delimiterLength,
					k: A2($pablohirafuji$elm_markdown$Markdown$InlineParser$EmphasisToken, _char, fringeRank)
				});
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '*', str),
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$codeTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\`+)'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$CodeToken = function (a) {
	return {$: 0, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToCodeToken = function (regMatch) {
	var _v0 = regMatch.jO;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backtick = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				iz: regMatch.iz + backslashesLength,
				db: $elm$core$String$length(backtick),
				k: $pablohirafuji$elm_markdown$Markdown$InlineParser$CodeToken(
					!$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength))
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findCodeTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToCodeToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$codeTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( {2,}))\\n'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken = {$: 8};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken = function (regMatch) {
	var _v0 = regMatch.jO;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return (!$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) ? $elm$core$Maybe$Just(
					{iz: (regMatch.iz + backslashesLength) - 1, db: 2, k: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Nothing;
			} else {
				if (_v0.b.b && (!_v0.b.a.$)) {
					var _v1 = _v0.b;
					return $elm$core$Maybe$Just(
						{
							iz: regMatch.iz,
							db: $elm$core$String$length(regMatch.iN),
							k: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken = function (regMatch) {
	var _v0 = regMatch.jO;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return $pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
					{iz: regMatch.iz + backslashesLength, db: 1, k: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Just(
					{iz: (regMatch.iz + backslashesLength) - 1, db: 2, k: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken});
			} else {
				if (_v0.b.b) {
					var _v1 = _v0.b;
					var maybeSpaces = _v1.a;
					return $elm$core$Maybe$Just(
						{
							iz: regMatch.iz,
							db: $elm$core$String$length(regMatch.iN),
							k: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( *))\\n'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findHardBreakTokens = F2(
	function (softAsHardLineBreak, str) {
		return softAsHardLineBreak ? A2(
			$elm$core$List$filterMap,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken,
			A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex, str)) : A2(
			$elm$core$List$filterMap,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken,
			A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex, str));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\])'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken = function (regMatch) {
	var _v0 = regMatch.jO;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{
				iz: regMatch.iz + backslashesLength,
				db: 1,
				k: $pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken(']')
			}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\!)?(\\[)'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageOpenToken = {$: 2};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkOpenToken = function (a) {
	return {$: 1, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken = function (regMatch) {
	var _v0 = regMatch.jO;
	if (((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var maybeImageOpen = _v1.a;
		var _v2 = _v1.b;
		var delimiter = _v2.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		var isEscaped = !$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength);
		var index = (regMatch.iz + backslashesLength) + ((isEscaped && _Utils_eq(
			maybeImageOpen,
			$elm$core$Maybe$Just('!'))) ? 1 : 0);
		var meaning = isEscaped ? A2(
			$elm$core$Maybe$map,
			function (_v3) {
				return $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkOpenToken(true);
			},
			maybeImageOpen) : $elm$core$Maybe$Just(
			A2(
				$elm$core$Maybe$withDefault,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$LinkOpenToken(true),
				A2(
					$elm$core$Maybe$map,
					function (_v4) {
						return $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageOpenToken;
					},
					maybeImageOpen)));
		var length = _Utils_eq(
			meaning,
			$elm$core$Maybe$Just($pablohirafuji$elm_markdown$Markdown$InlineParser$ImageOpenToken)) ? 2 : 1;
		var toModel = function (m) {
			return {iz: index, db: length, k: m};
		};
		return A2($elm$core$Maybe$map, toModel, meaning);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^_])?(\\_+)([^_])?'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '_', str),
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$tokenize = function (model) {
	return _Utils_update(
		model,
		{
			v: A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.iz;
				},
				_Utils_ap(
					$pablohirafuji$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens(model.ac),
					_Utils_ap(
						$pablohirafuji$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens(model.ac),
						_Utils_ap(
							A2($pablohirafuji$elm_markdown$Markdown$InlineParser$findHardBreakTokens, model.i8.hk, model.ac),
							_Utils_ap(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens(model.ac),
								_Utils_ap(
									$pablohirafuji$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens(model.ac),
									_Utils_ap(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens(model.ac),
										_Utils_ap(
											$pablohirafuji$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens(model.ac),
											$pablohirafuji$elm_markdown$Markdown$InlineParser$findCodeTokens(model.ac)))))))))
		});
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$CodeType = {$: 2};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$EmphasisType = function (a) {
	return {$: 7, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlType = function (a) {
	return {$: 6, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageType = function (a) {
	return {$: 5, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkType = function (a) {
	return {$: 4, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch = F2(
	function (model, match) {
		return _Utils_update(
			model,
			{
				e: A2($elm$core$List$cons, match, model.e)
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$addToken = F2(
	function (model, token) {
		return _Utils_update(
			model,
			{
				v: A2($elm$core$List$cons, token, model.v)
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM = F2(
	function (finderFunction, model) {
		return finderFunction(
			_Utils_Tuple2(
				model.v,
				_Utils_update(
					model,
					{v: _List_Nil})));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$AutolinkType = function (a) {
	return {$: 3, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$decodeUrlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('%(?:3B|2C|2F|3F|3A|40|26|3D|2B|24|23|25)'));
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $pablohirafuji$elm_markdown$Markdown$InlineParser$encodeUrl = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2(
		$elm$regex$Regex$replace,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$decodeUrlRegex,
		function (match) {
			return A2(
				$elm$core$Maybe$withDefault,
				match.iN,
				$elm$url$Url$percentDecode(match.iN));
		}));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$urlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z][A-Za-z0-9.+\\-]{1,31}:[^<>\\x00-\\x20]*)$'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$autolinkToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $pablohirafuji$elm_markdown$Markdown$InlineParser$urlRegex, match.fu) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				cV: $pablohirafuji$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.fu,
						$pablohirafuji$elm_markdown$Markdown$InlineParser$encodeUrl(match.fu)))
			})) : $elm$core$Result$Err(match);
};
var $pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars = ' \\t\\f\\v\\r\\n';
var $pablohirafuji$elm_markdown$Markdown$InlineParser$hrefRegex = '(?:<([^<>' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + (']*)>|([^' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + ('\\(\\)\\\\]*(?:\\\\.[^' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + '\\(\\)\\\\]*)*))')))));
var $pablohirafuji$elm_markdown$Markdown$Helpers$titleRegex = '(?:[' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + (']+' + ('(?:\'([^\'\\\\]*(?:\\\\.[^\'\\\\]*)*)\'|' + ('\"([^\"\\\\]*(?:\\\\.[^\"\\\\]*)*)\"|' + '\\(([^\\)\\\\]*(?:\\\\.[^\\)\\\\]*)*)\\)))?'))));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\(\\s*' + ($pablohirafuji$elm_markdown$Markdown$InlineParser$hrefRegex + ($pablohirafuji$elm_markdown$Markdown$Helpers$titleRegex + '\\s*\\)'))));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle = function (_v0) {
	var rawUrl = _v0.a;
	var maybeTitle = _v0.b;
	return _Utils_Tuple2(
		$pablohirafuji$elm_markdown$Markdown$InlineParser$encodeUrl(
			$pablohirafuji$elm_markdown$Markdown$Helpers$formatStr(rawUrl)),
		A2($elm$core$Maybe$map, $pablohirafuji$elm_markdown$Markdown$Helpers$formatStr, maybeTitle));
};
var $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust = function (maybes) {
	var process = F2(
		function (a, maybeFound) {
			if (!maybeFound.$) {
				var found = maybeFound.a;
				return $elm$core$Maybe$Just(found);
			} else {
				return a;
			}
		});
	return A3($elm$core$List$foldl, process, $elm$core$Maybe$Nothing, maybes);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch = F3(
	function (matchModel, model, regexMatch) {
		var _v0 = regexMatch.jO;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && _v0.b.b.b.b) && _v0.b.b.b.b.b) {
			var maybeRawUrlAngleBrackets = _v0.a;
			var _v1 = _v0.b;
			var maybeRawUrlWithoutBrackets = _v1.a;
			var _v2 = _v1.b;
			var maybeTitleSingleQuotes = _v2.a;
			var _v3 = _v2.b;
			var maybeTitleDoubleQuotes = _v3.a;
			var _v4 = _v3.b;
			var maybeTitleParenthesis = _v4.a;
			var maybeTitle = $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeTitleSingleQuotes, maybeTitleDoubleQuotes, maybeTitleParenthesis]));
			var toMatch = function (rawUrl) {
				return _Utils_update(
					matchModel,
					{
						f9: matchModel.f9 + $elm$core$String$length(regexMatch.iN),
						cV: function () {
							var _v5 = matchModel.cV;
							if (_v5.$ === 5) {
								return $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageType;
							} else {
								return $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkType;
							}
						}()(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle(
								_Utils_Tuple2(rawUrl, maybeTitle)))
					});
			};
			var maybeRawUrl = $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeRawUrlAngleBrackets, maybeRawUrlWithoutBrackets]));
			return $elm$core$Maybe$Just(
				toMatch(
					A2($elm$core$Maybe$withDefault, '', maybeRawUrl)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType = function (_v0) {
	var remainText = _v0.a;
	var tempMatch = _v0.b;
	var model = _v0.c;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple3(remainText, tempMatch, model),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch(model),
			A2(
				$elm$core$Maybe$andThen,
				A2($pablohirafuji$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch, tempMatch, model),
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex, remainText)))));
};
var $pablohirafuji$elm_markdown$Markdown$Helpers$insideSquareBracketRegex = '[^\\[\\]\\\\]*(?:\\\\.[^\\[\\]\\\\]*)*';
var $pablohirafuji$elm_markdown$Markdown$InlineParser$refLabelRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\[\\s*(' + ($pablohirafuji$elm_markdown$Markdown$Helpers$insideSquareBracketRegex + ')\\s*\\]')));
var $pablohirafuji$elm_markdown$Markdown$Helpers$whitespacesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + ']+')));
var $pablohirafuji$elm_markdown$Markdown$Helpers$cleanWhitespaces = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$trim,
	A2(
		$elm$regex$Regex$replace,
		$pablohirafuji$elm_markdown$Markdown$Helpers$whitespacesRegex,
		function (_v0) {
			return ' ';
		}));
var $pablohirafuji$elm_markdown$Markdown$Helpers$prepareRefLabel = A2($elm$core$Basics$composeR, $pablohirafuji$elm_markdown$Markdown$Helpers$cleanWhitespaces, $elm$core$String$toLower);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$refRegexToMatch = F3(
	function (matchModel, model, maybeRegexMatch) {
		var regexMatchLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.iN;
					},
					$elm$core$String$length),
				maybeRegexMatch));
		var toMatch = function (urlTitle) {
			return _Utils_update(
				matchModel,
				{
					f9: matchModel.f9 + regexMatchLength,
					cV: function () {
						var _v0 = matchModel.cV;
						if (_v0.$ === 5) {
							return $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageType;
						} else {
							return $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkType;
						}
					}()(
						$pablohirafuji$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle(urlTitle))
				});
		};
		var refLabel = function (str) {
			return $elm$core$String$isEmpty(str) ? matchModel.fu : str;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				matchModel.fu,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Maybe$Nothing,
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.jO;
								},
								$elm$core$List$head),
							maybeRegexMatch)))));
		var maybeRefItem = A2(
			$elm$core$Dict$get,
			$pablohirafuji$elm_markdown$Markdown$Helpers$prepareRefLabel(refLabel),
			model.jq);
		return A2($elm$core$Maybe$map, toMatch, maybeRefItem);
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$checkForRefLinkTypeOrImageType = function (_v0) {
	var remainText = _v0.a;
	var tempMatch = _v0.b;
	var model = _v0.c;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple3(remainText, tempMatch, model),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch(model),
			A3(
				$pablohirafuji$elm_markdown$Markdown$InlineParser$refRegexToMatch,
				tempMatch,
				model,
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$InlineParser$refLabelRegex, remainText)))));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping = function (parser) {
	var _v0 = parser.e;
	if (!_v0.b) {
		return $elm$core$Result$Err(0);
	} else {
		var match = _v0.a;
		var remainMatches = _v0.b;
		var overlappingMatches = A2(
			$elm$core$List$filter,
			function (_v1) {
				var testMatch = _v1;
				return (_Utils_cmp(match.f9, testMatch.fn) > 0) && (_Utils_cmp(match.f9, testMatch.f9) < 0);
			},
			remainMatches);
		return ($elm$core$List$isEmpty(remainMatches) || $elm$core$List$isEmpty(overlappingMatches)) ? $elm$core$Result$Ok(parser) : $elm$core$Result$Err(0);
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~\\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?)*)$'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $pablohirafuji$elm_markdown$Markdown$InlineParser$emailRegex, match.fu) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				cV: $pablohirafuji$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.fu,
						'mailto:' + $pablohirafuji$elm_markdown$Markdown$InlineParser$encodeUrl(match.fu)))
			})) : $elm$core$Result$Err(match);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$filterTokens = F2(
	function (filter, model) {
		return _Utils_update(
			model,
			{
				v: A2($elm$core$List$filter, filter, model.v)
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findToken = F2(
	function (isToken, tokens) {
		var search = F2(
			function (token, _v2) {
				var maybeToken = _v2.a;
				var innerTokens = _v2.b;
				var remainTokens = _v2.c;
				if (maybeToken.$ === 1) {
					return isToken(token) ? _Utils_Tuple3(
						$elm$core$Maybe$Just(token),
						innerTokens,
						_List_Nil) : _Utils_Tuple3(
						$elm$core$Maybe$Nothing,
						A2($elm$core$List$cons, token, innerTokens),
						_List_Nil);
				} else {
					return _Utils_Tuple3(
						maybeToken,
						innerTokens,
						A2($elm$core$List$cons, token, remainTokens));
				}
			});
		var _return = function (_v0) {
			var maybeToken = _v0.a;
			var innerTokens = _v0.b;
			var remainTokens = _v0.c;
			return A2(
				$elm$core$Maybe$map,
				function (token) {
					return _Utils_Tuple3(
						token,
						$elm$core$List$reverse(innerTokens),
						$elm$core$List$reverse(remainTokens));
				},
				maybeToken);
		};
		return _return(
			A3(
				$elm$core$List$foldl,
				search,
				_Utils_Tuple3($elm$core$Maybe$Nothing, _List_Nil, _List_Nil),
				tokens));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlModel = F2(
	function (tag, attributes) {
		return {fW: attributes, ft: tag};
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlToken = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$attributesFromRegex = function (regexMatch) {
	var _v0 = regexMatch.jO;
	_v0$2:
	while (true) {
		if (_v0.b && (!_v0.a.$)) {
			if (_v0.a.a === '') {
				return $elm$core$Maybe$Nothing;
			} else {
				if ((_v0.b.b && _v0.b.b.b) && _v0.b.b.b.b) {
					var name = _v0.a.a;
					var _v1 = _v0.b;
					var maybeDoubleQuotes = _v1.a;
					var _v2 = _v1.b;
					var maybeSingleQuotes = _v2.a;
					var _v3 = _v2.b;
					var maybeUnquoted = _v3.a;
					var maybeValue = $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
						_List_fromArray(
							[maybeDoubleQuotes, maybeSingleQuotes, maybeUnquoted]));
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(name, maybeValue));
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlAttributesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('([a-zA-Z:_][a-zA-Z0-9\\-_.:]*)(?: ?= ?(?:\"([^\"]*)\"|\'([^\']*)\'|([^\\s\"\'=<>`]*)))?'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$applyAttributesRegex = A2(
	$elm$core$Basics$composeR,
	$elm$regex$Regex$find($pablohirafuji$elm_markdown$Markdown$InlineParser$htmlAttributesRegex),
	$elm$core$List$filterMap($pablohirafuji$elm_markdown$Markdown$InlineParser$attributesFromRegex));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlFromRegex = F3(
	function (model, match, regexMatch) {
		var _v0 = regexMatch.jO;
		if ((((_v0.b && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) && _v0.b.b.b.b) {
			var maybeClose = _v0.a;
			var _v1 = _v0.b;
			var tag = _v1.a.a;
			var _v2 = _v1.b;
			var maybeAttributes = _v2.a;
			var _v3 = _v2.b;
			var maybeSelfClosing = _v3.a;
			var updateModel = function (attrs) {
				return A2(
					$pablohirafuji$elm_markdown$Markdown$InlineParser$addToken,
					model,
					{
						iz: match.fn,
						db: match.f9 - match.fn,
						k: A2(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlToken,
							_Utils_eq(maybeClose, $elm$core$Maybe$Nothing) && _Utils_eq(maybeSelfClosing, $elm$core$Maybe$Nothing),
							A2($pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlModel, tag, attrs))
					});
			};
			var filterAttributes = F2(
				function (attrs, allowed) {
					return A2(
						$elm$core$List$filter,
						function (attr) {
							return A2($elm$core$List$member, attr.a, allowed);
						},
						attrs);
				});
			var attributes = A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Maybe$map, $pablohirafuji$elm_markdown$Markdown$InlineParser$applyAttributesRegex, maybeAttributes));
			var noAttributesInCloseTag = _Utils_eq(maybeClose, $elm$core$Maybe$Nothing) || ((!_Utils_eq(maybeClose, $elm$core$Maybe$Nothing)) && _Utils_eq(attributes, _List_Nil));
			var _v4 = model.i8.g2;
			switch (_v4.$) {
				case 0:
					return noAttributesInCloseTag ? $elm$core$Maybe$Just(
						updateModel(attributes)) : $elm$core$Maybe$Nothing;
				case 1:
					var allowedHtmlElements = _v4.a.fT;
					var allowedHtmlAttributes = _v4.a.fS;
					return (A2($elm$core$List$member, tag, allowedHtmlElements) && noAttributesInCloseTag) ? $elm$core$Maybe$Just(
						updateModel(
							A2(filterAttributes, attributes, allowedHtmlAttributes))) : $elm$core$Maybe$Nothing;
				default:
					return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^(\\/)?([a-zA-Z][a-zA-Z0-9\\-]*)(?:\\s+([^<>]*?))?(\\/)?$'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlToToken = F2(
	function (model, _v0) {
		var match = _v0;
		var _v1 = model.i8.g2;
		if (_v1.$ === 2) {
			return $elm$core$Maybe$Nothing;
		} else {
			return A2(
				$elm$core$Maybe$andThen,
				A2($pablohirafuji$elm_markdown$Markdown$InlineParser$htmlFromRegex, model, match),
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlRegex, match.fu)));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isCloseToken = F2(
	function (htmlModel, token) {
		var _v0 = token.k;
		if ((_v0.$ === 5) && (!_v0.a)) {
			var htmlModel_ = _v0.b;
			return _Utils_eq(htmlModel.ft, htmlModel_.ft);
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isCodeTokenPair = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.k;
		if (!_v0.$) {
			var isEscaped = _v0.a;
			return isEscaped ? _Utils_eq(openToken.db - 1, closeToken.db) : _Utils_eq(openToken.db, closeToken.db);
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken = function (token) {
	var _v0 = token.k;
	switch (_v0.$) {
		case 1:
			return true;
		case 2:
			return true;
		default:
			return false;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.k;
		if (_v0.$ === 6) {
			var openChar = _v0.a;
			var _v1 = _v0.b;
			var openLR = _v1.a;
			var openRR = _v1.b;
			var _v2 = closeToken.k;
			if (_v2.$ === 6) {
				var closeChar = _v2.a;
				var _v3 = _v2.b;
				var closeLR = _v3.a;
				var closeRR = _v3.b;
				return _Utils_eq(openChar, closeChar) ? ((_Utils_eq(openLR, openRR) || _Utils_eq(closeLR, closeRR)) ? (!(!A2($elm$core$Basics$modBy, 3, closeToken.db + openToken.db))) : true) : false;
			} else {
				return false;
			}
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$voidHtmlTags = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isVoidTag = function (htmlModel) {
	return A2($elm$core$List$member, htmlModel.ft, $pablohirafuji$elm_markdown$Markdown$InlineParser$voidHtmlTags);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakType = {$: 1};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$SoftLineBreakToken = {$: 7};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens = function (model) {
	return _Utils_update(
		model,
		{
			v: $elm$core$List$reverse(model.v)
		});
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$tokenToMatch = F2(
	function (token, type_) {
		return {f9: token.iz + token.db, e: _List_Nil, fn: token.iz, fu: '', cb: 0, aI: 0, cV: type_};
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$lineBreakTTM = function (_v0) {
	lineBreakTTM:
	while (true) {
		var tokens = _v0.a;
		var model = _v0.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			if (_Utils_eq(token.k, $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken) || (_Utils_eq(token.k, $pablohirafuji$elm_markdown$Markdown$InlineParser$SoftLineBreakToken) && model.i8.hk)) {
				return $pablohirafuji$elm_markdown$Markdown$InlineParser$lineBreakTTM(
					function (b) {
						return _Utils_Tuple2(tokensTail, b);
					}(
						_Utils_update(
							model,
							{
								e: A2(
									$elm$core$List$cons,
									A2($pablohirafuji$elm_markdown$Markdown$InlineParser$tokenToMatch, token, $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakType),
									model.e)
							})));
			} else {
				var $temp$_v0 = _Utils_Tuple2(
					tokensTail,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v0 = $temp$_v0;
				continue lineBreakTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens = F2(
	function (tokensTail, parser) {
		var _v0 = parser.e;
		if (!_v0.b) {
			return _Utils_Tuple2(tokensTail, parser);
		} else {
			var match = _v0.a;
			return _Utils_Tuple2(
				A2(
					$elm$core$List$filter,
					function (token) {
						return _Utils_cmp(token.iz, match.f9) > -1;
					},
					tokensTail),
				parser);
		}
	});
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketsToMatch = F4(
	function (closeToken, isEscaped, model, _v24) {
		var openToken = _v24.a;
		var remainTokens = _v24.c;
		return function (result) {
			if (result.$ === 1) {
				var tempMatch = result.a;
				return (!isEscaped) ? A2(
					$pablohirafuji$elm_markdown$Markdown$InlineParser$htmlToToken,
					_Utils_update(
						model,
						{v: remainTokens}),
					tempMatch) : $elm$core$Result$toMaybe(result);
			} else {
				return $elm$core$Result$toMaybe(result);
			}
		}(
			A2(
				$elm$core$Result$map,
				function (newMatch) {
					return _Utils_update(
						model,
						{
							e: A2($elm$core$List$cons, newMatch, model.e),
							v: remainTokens
						});
				},
				A2(
					$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$autolinkToMatch(
						A6(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
							model,
							function (s) {
								return s;
							},
							$pablohirafuji$elm_markdown$Markdown$InlineParser$CodeType,
							openToken,
							closeToken,
							_List_Nil)))));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM = function (_v21) {
	codeAutolinkTypeHtmlTagTTM:
	while (true) {
		var tokens = _v21.a;
		var model = _v21.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v23 = token.k;
			switch (_v23.$) {
				case 0:
					var isEscaped = _v23.a;
					return $pablohirafuji$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM(
						function (b) {
							return _Utils_Tuple2(tokensTail, b);
						}(
							A2(
								$elm$core$Maybe$withDefault,
								A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token),
								A2(
									$elm$core$Maybe$map,
									A2($pablohirafuji$elm_markdown$Markdown$InlineParser$codeToMatch, token, model),
									A2(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$isCodeTokenPair(token),
										model.v)))));
				case 4:
					var isEscaped = _v23.a;
					return $pablohirafuji$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM(
						function (b) {
							return _Utils_Tuple2(tokensTail, b);
						}(
							A2(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$filterTokens,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.k;
									},
									$elm$core$Basics$neq(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken('<'))),
								A2(
									$elm$core$Maybe$withDefault,
									model,
									A2(
										$elm$core$Maybe$andThen,
										A3($pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketsToMatch, token, isEscaped, model),
										A2(
											$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.k;
												},
												$elm$core$Basics$eq(
													$pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken('<'))),
											model.v))))));
				default:
					var $temp$_v21 = _Utils_Tuple2(
						tokensTail,
						A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
					_v21 = $temp$_v21;
					continue codeAutolinkTypeHtmlTagTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$codeToMatch = F3(
	function (closeToken, model, _v20) {
		var openToken = _v20.a;
		var remainTokens = _v20.c;
		var updtOpenToken = _Utils_eq(
			openToken.k,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$CodeToken(true)) ? _Utils_update(
			openToken,
			{iz: openToken.iz + 1, db: openToken.db - 1}) : openToken;
		return _Utils_update(
			model,
			{
				e: A2(
					$elm$core$List$cons,
					A6($pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch, model, $pablohirafuji$elm_markdown$Markdown$Helpers$cleanWhitespaces, $pablohirafuji$elm_markdown$Markdown$InlineParser$CodeType, updtOpenToken, closeToken, _List_Nil),
					model.e),
				v: remainTokens
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisTTM = function (_v16) {
	emphasisTTM:
	while (true) {
		var tokens = _v16.a;
		var model = _v16.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v18 = token.k;
			if (_v18.$ === 6) {
				var _char = _v18.a;
				var _v19 = _v18.b;
				var leftRank = _v19.a;
				var rightRank = _v19.b;
				if (_Utils_eq(leftRank, rightRank)) {
					if ((!(!rightRank)) && ((_char !== '_') || (rightRank === 1))) {
						return $pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisTTM(
							A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(
									tokensTail,
									A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token)),
								A2(
									$elm$core$Maybe$map,
									A3($pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisToMatch, token, tokensTail, model),
									A2(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
										model.v))));
					} else {
						var $temp$_v16 = _Utils_Tuple2(tokensTail, model);
						_v16 = $temp$_v16;
						continue emphasisTTM;
					}
				} else {
					if (_Utils_cmp(leftRank, rightRank) < 0) {
						var $temp$_v16 = _Utils_Tuple2(
							tokensTail,
							A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
						_v16 = $temp$_v16;
						continue emphasisTTM;
					} else {
						return $pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisTTM(
							A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(tokensTail, model),
								A2(
									$elm$core$Maybe$map,
									A3($pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisToMatch, token, tokensTail, model),
									A2(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
										model.v))));
					}
				}
			} else {
				var $temp$_v16 = _Utils_Tuple2(
					tokensTail,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v16 = $temp$_v16;
				continue emphasisTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisToMatch = F4(
	function (closeToken, tokensTail, model, _v15) {
		var openToken = _v15.a;
		var innerTokens = _v15.b;
		var remainTokens = _v15.c;
		var remainLength = openToken.db - closeToken.db;
		var updt = (!remainLength) ? {c$: closeToken, cE: openToken, dn: remainTokens, dv: tokensTail} : ((remainLength > 0) ? {
			c$: closeToken,
			cE: _Utils_update(
				openToken,
				{iz: openToken.iz + remainLength, db: closeToken.db}),
			dn: A2(
				$elm$core$List$cons,
				_Utils_update(
					openToken,
					{db: remainLength}),
				remainTokens),
			dv: tokensTail
		} : {
			c$: _Utils_update(
				closeToken,
				{db: openToken.db}),
			cE: openToken,
			dn: remainTokens,
			dv: A2(
				$elm$core$List$cons,
				_Utils_update(
					closeToken,
					{iz: closeToken.iz + openToken.db, db: -remainLength}),
				tokensTail)
		});
		var match = A6(
			$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
			model,
			function (s) {
				return s;
			},
			$pablohirafuji$elm_markdown$Markdown$InlineParser$EmphasisType(updt.cE.db),
			updt.cE,
			updt.c$,
			$elm$core$List$reverse(innerTokens));
		return _Utils_Tuple2(
			updt.dv,
			_Utils_update(
				model,
				{
					e: A2($elm$core$List$cons, match, model.e),
					v: updt.dn
				}));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementTTM = function (_v12) {
	htmlElementTTM:
	while (true) {
		var tokens = _v12.a;
		var model = _v12.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v14 = token.k;
			if (_v14.$ === 5) {
				var isOpen = _v14.a;
				var htmlModel = _v14.b;
				return ($pablohirafuji$elm_markdown$Markdown$InlineParser$isVoidTag(htmlModel) || (!isOpen)) ? $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementTTM(
					function (b) {
						return _Utils_Tuple2(tokensTail, b);
					}(
						A2(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch,
							model,
							A2(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenToMatch,
								token,
								$pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel))))) : $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementTTM(
					A2(
						$elm$core$Maybe$withDefault,
						function (b) {
							return _Utils_Tuple2(tokensTail, b);
						}(
							A2(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch,
								model,
								A2(
									$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenToMatch,
									token,
									$pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel)))),
						A2(
							$elm$core$Maybe$map,
							A3($pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementToMatch, token, model, htmlModel),
							A2(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
								$pablohirafuji$elm_markdown$Markdown$InlineParser$isCloseToken(htmlModel),
								tokensTail))));
			} else {
				var $temp$_v12 = _Utils_Tuple2(
					tokensTail,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v12 = $temp$_v12;
				continue htmlElementTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementToMatch = F4(
	function (openToken, model, htmlModel, _v11) {
		var closeToken = _v11.a;
		var innerTokens = _v11.b;
		var remainTokens = _v11.c;
		return _Utils_Tuple2(
			remainTokens,
			_Utils_update(
				model,
				{
					e: A2(
						$elm$core$List$cons,
						A6(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
							model,
							function (s) {
								return s;
							},
							$pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel),
							openToken,
							closeToken,
							innerTokens),
						model.e)
				}));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageTypeTTM = function (_v8) {
	linkImageTypeTTM:
	while (true) {
		var tokens = _v8.a;
		var model = _v8.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v10 = token.k;
			if ((_v10.$ === 3) && (']' === _v10.a)) {
				return $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageTypeTTM(
					A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(tokensTail, model),
						A2(
							$elm$core$Maybe$andThen,
							A3($pablohirafuji$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch, token, tokensTail, model),
							A2($pablohirafuji$elm_markdown$Markdown$InlineParser$findToken, $pablohirafuji$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken, model.v))));
			} else {
				var $temp$_v8 = _Utils_Tuple2(
					tokensTail,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v8 = $temp$_v8;
				continue linkImageTypeTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch = F4(
	function (closeToken, tokensTail, model, _v1) {
		var openToken = _v1.a;
		var innerTokens = _v1.b;
		var remainTokens = _v1.c;
		var tempMatch = function (isLinkType) {
			return A6(
				$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
				model,
				function (s) {
					return s;
				},
				isLinkType ? $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)) : $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)),
				openToken,
				closeToken,
				$elm$core$List$reverse(innerTokens));
		};
		var removeOpenToken = _Utils_Tuple2(
			tokensTail,
			_Utils_update(
				model,
				{
					v: _Utils_ap(innerTokens, remainTokens)
				}));
		var remainText = A2($elm$core$String$dropLeft, closeToken.iz + 1, model.ac);
		var linkOpenTokenToInactive = function (model_) {
			var process = function (token) {
				var _v7 = token.k;
				if (_v7.$ === 1) {
					return _Utils_update(
						token,
						{
							k: $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkOpenToken(false)
						});
				} else {
					return token;
				}
			};
			return _Utils_update(
				model_,
				{
					v: A2($elm$core$List$map, process, model_.v)
				});
		};
		var args = function (isLinkType) {
			return _Utils_Tuple3(
				remainText,
				tempMatch(isLinkType),
				_Utils_update(
					model,
					{v: remainTokens}));
		};
		var _v2 = openToken.k;
		switch (_v2.$) {
			case 2:
				return $elm$core$Result$toMaybe(
					A2(
						$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
						function (_v4) {
							return $elm$core$Result$Ok(removeOpenToken);
						},
						A2(
							$elm$core$Result$map,
							$pablohirafuji$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens(tokensTail),
							A2(
								$elm$core$Result$andThen,
								$pablohirafuji$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping,
								A2(
									$elm$core$Result$mapError,
									function (_v3) {
										return 0;
									},
									A2(
										$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$checkForRefLinkTypeOrImageType,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType(
											args(false))))))));
			case 1:
				if (_v2.a) {
					return $elm$core$Result$toMaybe(
						A2(
							$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
							function (_v6) {
								return $elm$core$Result$Ok(removeOpenToken);
							},
							A2(
								$elm$core$Result$map,
								$pablohirafuji$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens(tokensTail),
								A2(
									$elm$core$Result$map,
									linkOpenTokenToInactive,
									A2(
										$elm$core$Result$andThen,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping,
										A2(
											$elm$core$Result$mapError,
											function (_v5) {
												return 0;
											},
											A2(
												$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
												$pablohirafuji$elm_markdown$Markdown$InlineParser$checkForRefLinkTypeOrImageType,
												$pablohirafuji$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType(
													args(true)))))))));
				} else {
					return $elm$core$Maybe$Just(removeOpenToken);
				}
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch = F6(
	function (model, processText, type_, openToken, closeToken, innerTokens) {
		var textStart = openToken.iz + openToken.db;
		var textEnd = closeToken.iz;
		var start = openToken.iz;
		var end = closeToken.iz + closeToken.db;
		var match = {
			f9: end,
			e: _List_Nil,
			fn: start,
			fu: processText(
				A3($elm$core$String$slice, textStart, textEnd, model.ac)),
			cb: textEnd,
			aI: textStart,
			cV: type_
		};
		var matches = A2(
			$elm$core$List$map,
			function (_v0) {
				var matchModel = _v0;
				return A2($pablohirafuji$elm_markdown$Markdown$InlineParser$prepareChildMatch, match, matchModel);
			},
			$pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches()(
				_Utils_update(
					model,
					{e: _List_Nil, v: innerTokens})).e);
		return _Utils_update(
			match,
			{e: matches});
	});
function $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches() {
	return A2(
		$elm$core$Basics$composeR,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM),
		A2(
			$elm$core$Basics$composeR,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementTTM),
			A2(
				$elm$core$Basics$composeR,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageTypeTTM),
				A2(
					$elm$core$Basics$composeR,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisTTM),
					$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$lineBreakTTM)))));
}
var $pablohirafuji$elm_markdown$Markdown$InlineParser$tokensToMatches = $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches();
$pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches = function () {
	return $pablohirafuji$elm_markdown$Markdown$InlineParser$tokensToMatches;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$parse = F3(
	function (options, refs, rawText) {
		return $pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(
			$pablohirafuji$elm_markdown$Markdown$InlineParser$parseText(
				$pablohirafuji$elm_markdown$Markdown$InlineParser$organizeParserMatches(
					$pablohirafuji$elm_markdown$Markdown$InlineParser$tokensToMatches(
						$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenize(
							A3(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$initParser,
								options,
								refs,
								$elm$core$String$trim(rawText)))))).e);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseInline = F4(
	function (maybeOptions, textAsParagraph, refs, block) {
		var options = A2($elm$core$Maybe$withDefault, $pablohirafuji$elm_markdown$Markdown$Config$defaultOptions, maybeOptions);
		switch (block.$) {
			case 2:
				var rawText = block.a;
				var lvl = block.b;
				return A3(
					$pablohirafuji$elm_markdown$Markdown$Block$Heading,
					rawText,
					lvl,
					A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parse, options, refs, rawText));
			case 4:
				var rawText = block.a;
				var inlines = A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parse, options, refs, rawText);
				if ((inlines.b && (inlines.a.$ === 5)) && (!inlines.b.b)) {
					var _v3 = inlines.a;
					return $pablohirafuji$elm_markdown$Markdown$Block$PlainInlines(inlines);
				} else {
					return textAsParagraph ? A2($pablohirafuji$elm_markdown$Markdown$Block$Paragraph, rawText, inlines) : $pablohirafuji$elm_markdown$Markdown$Block$PlainInlines(inlines);
				}
			case 5:
				var blocks = block.a;
				return $pablohirafuji$elm_markdown$Markdown$Block$BlockQuote(
					A3(
						$pablohirafuji$elm_markdown$Markdown$Block$parseInlines,
						maybeOptions,
						true,
						_Utils_Tuple2(refs, blocks)));
			case 6:
				var model = block.a;
				var items = block.b;
				return A2(
					$pablohirafuji$elm_markdown$Markdown$Block$List,
					model,
					function (a) {
						return A2($elm$core$List$map, a, items);
					}(
						A2(
							$elm$core$Basics$composeL,
							A2($pablohirafuji$elm_markdown$Markdown$Block$parseInlines, maybeOptions, model.a7),
							function (b) {
								return _Utils_Tuple2(refs, b);
							})));
			case 8:
				var customBlock = block.a;
				var blocks = block.b;
				return A2(
					$pablohirafuji$elm_markdown$Markdown$Block$Custom,
					customBlock,
					A3(
						$pablohirafuji$elm_markdown$Markdown$Block$parseInlines,
						maybeOptions,
						true,
						_Utils_Tuple2(refs, blocks)));
			default:
				return block;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseInlines = F3(
	function (maybeOptions, textAsParagraph, _v0) {
		var refs = _v0.a;
		var blocks = _v0.b;
		return A2(
			$elm$core$List$map,
			A3($pablohirafuji$elm_markdown$Markdown$Block$parseInline, maybeOptions, textAsParagraph, refs),
			blocks);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $pablohirafuji$elm_markdown$Markdown$Block$dropRefString = F2(
	function (rawText, inlineMatch) {
		var strippedText = A2($elm$core$String$dropLeft, inlineMatch.eB, rawText);
		return A2($elm$regex$Regex$contains, $pablohirafuji$elm_markdown$Markdown$Block$blankLineRegex, strippedText) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(strippedText);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$insertLinkMatch = F2(
	function (refs, linkMatch) {
		return A2($elm$core$Dict$member, linkMatch.bx, refs) ? refs : A3(
			$elm$core$Dict$insert,
			linkMatch.bx,
			_Utils_Tuple2(linkMatch.bJ, linkMatch.eC),
			refs);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$extractUrlTitleRegex = function (regexMatch) {
	var _v0 = regexMatch.jO;
	if ((((((_v0.b && (!_v0.a.$)) && _v0.b.b) && _v0.b.b.b) && _v0.b.b.b.b) && _v0.b.b.b.b.b) && _v0.b.b.b.b.b.b) {
		var rawText = _v0.a.a;
		var _v1 = _v0.b;
		var maybeRawUrlAngleBrackets = _v1.a;
		var _v2 = _v1.b;
		var maybeRawUrlWithoutBrackets = _v2.a;
		var _v3 = _v2.b;
		var maybeTitleSingleQuotes = _v3.a;
		var _v4 = _v3.b;
		var maybeTitleDoubleQuotes = _v4.a;
		var _v5 = _v4.b;
		var maybeTitleParenthesis = _v5.a;
		var toReturn = function (rawUrl) {
			return {
				bx: rawText,
				eB: $elm$core$String$length(regexMatch.iN),
				eC: $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
					_List_fromArray(
						[maybeTitleSingleQuotes, maybeTitleDoubleQuotes, maybeTitleParenthesis])),
				bJ: rawUrl
			};
		};
		var maybeRawUrl = $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
			_List_fromArray(
				[maybeRawUrlAngleBrackets, maybeRawUrlWithoutBrackets]));
		return A2($elm$core$Maybe$map, toReturn, maybeRawUrl);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$hrefRegex = '\\s*(?:<([^<>\\s]*)>|([^\\s]*))';
var $pablohirafuji$elm_markdown$Markdown$Block$refRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\s*\\[(' + ($pablohirafuji$elm_markdown$Markdown$Helpers$insideSquareBracketRegex + (')\\]:' + ($pablohirafuji$elm_markdown$Markdown$Block$hrefRegex + ($pablohirafuji$elm_markdown$Markdown$Helpers$titleRegex + '\\s*(?![^\\n])'))))));
var $pablohirafuji$elm_markdown$Markdown$Block$maybeLinkMatch = function (rawText) {
	return A2(
		$elm$core$Maybe$andThen,
		function (linkMatch) {
			return ((linkMatch.bJ === '') || (linkMatch.bx === '')) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(linkMatch);
		},
		A2(
			$elm$core$Maybe$map,
			function (linkMatch) {
				return _Utils_update(
					linkMatch,
					{
						bx: $pablohirafuji$elm_markdown$Markdown$Helpers$prepareRefLabel(linkMatch.bx)
					});
			},
			A2(
				$elm$core$Maybe$andThen,
				$pablohirafuji$elm_markdown$Markdown$Block$extractUrlTitleRegex,
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$refRegex, rawText)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$parseReference = F2(
	function (refs, rawText) {
		parseReference:
		while (true) {
			var _v0 = $pablohirafuji$elm_markdown$Markdown$Block$maybeLinkMatch(rawText);
			if (!_v0.$) {
				var linkMatch = _v0.a;
				var updtRefs = A2($pablohirafuji$elm_markdown$Markdown$Block$insertLinkMatch, refs, linkMatch);
				var maybeStrippedText = A2($pablohirafuji$elm_markdown$Markdown$Block$dropRefString, rawText, linkMatch);
				if (!maybeStrippedText.$) {
					var strippedText = maybeStrippedText.a;
					var $temp$refs = updtRefs,
						$temp$rawText = strippedText;
					refs = $temp$refs;
					rawText = $temp$rawText;
					continue parseReference;
				} else {
					return _Utils_Tuple2(updtRefs, $elm$core$Maybe$Nothing);
				}
			} else {
				return _Utils_Tuple2(
					refs,
					$elm$core$Maybe$Just(rawText));
			}
		}
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseReferences = function (refs) {
	return A2(
		$elm$core$List$foldl,
		$pablohirafuji$elm_markdown$Markdown$Block$parseReferencesHelp,
		_Utils_Tuple2(refs, _List_Nil));
};
var $pablohirafuji$elm_markdown$Markdown$Block$parseReferencesHelp = F2(
	function (block, _v0) {
		var refs = _v0.a;
		var parsedAST = _v0.b;
		switch (block.$) {
			case 4:
				var rawText = block.a;
				var _v2 = A2($pablohirafuji$elm_markdown$Markdown$Block$parseReference, $elm$core$Dict$empty, rawText);
				var paragraphRefs = _v2.a;
				var maybeUpdtText = _v2.b;
				var updtRefs = A2($elm$core$Dict$union, paragraphRefs, refs);
				if (!maybeUpdtText.$) {
					var updtText = maybeUpdtText.a;
					return _Utils_Tuple2(
						updtRefs,
						A2(
							$elm$core$List$cons,
							A2($pablohirafuji$elm_markdown$Markdown$Block$Paragraph, updtText, _List_Nil),
							parsedAST));
				} else {
					return _Utils_Tuple2(updtRefs, parsedAST);
				}
			case 6:
				var model = block.a;
				var items = block.b;
				var _v4 = A3(
					$elm$core$List$foldl,
					F2(
						function (item, _v5) {
							var refs__ = _v5.a;
							var parsedItems = _v5.b;
							return A2(
								$elm$core$Tuple$mapSecond,
								function (a) {
									return A2($elm$core$List$cons, a, parsedItems);
								},
								A2($pablohirafuji$elm_markdown$Markdown$Block$parseReferences, refs__, item));
						}),
					_Utils_Tuple2(refs, _List_Nil),
					items);
				var updtRefs = _v4.a;
				var updtItems = _v4.b;
				return _Utils_Tuple2(
					updtRefs,
					A2(
						$elm$core$List$cons,
						A2($pablohirafuji$elm_markdown$Markdown$Block$List, model, updtItems),
						parsedAST));
			case 5:
				var blocks = block.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					function (a) {
						return A2($elm$core$List$cons, a, parsedAST);
					},
					A2(
						$elm$core$Tuple$mapSecond,
						$pablohirafuji$elm_markdown$Markdown$Block$BlockQuote,
						A2($pablohirafuji$elm_markdown$Markdown$Block$parseReferences, refs, blocks)));
			case 8:
				var customBlock = block.a;
				var blocks = block.b;
				return A2(
					$elm$core$Tuple$mapSecond,
					function (a) {
						return A2($elm$core$List$cons, a, parsedAST);
					},
					A2(
						$elm$core$Tuple$mapSecond,
						$pablohirafuji$elm_markdown$Markdown$Block$Custom(customBlock),
						A2($pablohirafuji$elm_markdown$Markdown$Block$parseReferences, refs, blocks)));
			default:
				return _Utils_Tuple2(
					refs,
					A2($elm$core$List$cons, block, parsedAST));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parse = function (maybeOptions) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$lines,
		A2(
			$elm$core$Basics$composeR,
			function (a) {
				return A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLines, a, _List_Nil);
			},
			A2(
				$elm$core$Basics$composeR,
				$pablohirafuji$elm_markdown$Markdown$Block$parseReferences($elm$core$Dict$empty),
				A2($pablohirafuji$elm_markdown$Markdown$Block$parseInlines, maybeOptions, true))));
};
var $elm$html$Html$blockquote = _VirtualDom_node('blockquote');
var $elm$html$Html$code = _VirtualDom_node('code');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $elm$html$Html$h6 = _VirtualDom_node('h6');
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $elm$html$Html$pre = _VirtualDom_node('pre');
var $elm$html$Html$Attributes$start = function (n) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'start',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $pablohirafuji$elm_markdown$Markdown$Inline$attributeToAttribute = function (_v0) {
	var name = _v0.a;
	var maybeValue = _v0.b;
	return A2(
		$elm$html$Html$Attributes$attribute,
		name,
		A2($elm$core$Maybe$withDefault, name, maybeValue));
};
var $pablohirafuji$elm_markdown$Markdown$Inline$attributesToHtmlAttributes = $elm$core$List$map($pablohirafuji$elm_markdown$Markdown$Inline$attributeToAttribute);
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$html$Html$em = _VirtualDom_node('em');
var $pablohirafuji$elm_markdown$Markdown$Inline$extractText = function (inlines) {
	return A3($elm$core$List$foldl, $pablohirafuji$elm_markdown$Markdown$Inline$extractTextHelp, '', inlines);
};
var $pablohirafuji$elm_markdown$Markdown$Inline$extractTextHelp = F2(
	function (inline, text) {
		switch (inline.$) {
			case 0:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 1:
				return text + ' ';
			case 2:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 3:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
			case 4:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
			case 5:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
			case 6:
				var inlines = inline.b;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
			default:
				var inlines = inline.b;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
		}
	});
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $pablohirafuji$elm_markdown$Markdown$Inline$defaultHtml = F2(
	function (customTransformer, inline) {
		var transformer = A2(
			$elm$core$Maybe$withDefault,
			$pablohirafuji$elm_markdown$Markdown$Inline$defaultHtml($elm$core$Maybe$Nothing),
			customTransformer);
		switch (inline.$) {
			case 0:
				var str = inline.a;
				return $elm$html$Html$text(str);
			case 1:
				return A2($elm$html$Html$br, _List_Nil, _List_Nil);
			case 2:
				var codeStr = inline.a;
				return A2(
					$elm$html$Html$code,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(codeStr)
						]));
			case 3:
				var url = inline.a;
				var maybeTitle = inline.b;
				var inlines = inline.c;
				if (!maybeTitle.$) {
					var title_ = maybeTitle.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(url),
								$elm$html$Html$Attributes$title(title_)
							]),
						A2($elm$core$List$map, transformer, inlines));
				} else {
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(url)
							]),
						A2($elm$core$List$map, transformer, inlines));
				}
			case 4:
				var url = inline.a;
				var maybeTitle = inline.b;
				var inlines = inline.c;
				if (!maybeTitle.$) {
					var title_ = maybeTitle.a;
					return A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$alt(
								$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines)),
								$elm$html$Html$Attributes$src(url),
								$elm$html$Html$Attributes$title(title_)
							]),
						_List_Nil);
				} else {
					return A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$alt(
								$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines)),
								$elm$html$Html$Attributes$src(url)
							]),
						_List_Nil);
				}
			case 5:
				var tag = inline.a;
				var attrs = inline.b;
				var inlines = inline.c;
				return A3(
					$elm$html$Html$node,
					tag,
					$pablohirafuji$elm_markdown$Markdown$Inline$attributesToHtmlAttributes(attrs),
					A2($elm$core$List$map, transformer, inlines));
			case 6:
				var length = inline.a;
				var inlines = inline.b;
				switch (length) {
					case 1:
						return A2(
							$elm$html$Html$em,
							_List_Nil,
							A2($elm$core$List$map, transformer, inlines));
					case 2:
						return A2(
							$elm$html$Html$strong,
							_List_Nil,
							A2($elm$core$List$map, transformer, inlines));
					default:
						return ((length - 2) > 0) ? A2(
							$elm$html$Html$strong,
							_List_Nil,
							function (a) {
								return A2($elm$core$List$cons, a, _List_Nil);
							}(
								transformer(
									A2($pablohirafuji$elm_markdown$Markdown$Inline$Emphasis, length - 2, inlines)))) : A2(
							$elm$html$Html$em,
							_List_Nil,
							A2($elm$core$List$map, transformer, inlines));
				}
			default:
				var inlines = inline.b;
				return $elm$html$Html$text('');
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$toHtml = $pablohirafuji$elm_markdown$Markdown$Inline$defaultHtml($elm$core$Maybe$Nothing);
var $pablohirafuji$elm_markdown$Markdown$Block$defaultHtml = F3(
	function (customHtml, customInlineHtml, block) {
		var inlineToHtml = A2($elm$core$Maybe$withDefault, $pablohirafuji$elm_markdown$Markdown$Inline$toHtml, customInlineHtml);
		var blockToHtml = A2(
			$elm$core$Maybe$withDefault,
			A2($pablohirafuji$elm_markdown$Markdown$Block$defaultHtml, $elm$core$Maybe$Nothing, customInlineHtml),
			customHtml);
		switch (block.$) {
			case 0:
				return _List_Nil;
			case 2:
				var level = block.b;
				var inlines = block.c;
				var hElement = function () {
					switch (level) {
						case 1:
							return $elm$html$Html$h1(_List_Nil);
						case 2:
							return $elm$html$Html$h2(_List_Nil);
						case 3:
							return $elm$html$Html$h3(_List_Nil);
						case 4:
							return $elm$html$Html$h4(_List_Nil);
						case 5:
							return $elm$html$Html$h5(_List_Nil);
						default:
							return $elm$html$Html$h6(_List_Nil);
					}
				}();
				return _List_fromArray(
					[
						hElement(
						A2($elm$core$List$map, inlineToHtml, inlines))
					]);
			case 1:
				return _List_fromArray(
					[
						A2($elm$html$Html$hr, _List_Nil, _List_Nil)
					]);
			case 4:
				var inlines = block.b;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						A2($elm$core$List$map, inlineToHtml, inlines))
					]);
			case 3:
				if (block.a.$ === 1) {
					var _v2 = block.a;
					var model = _v2.b;
					var codeStr = block.b;
					var basicView = function (attrs) {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$pre,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$code,
										attrs,
										_List_fromArray(
											[
												$elm$html$Html$text(codeStr)
											]))
									]))
							]);
					};
					var _v3 = model.eq;
					if (!_v3.$) {
						var language = _v3.a;
						return basicView(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('language-' + language)
								]));
					} else {
						return basicView(_List_Nil);
					}
				} else {
					var _v4 = block.a;
					var codeStr = block.b;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$pre,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$code,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(codeStr)
										]))
								]))
						]);
				}
			case 5:
				var blocks = block.a;
				return function (a) {
					return A2($elm$core$List$cons, a, _List_Nil);
				}(
					A2(
						$elm$html$Html$blockquote,
						_List_Nil,
						$elm$core$List$concat(
							A2($elm$core$List$map, blockToHtml, blocks))));
			case 6:
				var model = block.a;
				var items = block.b;
				return function (a) {
					return A2($elm$core$List$cons, a, _List_Nil);
				}(
					function () {
						var _v5 = model.cV;
						if (_v5.$ === 1) {
							var startInt = _v5.a;
							return (startInt === 1) ? $elm$html$Html$ol(_List_Nil) : $elm$html$Html$ol(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$start(startInt)
									]));
						} else {
							return $elm$html$Html$ul(_List_Nil);
						}
					}()(
						A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$List$map(blockToHtml),
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$concat,
									$elm$html$Html$li(_List_Nil))),
							items)));
			case 7:
				var inlines = block.a;
				return A2($elm$core$List$map, inlineToHtml, inlines);
			default:
				var customBlock = block.a;
				var blocks = block.b;
				return function (a) {
					return A2($elm$core$List$cons, a, _List_Nil);
				}(
					A2(
						$elm$html$Html$div,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							$elm$html$Html$text('Unhandled custom block.'),
							$elm$core$List$concat(
								A2($elm$core$List$map, blockToHtml, blocks)))));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$toHtml = A2($pablohirafuji$elm_markdown$Markdown$Block$defaultHtml, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
var $pablohirafuji$elm_markdown$Markdown$toHtml = F2(
	function (maybeOptions, rawText) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$pablohirafuji$elm_markdown$Markdown$Block$toHtml,
				A2($pablohirafuji$elm_markdown$Markdown$Block$parse, maybeOptions, rawText)));
	});
var $author$project$View$intentionDisplay = F3(
	function (i, intentions, onCloseMsg) {
		return A2(
			$surprisetalk$elm_bulma$Bulma$Elements$box,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					'is-marginless intention-display ' + ('level-' + $elm$core$String$fromInt(
						A2($author$project$Utils$distanceFromRoot, intentions, i))))
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$author$project$Utils$empty,
					A2(
						$elm$core$Maybe$map,
						function (msg) {
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'text-align', 'right')
									]),
								_List_fromArray(
									[
										A2($surprisetalk$elm_bulma$Bulma$Elements$easyDelete, _List_Nil, msg)
									]));
						},
						onCloseMsg)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('columns is-1 is-vcentered')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('column')
								]),
							_List_fromArray(
								[
									A3(
									$surprisetalk$elm_bulma$Bulma$Elements$title,
									3,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('text-center-mobile')
										]),
									_List_fromArray(
										[
											(i.jJ === 1) ? A2(
											$elm$html$Html$s,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text(i.jU)
												])) : $elm$html$Html$text(i.jU)
										])),
									A2(
									$elm$core$Maybe$withDefault,
									$author$project$Utils$empty,
									A2(
										$elm$core$Maybe$map,
										function (d) {
											return A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('description content text-center-mobile')
													]),
												A2($pablohirafuji$elm_markdown$Markdown$toHtml, $elm$core$Maybe$Nothing, d));
										},
										i.ib))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('column is-narrow')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('buttons flex-center')
										]),
									_List_fromArray(
										[
											A3(
											$surprisetalk$elm_bulma$Bulma$Elements$button,
											_Utils_update(
												$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
												{H: 0}),
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													$author$project$Types$ToggleIntentionDone(i.iw))
												]),
											_List_fromArray(
												[
													A3(
													$surprisetalk$elm_bulma$Bulma$Elements$icon,
													2,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$i,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class(
																	'fa fa-' + ((i.jJ === 1) ? 'undo' : 'check'))
																]),
															_List_Nil)
														]))
												])),
											A3(
											$surprisetalk$elm_bulma$Bulma$Elements$button,
											_Utils_update(
												$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
												{H: 0}),
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													$author$project$Types$RequestQuickAddChild(i.iw))
												]),
											_List_fromArray(
												[
													A3(
													$surprisetalk$elm_bulma$Bulma$Elements$icon,
													2,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$i,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('fa fa-plus')
																]),
															_List_Nil)
														]))
												])),
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('button is-default'),
													$elm$html$Html$Attributes$href(
													'/focus/' + $elm$core$String$fromInt(i.iw))
												]),
											_List_fromArray(
												[
													A3(
													$surprisetalk$elm_bulma$Bulma$Elements$icon,
													2,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$i,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('fa fa-eye')
																]),
															_List_Nil)
														]))
												])),
											A3(
											$surprisetalk$elm_bulma$Bulma$Elements$button,
											_Utils_update(
												$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
												{H: 5}),
											_List_fromArray(
												[
													$elm$html$Html$Attributes$href(
													'/intention/' + $elm$core$String$fromInt(i.iw))
												]),
											_List_fromArray(
												[
													A3(
													$surprisetalk$elm_bulma$Bulma$Elements$icon,
													2,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$i,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('fa fa-edit')
																]),
															_List_Nil)
														]))
												])),
											A3(
											$surprisetalk$elm_bulma$Bulma$Elements$button,
											_Utils_update(
												$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
												{H: 10}),
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													$author$project$Types$RequestDeleteIntention(i.iw))
												]),
											_List_fromArray(
												[
													A3(
													$surprisetalk$elm_bulma$Bulma$Elements$icon,
													2,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$i,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('fa fa-trash')
																]),
															_List_Nil)
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Types$CancelQuickAdd = {$: 28};
var $author$project$Types$QuickAdd = {$: 27};
var $author$project$Types$UpdateQuickAdd = function (a) {
	return {$: 26, a: a};
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Utils$onEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2(
			$elm$json$Json$Decode$map,
			function (k) {
				if (k === 13) {
					return msg;
				} else {
					return $author$project$Types$NoOp;
				}
			},
			$elm$html$Html$Events$keyCode));
};
var $author$project$View$quickAddForm = function (qa) {
	var id = qa.a;
	var s = qa.b;
	return A2(
		$elm$html$Html$form,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('is-marginless quick-add box'),
				$elm$html$Html$Events$onSubmit($author$project$Types$QuickAdd)
			]),
		_List_fromArray(
			[
				A4(
				$surprisetalk$elm_bulma$Bulma$Form$controlInput,
				_Utils_update(
					$surprisetalk$elm_bulma$Bulma$Form$controlInputModifiers,
					{
						go: $elm$core$Maybe$Just(
							_Utils_Tuple3(
								2,
								_List_Nil,
								A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-plus')
										]),
									_List_Nil)))
					}),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('title-input')
					]),
				_List_fromArray(
					[
						$elm$html$Html$Events$onInput($author$project$Types$UpdateQuickAdd),
						$author$project$Utils$onEnter($author$project$Types$QuickAdd),
						$elm$html$Html$Attributes$placeholder('New Intention Title'),
						$elm$html$Html$Attributes$value(s),
						$elm$html$Html$Attributes$id('quick-add-input')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('buttons')
					]),
				_List_fromArray(
					[
						A3(
						$surprisetalk$elm_bulma$Bulma$Elements$button,
						_Utils_update(
							$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
							{H: 0}),
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick($author$project$Types$CancelQuickAdd)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Cancel')
							])),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('button is-primary'),
								$elm$html$Html$Attributes$type_('submit')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Add')
							]))
					]))
			]));
};
var $author$project$View$intentionsList = F2(
	function (quickAdd, intentions) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('intentions-list')
				]),
			A2(
				$elm$core$List$concatMap,
				function (i) {
					return _List_fromArray(
						[
							A3($author$project$View$intentionDisplay, i, intentions, $elm$core$Maybe$Nothing),
							function () {
							if (!quickAdd.$) {
								var _v1 = quickAdd.a;
								var id = _v1.a;
								var s = _v1.b;
								return _Utils_eq(id, i.iw) ? $author$project$View$quickAddForm(
									_Utils_Tuple2(id, s)) : $author$project$Utils$empty;
							} else {
								return $author$project$Utils$empty;
							}
						}()
						]);
				},
				intentions));
	});
var $author$project$Utils$isNothing = A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Utils$isSome);
var $author$project$Utils$mostParentsSort = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$sortBy(
		function (i) {
			return $elm$core$List$length(i.ji);
		}),
	$elm$core$List$reverse);
var $surprisetalk$elm_bulma$Bulma$Classes$tag = $elm$html$Html$Attributes$class('tag');
var $surprisetalk$elm_bulma$Bulma$Elements$tag = function (_v0) {
	var size = _v0.bE;
	var color = _v0.H;
	var isLink = _v0.en;
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		isLink ? 'a' : 'span',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$tag,
				function () {
				switch (size) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
				}
			}(),
				function () {
				switch (color) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWhite;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLight;
					case 3:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDark;
					case 4:
						return $surprisetalk$elm_bulma$Bulma$Classes$isBlack;
					case 5:
						return $surprisetalk$elm_bulma$Bulma$Classes$isPrimary;
					case 7:
						return $surprisetalk$elm_bulma$Bulma$Classes$isInfo;
					case 8:
						return $surprisetalk$elm_bulma$Bulma$Classes$isSuccess;
					case 9:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWarning;
					case 10:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDanger;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLink;
				}
			}()
			]));
};
var $surprisetalk$elm_bulma$Bulma$Elements$tagModifiers = {H: 0, en: false, bE: 1};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$View$homePage = function (model) {
	var breadcrumbs = A4(
		$surprisetalk$elm_bulma$Bulma$Components$breadcrumb,
		$surprisetalk$elm_bulma$Bulma$Components$breadcrumbModifiers,
		_List_Nil,
		_List_Nil,
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					A4(
					$surprisetalk$elm_bulma$Bulma$Components$crumblet,
					false,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href('/')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Home')
						]))
				]),
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					function (i) {
						return _List_fromArray(
							[
								A4(
								$surprisetalk$elm_bulma$Bulma$Components$crumblet,
								true,
								_List_Nil,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Focus: ' + i.jU),
										(!A2(
										$elm$core$List$any,
										function (v) {
											return _Utils_eq(v.ju.iw, i.iw);
										},
										model.j5)) ? A3(
										$surprisetalk$elm_bulma$Bulma$Elements$tag,
										_Utils_update(
											$surprisetalk$elm_bulma$Bulma$Elements$tagModifiers,
											{H: 5}),
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('breadcrumb-button'),
												$elm$html$Html$Events$onClick(
												$author$project$Types$RequestAddView(i.iw))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Save'),
												A3(
												$surprisetalk$elm_bulma$Bulma$Elements$icon,
												2,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$i,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('fa fa-save')
															]),
														_List_Nil)
													]))
											])) : $author$project$Utils$empty
									]))
							]);
					},
					A2(
						$elm$core$Maybe$andThen,
						function (id) {
							return A2($elm$core$Dict$get, id, model.iD);
						},
						model.ir)))));
	var addNewButton = A2(
		$elm$html$Html$a,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('button is-medium is-primary new-intention-button add-new-button'),
				$elm$html$Html$Attributes$href('/intention/new')
			]),
		_List_fromArray(
			[
				A3(
				$surprisetalk$elm_bulma$Bulma$Elements$icon,
				2,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fa fa-plus')
							]),
						_List_Nil)
					])),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(' New Intention')
					]))
			]));
	var _v0 = _Utils_Tuple3(
		model.iL,
		$elm$core$Dict$isEmpty(model.iD),
		$author$project$Utils$isSome(model.ir) && $author$project$Utils$isNothing(
			A2(
				$elm$core$Maybe$andThen,
				function (id) {
					return A2($elm$core$Dict$get, id, model.iD);
				},
				model.ir)));
	_v0$2:
	while (true) {
		_v0$3:
		while (true) {
			if (_v0.a) {
				if (_v0.b) {
					return _List_fromArray(
						[
							A3(
							$surprisetalk$elm_bulma$Bulma$Elements$progress,
							_Utils_update(
								$surprisetalk$elm_bulma$Bulma$Elements$progressModifiers,
								{H: 5, bE: 3}),
							_List_Nil,
							_List_Nil)
						]);
				} else {
					if (_v0.c) {
						break _v0$2;
					} else {
						break _v0$3;
					}
				}
			} else {
				if (_v0.b) {
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('is-size-4 space-around')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Welcome to Intention!')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('is-size-4 space-around')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('To get started, click the \'New Intention\' button below to add your first intention.')
								])),
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('is-size-4 space-around large-space-below')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Please see the '),
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('https://about.i.ntention.app/guide'),
											$elm$html$Html$Attributes$target('_blank')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Walkthough Guide')
										])),
									$elm$html$Html$text(' for help.')
								])),
							addNewButton
						]);
				} else {
					if (_v0.c) {
						break _v0$2;
					} else {
						break _v0$3;
					}
				}
			}
		}
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(
						'top-bar ' + (model.jz ? 'search-focused' : '')),
						$elm$html$Html$Attributes$id('top-bar'),
						A2(
						$elm$html$Html$Events$on,
						'focusout',
						A2(
							$elm$json$Json$Decode$andThen,
							function (id) {
								return (!_Utils_eq(
									id,
									$elm$core$Maybe$Just('top-bar'))) ? $elm$json$Json$Decode$succeed($author$project$Types$SearchUnfocused) : $elm$json$Json$Decode$succeed($author$project$Types$NoOp);
							},
							$author$project$JSON$decodeRelatedTargetId)),
						$elm$html$Html$Attributes$tabindex(0)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('top-bar-level')
							]),
						_List_fromArray(
							[
								breadcrumbs,
								A4(
								$surprisetalk$elm_bulma$Bulma$Form$controlInput,
								_Utils_update(
									$surprisetalk$elm_bulma$Bulma$Form$controlInputModifiers,
									{
										go: $elm$core$Maybe$Just(
											_Utils_Tuple3(
												2,
												_List_Nil,
												A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('fa fa-search')
														]),
													_List_Nil))),
										g9: true
									}),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('search')
									]),
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput($author$project$Types$SearchFilterUpdated),
										$elm$html$Html$Events$onFocus($author$project$Types$SearchFocused),
										$elm$html$Html$Attributes$value(
										A2($elm$core$Maybe$withDefault, '', model.jy))
									]),
								_List_Nil)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('filters')
							]),
						A2(
							$elm$core$List$map,
							function (t) {
								var _v1 = t;
								var label = _v1.a;
								var value = _v1.b;
								var msg = _v1.c;
								return A3(
									$surprisetalk$elm_bulma$Bulma$Elements$button,
									_Utils_update(
										$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
										{
											H: value ? 5 : 0,
											g9: true,
											bE: 0
										}),
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											msg(!value))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(label)
										]));
							},
							_List_fromArray(
								[
									_Utils_Tuple3('Show completed?', model.jE, $author$project$Types$SetDoneFilter),
									_Utils_Tuple3('Only base level', model.jG, $author$project$Types$SetLeavesFilter)
								])))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('secondary-options')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('secondary-options__left')
							]),
						_List_fromArray(
							[
								A2(
								$surprisetalk$elm_bulma$Bulma$Form$field,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('has-addons display-select')
									]),
								A2(
									$elm$core$List$map,
									function (_v2) {
										var listType = _v2.a;
										var iconClass = _v2.b;
										return A4(
											$surprisetalk$elm_bulma$Bulma$Form$controlButton,
											_Utils_update(
												$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
												{
													H: _Utils_eq(model.id, listType) ? 5 : 0,
													bE: 0
												}),
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													$author$project$Types$SwitchTab(listType))
												]),
											_List_fromArray(
												[
													A3(
													$surprisetalk$elm_bulma$Bulma$Elements$icon,
													0,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$elm$html$Html$i,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class(iconClass)
																]),
															_List_Nil)
														]))
												]));
									},
									_List_fromArray(
										[
											_Utils_Tuple2(1, 'fa fa-share-alt'),
											_Utils_Tuple2(0, 'fa fa-list')
										]))),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('view-select')
									]),
								A2(
									$elm$core$List$map,
									function (v) {
										return A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('tag is-primary'),
													$elm$html$Html$Attributes$href(
													'/focus/' + $elm$core$String$fromInt(v.ju.iw))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(v.jU),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('delete'),
															$elm$html$Html$Events$onClick(
															$author$project$Types$RequestDeleteView(v))
														]),
													_List_Nil)
												]));
									},
									model.j5))
							])),
						(!model.id) ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('secondary-options__right')
							]),
						_List_fromArray(
							[
								A3(
								$surprisetalk$elm_bulma$Bulma$Elements$icon,
								0,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('button-icon')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fa fa-sort-amount-desc')
											]),
										_List_Nil)
									])),
								A2(
								$surprisetalk$elm_bulma$Bulma$Form$field,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('has-addons sort-select')
									]),
								A2(
									$elm$core$List$map,
									function (_v3) {
										var label = _v3.a;
										var sortType = _v3.b;
										return A4(
											$surprisetalk$elm_bulma$Bulma$Form$controlButton,
											_Utils_update(
												$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
												{
													H: _Utils_eq(model.jI, sortType) ? 5 : 0,
													bE: 0
												}),
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													$author$project$Types$SetSortType(sortType))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(label)
												]));
									},
									_List_fromArray(
										[
											_Utils_Tuple2('normal', 0),
											_Utils_Tuple2('most parents', 1)
										])))
							])) : $author$project$Utils$empty
					])),
				function () {
				var _v4 = model.id;
				if (!_v4) {
					var sortFn = function () {
						var _v5 = model.jI;
						if (!_v5) {
							return $author$project$Utils$topologicalSort;
						} else {
							return $author$project$Utils$mostParentsSort;
						}
					}();
					var intentions = $elm$core$Dict$values(model.iD);
					return A2(
						$author$project$View$intentionsList,
						model.jo,
						sortFn(
							A2(
								$elm$core$List$filter,
								function (i) {
									return A2(
										$elm$core$Maybe$withDefault,
										true,
										A2(
											$elm$core$Maybe$map,
											function (sf) {
												return A2($author$project$Utils$caseInsensitiveContains, sf, i.jU);
											},
											model.jy));
								},
								A2(
									$elm$core$List$filter,
									function (i) {
										return model.jG ? $elm$core$List$isEmpty(
											A2($author$project$Utils$descendentsOf, i.iw, intentions)) : true;
									},
									A2(
										$elm$core$List$filter,
										function (i) {
											return model.jE || (i.jJ !== 1);
										},
										A3(
											$elm$core$Basics$apR,
											A2($elm$core$Maybe$map, $author$project$Utils$subGraph, model.ir),
											$elm$core$Maybe$withDefault($elm$core$Basics$identity),
											intentions))))));
				} else {
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$core$Maybe$withDefault,
								$author$project$Utils$empty,
								A2(
									$elm$core$Maybe$map,
									A2($author$project$View$dagDisplay, model.hR !== 'Safari', model.iD),
									model.h3)),
								A2(
								$elm$core$Maybe$withDefault,
								$author$project$Utils$empty,
								A2(
									$elm$core$Maybe$map,
									function (i) {
										return A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('selected-intention')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('container')
														]),
													_List_fromArray(
														[
															function () {
															var _v6 = model.jo;
															if (!_v6.$) {
																var _v7 = _v6.a;
																var id = _v7.a;
																var s = _v7.b;
																return _Utils_eq(id, i.iw) ? $author$project$View$quickAddForm(
																	_Utils_Tuple2(id, s)) : A3(
																	$author$project$View$intentionDisplay,
																	i,
																	$elm$core$Dict$values(model.iD),
																	$elm$core$Maybe$Just($author$project$Types$DAGNodeUnselected));
															} else {
																return A3(
																	$author$project$View$intentionDisplay,
																	i,
																	$elm$core$Dict$values(model.iD),
																	$elm$core$Maybe$Just($author$project$Types$DAGNodeUnselected));
															}
														}()
														]))
												]));
									},
									A2(
										$elm$core$Maybe$andThen,
										function (id) {
											return A2($elm$core$Dict$get, id, model.iD);
										},
										model.jA)))
							]));
				}
			}(),
				addNewButton
			]);
	}
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					'Focused Intention not found! Id: ' + A2(
						$elm$core$Maybe$withDefault,
						'unknown',
						A2($elm$core$Maybe$map, $elm$core$String$fromInt, model.ir)))
				]))
		]);
};
var $surprisetalk$elm_bulma$Bulma$Classes$levelRight = $elm$html$Html$Attributes$class('level-right');
var $surprisetalk$elm_bulma$Bulma$Layout$levelRight = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'div',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$levelRight]));
var $author$project$View$notFound = function (_v0) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Route not found!')
				]))
		]);
};
var $surprisetalk$elm_bulma$Bulma$Classes$notification = $elm$html$Html$Attributes$class('notification');
var $surprisetalk$elm_bulma$Bulma$Elements$notification = function (color) {
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		'div',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$notification,
				function () {
				switch (color) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWhite;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLight;
					case 3:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDark;
					case 4:
						return $surprisetalk$elm_bulma$Bulma$Classes$isBlack;
					case 5:
						return $surprisetalk$elm_bulma$Bulma$Classes$isPrimary;
					case 7:
						return $surprisetalk$elm_bulma$Bulma$Classes$isInfo;
					case 8:
						return $surprisetalk$elm_bulma$Bulma$Classes$isSuccess;
					case 9:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWarning;
					case 10:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDanger;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLink;
				}
			}()
			]));
};
var $surprisetalk$elm_bulma$Bulma$Classes$section = $elm$html$Html$Attributes$class('section');
var $surprisetalk$elm_bulma$Bulma$Layout$section = function (spacing) {
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		'section',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$section,
				function () {
				switch (spacing) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$isMedium;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLarge;
				}
			}()
			]));
};
var $author$project$View$body = function (model) {
	return A3(
		$surprisetalk$elm_bulma$Bulma$Layout$section,
		0,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('main-section')
			]),
		_List_fromArray(
			[
				A2(
				$surprisetalk$elm_bulma$Bulma$Layout$container,
				_List_Nil,
				$elm$core$List$concat(
					_List_fromArray(
						[
							function () {
							var _v0 = model.il;
							if (_v0.$ === 1) {
								var _v1 = model.jd;
								switch (_v1.$) {
									case 0:
										return $author$project$View$homePage(model);
									case 1:
										var id = _v1.b;
										return A2($author$project$View$editIntention, id, model);
									default:
										return $author$project$View$notFound(model);
								}
							} else {
								var error = _v0.a;
								return _List_fromArray(
									[
										A3(
										$surprisetalk$elm_bulma$Bulma$Elements$notification,
										10,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$html$Html$b,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Error: ')
															])),
														$elm$html$Html$text(error)
													]))
											]))
									]);
							}
						}(),
							_List_fromArray(
							[
								A4(
								$surprisetalk$elm_bulma$Bulma$Components$easyModal,
								$author$project$Utils$isSome(model.iC),
								_List_Nil,
								$author$project$Types$DeleteCancelled,
								_List_fromArray(
									[
										A2(
										$surprisetalk$elm_bulma$Bulma$Elements$box,
										_List_Nil,
										_List_fromArray(
											[
												A3(
												$surprisetalk$elm_bulma$Bulma$Elements$title,
												3,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$elm$core$Maybe$withDefault,
														$elm$html$Html$text('Something went wrong :('),
														A2(
															$elm$core$Maybe$map,
															function (i) {
																return $elm$html$Html$text('Are you sure you want to delete \'' + (i.jU + '\'?'));
															},
															A2(
																$elm$core$Maybe$andThen,
																function (i) {
																	return A2($elm$core$Dict$get, i.a, model.iD);
																},
																model.iC)))
													])),
												A2(
												$surprisetalk$elm_bulma$Bulma$Form$label,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('checkbox')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$type_('checkbox'),
																$elm$html$Html$Events$onClick($author$project$Types$ToggleDeleteDescendants)
															]),
														_List_Nil),
														$elm$html$Html$text('Also delete subtasks?')
													])),
												A2(
												$elm$core$Maybe$withDefault,
												false,
												A2($elm$core$Maybe$map, $elm$core$Tuple$second, model.iC)) ? A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('has-text-danger')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Warning: deleting all subtasks may delete a large number of intentions. Use with caution.')
													])) : $author$project$Utils$empty,
												A2(
												$surprisetalk$elm_bulma$Bulma$Layout$level,
												_List_Nil,
												_List_fromArray(
													[
														A2($surprisetalk$elm_bulma$Bulma$Layout$levelLeft, _List_Nil, _List_Nil),
														A2(
														$surprisetalk$elm_bulma$Bulma$Layout$levelRight,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$surprisetalk$elm_bulma$Bulma$Layout$levelItem,
																_List_Nil,
																_List_fromArray(
																	[
																		A3(
																		$surprisetalk$elm_bulma$Bulma$Elements$button,
																		$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
																		_List_fromArray(
																			[
																				$elm$html$Html$Events$onClick($author$project$Types$DeleteCancelled)
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Cancel')
																			])),
																		A3(
																		$surprisetalk$elm_bulma$Bulma$Elements$button,
																		_Utils_update(
																			$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
																			{H: 10}),
																		_List_fromArray(
																			[
																				$elm$html$Html$Events$onClick($author$project$Types$DeleteIntention)
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Delete')
																			]))
																	]))
															]))
													]))
											]))
									]))
							]),
							function () {
							var _v2 = model.j3;
							if (!_v2.$) {
								var _v3 = _v2.a;
								var id = _v3.a;
								var viewTitle = _v3.b;
								return _List_fromArray(
									[
										A4(
										$surprisetalk$elm_bulma$Bulma$Components$easyModal,
										true,
										_List_Nil,
										$author$project$Types$AddViewCancelled,
										_List_fromArray(
											[
												A2(
												$surprisetalk$elm_bulma$Bulma$Elements$box,
												_List_Nil,
												_List_fromArray(
													[
														A3(
														$surprisetalk$elm_bulma$Bulma$Elements$title,
														3,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(
																'Add View for \"' + (A2(
																	$elm$core$Maybe$withDefault,
																	'',
																	A2(
																		$elm$core$Maybe$map,
																		function ($) {
																			return $.jU;
																		},
																		A2($elm$core$Dict$get, id, model.iD))) + '\"'))
															])),
														A2(
														$surprisetalk$elm_bulma$Bulma$Form$field,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$surprisetalk$elm_bulma$Bulma$Form$controlLabel,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('View Title')
																	])),
																A4(
																$surprisetalk$elm_bulma$Bulma$Form$controlInput,
																$surprisetalk$elm_bulma$Bulma$Form$controlInputModifiers,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$Events$onInput($author$project$Types$AddViewTitleUpdated),
																		$elm$html$Html$Attributes$value(viewTitle)
																	]),
																_List_Nil)
															])),
														A2(
														$surprisetalk$elm_bulma$Bulma$Layout$level,
														_List_Nil,
														_List_fromArray(
															[
																A2($surprisetalk$elm_bulma$Bulma$Layout$levelLeft, _List_Nil, _List_Nil),
																A2(
																$surprisetalk$elm_bulma$Bulma$Layout$levelRight,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$surprisetalk$elm_bulma$Bulma$Layout$levelItem,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A3(
																				$surprisetalk$elm_bulma$Bulma$Elements$button,
																				$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
																				_List_fromArray(
																					[
																						$elm$html$Html$Events$onClick($author$project$Types$AddViewCancelled)
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Cancel')
																					])),
																				A3(
																				$surprisetalk$elm_bulma$Bulma$Elements$button,
																				_Utils_update(
																					$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
																					{H: 5}),
																				_List_fromArray(
																					[
																						$elm$html$Html$Events$onClick($author$project$Types$AddView)
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Create')
																					]))
																			]))
																	]))
															]))
													]))
											]))
									]);
							} else {
								return _List_Nil;
							}
						}(),
							function () {
							var _v4 = model.j4;
							if (!_v4.$) {
								var v = _v4.a;
								return _List_fromArray(
									[
										A4(
										$surprisetalk$elm_bulma$Bulma$Components$easyModal,
										true,
										_List_Nil,
										$author$project$Types$DeleteCancelled,
										_List_fromArray(
											[
												A2(
												$surprisetalk$elm_bulma$Bulma$Elements$box,
												_List_Nil,
												_List_fromArray(
													[
														A3(
														$surprisetalk$elm_bulma$Bulma$Elements$title,
														3,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Are you sure you want to delete the view \'' + (v.jU + '\'?'))
															])),
														A2(
														$surprisetalk$elm_bulma$Bulma$Layout$level,
														_List_Nil,
														_List_fromArray(
															[
																A2($surprisetalk$elm_bulma$Bulma$Layout$levelLeft, _List_Nil, _List_Nil),
																A2(
																$surprisetalk$elm_bulma$Bulma$Layout$levelRight,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$surprisetalk$elm_bulma$Bulma$Layout$levelItem,
																		_List_Nil,
																		_List_fromArray(
																			[
																				A3(
																				$surprisetalk$elm_bulma$Bulma$Elements$button,
																				$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
																				_List_fromArray(
																					[
																						$elm$html$Html$Events$onClick($author$project$Types$DeleteViewCancelled)
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Cancel')
																					])),
																				A3(
																				$surprisetalk$elm_bulma$Bulma$Elements$button,
																				_Utils_update(
																					$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
																					{H: 10}),
																				_List_fromArray(
																					[
																						$elm$html$Html$Events$onClick(
																						$author$project$Types$DeleteView(v))
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Delete')
																					]))
																			]))
																	]))
															]))
													]))
											]))
									]);
							} else {
								return _List_Nil;
							}
						}()
						])))
			]));
};
var $surprisetalk$elm_bulma$Bulma$Elements$H1 = 0;
var $author$project$Types$Logout = {$: 29};
var $author$project$Types$ToggleToolbarDropdown = {$: 30};
var $surprisetalk$elm_bulma$Bulma$Classes$isTransparent = $elm$html$Html$Attributes$class('is-transparent');
var $surprisetalk$elm_bulma$Bulma$Classes$navbar = $elm$html$Html$Attributes$class('navbar');
var $surprisetalk$elm_bulma$Bulma$Components$navbar = function (_v0) {
	var color = _v0.H;
	var transparent = _v0.fE;
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		'nav',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$navbar,
				function () {
				if (transparent) {
					return $surprisetalk$elm_bulma$Bulma$Classes$isTransparent;
				} else {
					return $surprisetalk$elm_bulma$Bulma$Classes$none;
				}
			}(),
				function () {
				switch (color) {
					case 0:
						return $surprisetalk$elm_bulma$Bulma$Classes$none;
					case 1:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWhite;
					case 2:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLight;
					case 3:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDark;
					case 4:
						return $surprisetalk$elm_bulma$Bulma$Classes$isBlack;
					case 5:
						return $surprisetalk$elm_bulma$Bulma$Classes$isPrimary;
					case 6:
						return $surprisetalk$elm_bulma$Bulma$Classes$isLink;
					case 7:
						return $surprisetalk$elm_bulma$Bulma$Classes$isInfo;
					case 8:
						return $surprisetalk$elm_bulma$Bulma$Classes$isSuccess;
					case 9:
						return $surprisetalk$elm_bulma$Bulma$Classes$isWarning;
					default:
						return $surprisetalk$elm_bulma$Bulma$Classes$isDanger;
				}
			}()
			]));
};
var $surprisetalk$elm_bulma$Bulma$Components$navbarBrand = F3(
	function (attrs, burger, items) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('navbar-brand'),
				attrs),
			_Utils_ap(
				items,
				_List_fromArray(
					[burger])));
	});
var $surprisetalk$elm_bulma$Bulma$Classes$navbarBurger = $elm$html$Html$Attributes$class('navbar-burger');
var $surprisetalk$elm_bulma$Bulma$Components$navbarBurger = function (isActive) {
	return A2(
		$surprisetalk$elm_bulma$Helpers$node,
		'a',
		_List_fromArray(
			[
				$surprisetalk$elm_bulma$Bulma$Classes$navbarBurger,
				isActive ? $surprisetalk$elm_bulma$Bulma$Classes$isActive : $surprisetalk$elm_bulma$Bulma$Classes$none
			]));
};
var $surprisetalk$elm_bulma$Bulma$Classes$navbarEnd = $elm$html$Html$Attributes$class('navbar-end');
var $surprisetalk$elm_bulma$Bulma$Components$navbarEnd = A2(
	$surprisetalk$elm_bulma$Helpers$node,
	'div',
	_List_fromArray(
		[$surprisetalk$elm_bulma$Bulma$Classes$navbarEnd]));
var $surprisetalk$elm_bulma$Bulma$Classes$navbarMenu = $elm$html$Html$Attributes$class('navbar-menu');
var $surprisetalk$elm_bulma$Bulma$Components$navbarMenu = F2(
	function (isActive, attrs) {
		return A3(
			$surprisetalk$elm_bulma$Helpers$node,
			'div',
			_List_fromArray(
				[
					$surprisetalk$elm_bulma$Bulma$Classes$navbarMenu,
					isActive ? $surprisetalk$elm_bulma$Bulma$Classes$isActive : $surprisetalk$elm_bulma$Bulma$Classes$none
				]),
			attrs);
	});
var $surprisetalk$elm_bulma$Bulma$Components$navbarModifiers = {H: 0, fE: false};
var $author$project$View$header = function (showDropdown) {
	return A3(
		$surprisetalk$elm_bulma$Bulma$Components$navbar,
		_Utils_update(
			$surprisetalk$elm_bulma$Bulma$Components$navbarModifiers,
			{H: 5}),
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('header')
			]),
		_List_fromArray(
			[
				A3(
				$surprisetalk$elm_bulma$Bulma$Components$navbarBrand,
				_List_Nil,
				A3(
					$surprisetalk$elm_bulma$Bulma$Components$navbarBurger,
					showDropdown,
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick($author$project$Types$ToggleToolbarDropdown)
						]),
					_List_fromArray(
						[
							A2($elm$html$Html$span, _List_Nil, _List_Nil),
							A2($elm$html$Html$span, _List_Nil, _List_Nil),
							A2($elm$html$Html$span, _List_Nil, _List_Nil)
						])),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navbar-item logo-section')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('/'),
										$elm$html$Html$Attributes$class('app-logo')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src('/logo-white.svg')
											]),
										_List_Nil),
										A3(
										$surprisetalk$elm_bulma$Bulma$Elements$title,
										0,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('toolbar-title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Intention')
											]))
									])),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('https://davemartin.me'),
										$elm$html$Html$Attributes$target('_blank'),
										$elm$html$Html$Attributes$class('dm-logo')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('by '),
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src('/dm-logo.png')
											]),
										_List_Nil)
									]))
							]))
					])),
				A3(
				$surprisetalk$elm_bulma$Bulma$Components$navbarMenu,
				showDropdown,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$surprisetalk$elm_bulma$Bulma$Components$navbarEnd,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('navbar-item')
									]),
								_List_fromArray(
									[
										A3(
										$surprisetalk$elm_bulma$Bulma$Elements$button,
										_Utils_update(
											$surprisetalk$elm_bulma$Bulma$Elements$buttonModifiers,
											{H: 5, iG: true, jc: true, bE: 2}),
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick($author$project$Types$Logout)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Logout')
											]))
									])),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('navbar-item'),
										$elm$html$Html$Attributes$href('https://about.i.ntention.app/guide'),
										$elm$html$Html$Attributes$target('_blank')
									]),
								_List_fromArray(
									[
										A3(
										$surprisetalk$elm_bulma$Bulma$Elements$icon,
										3,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('fa fa-lg fa-question-circle')
													]),
												_List_Nil)
											]))
									]))
							]))
					]))
			]));
};
var $pablen$toasty$Toasty$itemContainer = F4(
	function (_v0, tagger, _v1, toastView) {
		var cfg = _v0;
		var id = _v1.a;
		var status = _v1.b;
		var toast = _v1.c;
		var attrs = function () {
			if (!status) {
				return cfg.fA;
			} else {
				return cfg.fB;
			}
		}();
		return _Utils_Tuple2(
			$elm$core$String$fromInt(id),
			A2(
				$elm$html$Html$li,
				_Utils_ap(
					cfg.eo,
					_Utils_ap(
						attrs,
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								tagger(
									$pablen$toasty$Toasty$TransitionOut(id)))
							]))),
				_List_fromArray(
					[
						toastView(toast)
					])));
	});
var $elm$html$Html$Keyed$ol = $elm$html$Html$Keyed$node('ol');
var $pablen$toasty$Toasty$view = F4(
	function (cfg, toastView, tagger, _v0) {
		var toasts = _v0.a;
		var seed = _v0.b;
		var _v1 = cfg;
		var c = _v1;
		return $elm$core$List$isEmpty(toasts) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$Keyed$ol,
			c.dO,
			A2(
				$elm$core$List$map,
				function (toast) {
					return A4($pablen$toasty$Toasty$itemContainer, cfg, tagger, toast, toastView);
				},
				toasts));
	});
var $pablen$toasty$Toasty$Defaults$genericToast = F3(
	function (variantClass, title, message) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('toasty-container'),
					$elm$html$Html$Attributes$class(variantClass)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h1,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('toasty-title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					$elm$core$String$isEmpty(message) ? $elm$html$Html$text('') : A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('toasty-message')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(message)
						]))
				]));
	});
var $pablen$toasty$Toasty$Defaults$view = function (toast) {
	switch (toast.$) {
		case 0:
			var title = toast.a;
			var message = toast.b;
			return A3($pablen$toasty$Toasty$Defaults$genericToast, 'toasty-success', title, message);
		case 1:
			var title = toast.a;
			var message = toast.b;
			return A3($pablen$toasty$Toasty$Defaults$genericToast, 'toasty-warning', title, message);
		default:
			var title = toast.a;
			var message = toast.b;
			return A3($pablen$toasty$Toasty$Defaults$genericToast, 'toasty-error', title, message);
	}
};
var $author$project$View$view = function (model) {
	return {
		br: _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id('root')
					]),
				_List_fromArray(
					[
						$author$project$View$header(model.jH),
						$author$project$View$body(model),
						A4($pablen$toasty$Toasty$view, $author$project$Config$toastConfig, $pablen$toasty$Toasty$Defaults$view, $author$project$Types$ShowToast, model.j$)
					]))
			]),
		jU: 'Intention'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{iA: $author$project$Model$init, i6: $author$project$Types$UrlChanged, i7: $author$project$Types$LinkClicked, jP: $author$project$Main$subscriptions, j0: $author$project$Update$update, j2: $author$project$View$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (browser) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (authToken) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (apiUrl) {
							return $elm$json$Json$Decode$succeed(
								{hL: apiUrl, hN: authToken, hR: browser});
						},
						A2($elm$json$Json$Decode$field, 'apiUrl', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'authToken', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'browser', $elm$json$Json$Decode$string)))(0)}});}(this));